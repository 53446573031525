import React, { useEffect, useContext, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import Head from '../../Head';
import PageHeroTitle from '../../PageHeroTitle';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/styles';
import PageContext from '../../../contexts/page';
import Button from '@material-ui/core/Button';
import CollisionLink from '../../CollisionLink';
import { getResourceSelector } from '../../../store/resource/selectors';

const Wrapper = styled(Box)`
  width: 100%;
  padding: 40px 16px;

  ${({ theme }) => css`
    background-color: ${theme.palette.background.default};

    ${theme.breakpoints.down('sm')} {
      padding: 20px 16px;
    }
  `};
`;

const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-top: ${theme.spacing(7)}px;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      margin-top: ${theme.spacing(4)}px;
    }
  `}
`;

const RobPage = ({ ...props }) => {
  const theme = useTheme();
  const { dispatchPageConfig } = useContext(PageContext);
  const [pageConfigDispatched, setPageConfigDispatched] = useState(false);
  const quizData = useSelector(getResourceSelector)('quiz');
  const selectedAnswer = quizData.selected_answer || {};

  useEffect(() => {
    if (!pageConfigDispatched) {
      dispatchPageConfig({
        type: 'reset-blacklist',
        pageSlug: 'rob',
      });

      setPageConfigDispatched(true);
    }
  }, [dispatchPageConfig, pageConfigDispatched, setPageConfigDispatched]);

  if (Object.keys(selectedAnswer).length > 0 && selectedAnswer.text !== 'Rob') {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <Head pageTitle="Rob" />
        <Wrapper theme={theme} textAlign="center" className="body">
          <PageHeroTitle title="Rob" />
          <Typography
            theme={theme}
            variant="subtitle1"
            component="p"
            style={{ marginBottom: '1rem' }}
          >
            You've chosen to rob
          </Typography>
          <Typography
            theme={theme}
            variant="subtitle1"
            component="p"
            style={{ marginBottom: '1.2rem' }}
          >
            Enter your details to receive a FREE
            <br />
            Furphy Crisp at one of
            <br />
            our participating venues
          </Typography>
          <svg
            width="276"
            height="3"
            viewBox="0 0 276 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M276 1.30279C242.5 2.56008 175.833 3.18873 138 2.8744C100.167 3.18873 33.5 2.9545 0 1.69721C33.5 0.439918 100.167 -0.188732 138 0.125595C175.833 -0.188733 242.5 0.0454934 276 1.30279Z"
              fill="#FEFCE1"
            />
          </svg>
          <ButtonWrapper theme={theme}>
            <Button
              variant="contained"
              size="large"
              component={CollisionLink(`/application`)}
            >
              Enter now
            </Button>
            <Button
              variant="outlined"
              size="large"
              component={CollisionLink(`/participating-venues`)}
            >
              Participating venues
            </Button>
          </ButtonWrapper>
        </Wrapper>
      </>
    );
  }
};

export default RobPage;
