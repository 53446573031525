import { furphy } from './furphy';
import deepmerge from 'deepmerge';

const config = {
  name: 'Furphy - on-prem',
  id: 6,
  campaigns: ['5'],
  userTheme: {
    default: {
      brand: {
        home: {
          layout: {
            direction: 'row',
            sections: [
              {
                id: 'first',
                responsive: {
                  sizes: {
                    xs: 12,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['landing_title'],
              },
              {
                id: 'second',
                responsive: {
                  sizes: {
                    xs: 12,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['hero'],
              },
              {
                id: 'third',
                responsive: {
                  sizes: {
                    xs: 12,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['cta'],
              },
              {
                id: 'forth',
                responsive: {
                  sizes: {
                    xs: 12,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['landing_description'],
              },
              {
                id: 'fith',
                responsive: {
                  sizes: {
                    xs: 12,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['description'],
              },
              {
                id: 'sixth',
                responsive: {
                  sizes: {
                    xs: 12,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['secondary'],
              },
            ],
          },
        },
      },
    },
  },
};
const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;
export const furphyOnPrem = deepmerge(furphy, config, {
  arrayMerge: overwriteMerge,
});
