// https://github.com/diegohaz/arc/wiki/Reducers
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import { initialState } from './selectors';
import {
  LOCATION_QUERY_READ_REQUEST,
  LOCATION_QUERY_READ_SUCCESS,
  LOCATION_QUERY_READ_FAILURE,
  USER_VENUE_SELECTED,
  USER_LOCATION_REQUEST,
  USER_LOCATION_SUCCESS,
  USER_LOCATION_PERMISSION_DENIED,
  USER_LOCATION_POSITION_UNAVAILABLE,
  USER_LOCATION_TIMEOUT,
  IP_LOCATION_READ_REQUEST,
  IP_LOCATION_READ_SUCCESS,
  IP_LOCATION_READ_FAILURE,
  UPDATE_MAP_CENTER,
  DEFAULT_MAP_CENTER,
} from './actions';

export default (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case LOCATION_QUERY_READ_REQUEST:
      return {
        ...state,
        query: {
          ...state.query,
          ...payload,
        },
      };

    case LOCATION_QUERY_READ_SUCCESS:
      return {
        ...state,
        pins: {
          ...state.pins,
          status: 'success',
          list: payload,
        },
      };

    case LOCATION_QUERY_READ_FAILURE:
      return {
        ...state,
        pins: {
          ...state.pins,
          status: 'failed',
        },
      };

    case USER_VENUE_SELECTED:
      return {
        ...state,
        venue: {
          ...state.venue,
          selected: payload && payload.id,
        },
      };

    case USER_LOCATION_REQUEST:
      return {
        ...state,
        user: {
          ...state.user,
          searched: false,
          asked: true,
          fetching: true,
          error: '',
        },
      };

    case USER_LOCATION_SUCCESS:
      return {
        ...state,
        highAccuracy: true,
        user: {
          ...state.user,
          denied: false,
          fetching: false,
          error: '',
          coords: {
            latitude: payload.coords.latitude,
            longitude: payload.coords.longitude,
          },
        },
      };

    case USER_LOCATION_PERMISSION_DENIED:
      return {
        ...state,
        user: {
          ...state.user,
          denied: true,
          fetching: false,
          error: payload,
        },
      };

    case USER_LOCATION_POSITION_UNAVAILABLE:
      return {
        ...state,
        user: {
          ...state.user,
          fetching: false,
          error: payload,
        },
      };

    case USER_LOCATION_TIMEOUT:
      return {
        ...state,
        user: {
          ...state.user,
          fetching: false,
          error: payload,
        },
      };

    case IP_LOCATION_READ_REQUEST:
      return {
        ...state,
        ip: {
          ...state.ip,
          ...payload,
          fetching: true,
        },
      };

    case IP_LOCATION_READ_SUCCESS:
      return {
        ...state,
        ip: {
          ...state.ip,
          ...payload,
          fetching: false,
        },
      };

    case IP_LOCATION_READ_FAILURE:
      return {
        ...state,
        ip: {
          ...state.ip,
          ...payload,
          fetching: false,
        },
      };

    case UPDATE_MAP_CENTER:
      return {
        ...state,
        highAccuracy: true,
        user: {
          ...state.user,
          usingDefault: true,
          coords: {
            latitude: payload.lat,
            longitude: payload.lng,
          }
        },
      };

    case DEFAULT_MAP_CENTER:
      return {
        ...state,
        user: {
          ...state.user,
          usingDefault: true,
          coords: {
            latitude: payload.lat,
            longitude: payload.lng,
          }
        },
      };

    default:
      return state;


  }
};
