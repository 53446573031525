// https://github.com/diegohaz/arc/wiki/Sagas
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import { put, call, takeEvery, select } from 'redux-saga/effects';
import { apiConfigSelector } from '../../store/config/selectors';
import { accessTokenDataSelector } from '../../store/resource/selectors';
import { isDev } from '../../config';

import * as actions from './actions';

export function* checkToken(api) {
  const apiUrl = yield select(apiConfigSelector);
  if (apiUrl.local) {
    return;
  }

  let tokenData = yield select(accessTokenDataSelector);
  if (!tokenData || tokenData.expires_at < Date.now() + 30) {
    api.unsetToken();

    tokenData = yield call(
      [api, api.post],
      '/oauth/token',
      {
        grant_type: 'client_credentials',
        client_id: 1,
        client_secret: 'UDwoFN5Sqz2bQvWn9PenbFm7WjmanNsZAhyfCv4N',
      },
      { apiUrl }
    );
    yield put(
      actions.resourceAccessTokenData({
        access_token: tokenData.access_token,
        expires_at: Date.now() + tokenData.expires_in,
      })
    );
  }
  yield call([api, api.setToken], tokenData.access_token);
}

export function* createResource(api, { data }, { resource, endpoint = '' }) {
  const apiUrl = yield select(apiConfigSelector);
  try {
    // https://github.com/diegohaz/arc/wiki/API-service
    // const detail = yield call([api, api.post], `/${resource}`, data);

    const detail = yield call(
      [api, api.post],
      endpoint ? `/${resource}${endpoint}` : `/${resource}`,
      data,
      {
        apiUrl: { ...apiUrl, path: apiUrl.path + '/api' },
      }
    );

    // https://github.com/diegohaz/arc/wiki/Actions#async-actions
    yield put(actions.resourceCreateSuccess(resource, detail, { data }));
  } catch (e) {
    if (isDev) {
      console.log('error', e);
    }
    yield put(actions.resourceCreateFailure(resource, e, { data }));
  }
}

export function* readResourceList(api, { params }, { resource, detail, dest }) {
  const state = yield select();
  const apiUrl = yield select(apiConfigSelector);
  let endpoint = `/campaign/${state.campaign.id}`;
  if (resource !== 'campaign') {
    endpoint += `/${resource}`;
  }
  if (!dest) {
    dest = detail;
  }
  try {
    const pathWay = Array.isArray(detail)
      ? detail.map(path => `/${path}`).join('')
      : detail
        ? `/${detail}`
        : '';
    endpoint += pathWay;
    const list = yield call([api, api.get], endpoint, {
      params,
      apiUrl: { ...apiUrl, path: apiUrl.path + '/api' },
    });

    yield put(
      actions.resourceListReadSuccess(resource, dest, list, { params })
    );
  } catch (e) {
    if (isDev) {
      console.log('error', e);
    }
    yield put(actions.resourceListReadFailure(resource, dest, e, { params }));
  }
}

export function* updateResource(api, endpoint, { resource, data = {} }) {
  const state = yield select();
  const apiUrl = yield select(apiConfigSelector);
  endpoint = `/campaign/${state.campaign.id}` + endpoint;
  try {
    const detail = yield call([api, api.post], endpoint, data, {
      apiUrl: { ...apiUrl, path: apiUrl.path + '/api' },
    });
    yield put(actions.resourceUpdateSuccess(resource, detail));
  } catch (e) {
    if (isDev) {
      console.log('error', e);
    }
    yield put(actions.resourceUpdateFailure(resource, e));
  }
}

export function* watchResourceCreateRequest(api, { payload, meta }) {
  yield call(checkToken, api);
  yield call(createResource, api, payload, meta);
}

export function* watchResourceListReadRequest(api, { payload, meta }) {
  yield call(checkToken, api);
  yield call(readResourceList, api, payload, meta);
}

export function* watchResourceUpdateRequest(api, { payload, meta }) {
  yield call(checkToken, api);
  yield call(updateResource, api, payload, meta);
}

export default function* ({ api }) {
  yield takeEvery(
    actions.RESOURCE_CREATE_REQUEST,
    watchResourceCreateRequest,
    api
  );
  yield takeEvery(
    actions.RESOURCE_LIST_READ_REQUEST,
    watchResourceListReadRequest,
    api
  );
  yield takeEvery(
    actions.RESOURCE_UPDATE_REQUEST,
    watchResourceUpdateRequest,
    api
  );
}
