import React, { useEffect, useContext } from 'react';
// import styled from 'styled-components/macro';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import { useTheme } from '@material-ui/styles';
import PageHero from '../../PageHero';
import Head from '../../Head';
// import Image from '../../Image';
import CollisionLink from '../../CollisionLink';
import PageContext from '../../../contexts/page';
import { useSelector } from 'react-redux';
import { getResourceSelector } from './../../../store/resource/selectors';
import get from 'lodash/get';

const FourOFourPage = ({ homepageLink }) => {
  // const { brand } = useTheme();
  const { data: campaignData } = useSelector(getResourceSelector)('campaign');

  const { dispatchPageConfig } = useContext(PageContext);

  useEffect(() => {
    dispatchPageConfig({
      type: 'reset-blacklist',
      pageSlug: 'fourOFour',
    });
  }, [dispatchPageConfig]);

  const message_404 = get(campaignData, 'message_404', 'Page not found');

  return (
    <>
      <Head pageTitle="404 - Page not found" />

      <PageHero title="404" />

      <Box mt="-2rem" align="center">
        <Typography variant="h4" component="p" className="message">
          {message_404}
        </Typography>
      </Box>

      <Box mt="40px" align="center">
        <Button
          className="button"
          variant="contained"
          size="large"
          component={CollisionLink()}
        >
          Back to homepage
        </Button>
      </Box>
    </>
  );
};

export default FourOFourPage;
