import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
// import styled, { css } from 'styled-components/macro';
// import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import Head from '../../Head';
import { useTheme } from '@material-ui/styles';
import {
  getCountdownTime,
  getCountdownFinished,
} from '../../../store/premise/selectors';
import {
  getResourceSelector,
  getResourcesDataSelector,
  onPremDataSelector,
} from '../../../store/resource/selectors';
import Box from '@material-ui/core/Box';
// import HeroImage from '../../HeroImage';
import TimerValidator from '../../TimerValidator';
import SocialStickers from '../../SocialStickers';
import { resourceListReadRequest } from '../../../store/resource/actions';
import PageContext from '../../../contexts/page';
// import MarkDown from '../../../containers/Markdown';

// const FadedHeroImage = styled(HeroImage)`
//   ${({ theme, config, ...props }) => css`
//     position: relative;
//     overflow: hidden;
//     transform: translateY(-${theme.spacing(2)}px);
//     transition: ${({ theme }) =>
//       `opacity ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeOut}, transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeOut}`};

//     ${!props['data-fade'] &&
//     css`
//         transform: translateY(0);
//       `}

//     &:before {
//       content: '';
//       position: absolute;
//       width: 100%;
//       height: 100px;
//       background-image: linear-gradient(
//         rgba(0, 60, 121, 0) 0%,
//         ${theme.palette.background.default} 100%
//       );
//       bottom: 0;
//       left: 0;
//       opacity: ${props => (props['data-fade'] ? 1 : 0)}
//       transition: ${({ theme }) =>
//       `opacity ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeOut}`};
//     }
//   `}
// `;

const OrderPage = ({ ...props }) => {
  const { brand, ...theme } = useTheme();
  const dispatch = useDispatch();
  const onPrem = useSelector(getResourceSelector)(['campaign', 'on-prem']);
  const onPremData = useSelector(onPremDataSelector);
  const timerStarted = useSelector(getResourceSelector)('timerStarted');
  const redemption = useSelector(getResourceSelector)('redemption');
  const countdownTime = useSelector(getCountdownTime);
  const expired = useSelector(getCountdownFinished);
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));
  const campaignData = useSelector(getResourcesDataSelector)('campaign');

  const { dispatchPageConfig } = useContext(PageContext);

  useEffect(() => {
    if (!onPrem.success && !onPrem.fetching) {
      dispatch(resourceListReadRequest('campaign', 'on-prem'));
    }
  }, [dispatch, onPrem.fetching, onPrem.success]);

  const alreadyRedeemed = window.location.search === '?redeemed=already';
  const error = timerStarted.error || redemption.error;
  const timerState =
    alreadyRedeemed || redemption.success
      ? 'success'
      : error || expired
      ? 'expired'
      : countdownTime
      ? 'during'
      : '';
  let message;
  // Hide message to start timer on desktop
  if (!error && !(timerState === '' && desktop)) {
    message =
      onPremData['timer' + (timerState ? '_' + timerState : '') + '_message'];
  }
  let messagePosition = (brand.timer && brand.timer.messagePosition) || 'above';
  let title =
    onPremData['timer' + (timerState ? '_' + timerState : '') + '_title'];
  const blerb = messagePosition === 'above' ? message : '';

  if (brand.timer && brand.timer.already && alreadyRedeemed) {
    if (brand.timer.already.title) {
      title = brand.timer.already.title;
    }
    if (brand.timer.already.messagePrefix) {
      if (brand.vendor.name === 'Heineken') {
        messagePosition = 'above';
      }
      message = brand.timer.already.messagePrefix + '<br /><br />' + message;
    }
  }
  useEffect(() => {
    dispatchPageConfig({
      type: 'reset-blacklist',
      hero: {
        title,
        blerb,
        present: true,
      },
      pageSlug: 'order',
    });
  }, [dispatchPageConfig, title, blerb, timerState]);

  return (
    <>
      <Head pageTitle="Order" />
      <Box className={'timer ' + (timerState || 'start')} mb={4}>
        <Container maxWidth="sm" className="timerInner">
          {error || timerState === 'expired' ? (
            <Typography color="error" variant="h2" align="center">
              {timerStarted.message ||
                redemption.message ||
                'An error occurred'}
            </Typography>
          ) : (
            <TimerValidator desktop={desktop} timerState={timerState} />
          )}
          {timerState !== 'during' &&
            timerState === 'success' &&
            timerState !== 'expired' && (
              <>
                <Typography
                  color="textPrimary"
                  variant="h2"
                  className="message body__text--primary"
                  align="center"
                >
                  {campaignData.name === 'Furphy Dob or Rob'
                    ? 'Success! Your offer has been redeemed.'
                    : 'Success'}
                </Typography>
                <Typography
                  color="textPrimary"
                  className="message"
                  align="center"
                  style={{ fontSize: '18px', marginTop: '12px' }}
                ></Typography>
                <SocialStickers
                  message={
                    campaignData.name === 'Furphy Dob or Rob'
                      ? 'Follow our socials to stay up to date with our latest unbelievable offers.'
                      : 'Be the first to find out about other unbelievable offers by giving us a follow below:'
                  }
                />
              </>
            )}
        </Container>
      </Box>
    </>
  );
};

export default OrderPage;
