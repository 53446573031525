import React, {
  useRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled, { css } from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { MapBox, GoogleMapContext, Autocomplete } from '@googlemap-react/core';
import { userLocationRequest } from '../../store/location/actions';
import VenueContext from '../../contexts/venue';
import config from '../../config';
import { useTheme } from '@material-ui/styles';
// import { resourceListReadRequest } from '../../store/resource/actions';
import {
  updateMapCenter,
  defaultMapCenter,
} from '../../store/location/actions';
import MapMarker from './../MapMarker';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Box from '@material-ui/core/Box';
import { getResourceSelector } from '../../store/resource/selectors';
// import get from 'lodash/get';
// import { change } from 'redux-form';

const CircleProgress = styled(CircularProgress)`
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
`;

const StyledAutocomplete = styled(Autocomplete)`
  ${({ ...props }) => css`
    position: absolute;
    top: 24px;
    left: 24px;
    width: calc(100% - 48px);
    height: 48px;
    padding: 0 45px 0 12px;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    border: 1px solid rgba(33, 37, 41, 0.3);
    border-radius: 4px;
    // background-image: url(${props['data-icon']});
    background-repeat: no-repeat;
    background-position: right 15px center;
    outline: none;
  `}
`;

const Map = () => {
  const dispatch = useDispatch();

  const { state: googleState } = useContext(GoogleMapContext);
  // const [venue, setVenue] = useState('');
  // const [bounds, setBounds] = useState(false);
  const theme = useTheme();

  const { search } = theme.brand.map['onPrem']; // Search box icon

  const defaultZoom = useSelector(
    state => state.resource.campaign.location.data.map_zoom_level
  );

  const [zoom, setZoom] = useState(defaultZoom || 15);

  const [allVenues, setAllVenues] = useState(false);

  const { venueConfig } = useContext(VenueContext);

  useEffect(() => {
    if (venueConfig.success && !allVenues) {
      let venueArray = [];

      venueConfig.venueData.forEach(venue => {
        venueArray.push(venue);
      });

      setAllVenues(venueArray);
    }
  }, [venueConfig, allVenues, setAllVenues]);

  // const campaignLocation = useSelector(getResourceSelector)([
  //   'campaign',
  //   'location',
  // ]);

  // const userLocationFetching = useSelector(
  //   state => state.location.user.fetching
  // );

  const userLocationDefault = useSelector(
    state => state.location.user.usingDefault
  );

  const userLocationSuccess = useSelector(state => state.location.highAccuracy);
  const userLocationSuccessRef = useRef(userLocationSuccess);
  const userLocationDenied = useSelector(state => state.location.user.denied);
  const userCoords = useSelector(state => state.location.user.coords);
  const userLocationAsked = useSelector(state => state.location.user.asked);

  useEffect(() => {
    if (!userLocationAsked) {
      dispatch(userLocationRequest());
    }
  }, [dispatch, userLocationAsked]);

  useEffect(() => {
    userLocationSuccessRef.current = userLocationSuccess;
  });

  const userLocationSuccessRefPrev = userLocationSuccessRef.current;

  if (!userLocationSuccessRefPrev && userLocationSuccess) {
    if (googleState.map) {
      googleState.map.setCenter({
        lat: Number(userCoords.latitude),
        lng: Number(userCoords.longitude),
      });
      googleState.map.setZoom(zoom);
    }
  }

  const nearestVenuesStatus = useSelector(getResourceSelector)([
    'campaign',
    'venuesNearMe',
  ]);

  // const userLocationSearched = useSelector(
  //   state => state.location.searched
  // );

  // const userLocationRecorded = useSelector(
  //   state => state.location.highAccuracy
  // );

  // const [helperText, setHelperText] = useState('');

  const { latitude, longitude } = useSelector(
    state => state.location.user.coords
  );

  // const venues = useSelector(getVenuesByDistanceSelector)({
  //   location: { latitude, longitude },
  // });

  // const pullVenuesNearMe = useCallback(() => {
  //   if (latitude && longitude) {
  //     // setHelperText('');
  //     dispatch(
  //       resourceListReadRequest(
  //         'campaign',
  //         'venues',
  //         {
  //           coords: `${latitude},${longitude}`,
  //         },
  //         'venuesNearMe'
  //       )
  //     );
  //   }
  // }, [dispatch, latitude, longitude]);

  // Enable the location permission to see venues near you
  // useEffect(() => {
  //   if (userLocationSuccess) {
  //     pullVenuesNearMe();
  //   }
  // }, [userLocationSuccess, pullVenuesNearMe]);

  useEffect(() => {
    if (
      googleState &&
      googleState.map &&
      userLocationDenied &&
      !userLocationDefault
    ) {
      googleState.map.setCenter({ lat: -33.86785, lng: 151.20732 });
      dispatch(defaultMapCenter({ lat: -33.86785, lng: 151.20732 }));
    }
  }, [
    dispatch,
    googleState,
    googleState.map,
    userLocationDenied,
    userLocationDefault,
  ]);

  // useEffect(() => {
  //   // TODO: Turn this into some sort of reducer
  //   if (userLocationFetching) {
  //     setHelperText('Trying to fetch your location...');
  //   } else if (userLocationSuccess) {
  //     setHelperText('');
  //   } else {
  //     setHelperText('Enable the location permission to see venues near you');
  //   }
  //   if (userLocationDenied) { }
  //   if (nearestVenuesStatus.fetching) {
  //     setHelperText('Loading...');
  //   }
  //   if (nearestVenuesStatus.success) {
  //     if (nearestVenuesStatus.data.length === 0) {
  //       setHelperText('No venues found');
  //     } else {
  //       setHelperText('');
  //     }
  //   }
  //   if (nearestVenuesStatus.error) {
  //     setHelperText('Error');
  //   }
  //   if (
  //     (nearestVenuesStatus.success || nearestVenuesStatus.error) &&
  //     venues.length === 0
  //   ) {
  //     setHelperText('No venues found');
  //   }
  // }, [
  //   userLocationDenied,
  //   userLocationFetching,
  //   userLocationSuccess,
  //   nearestVenuesStatus.data.length,
  //   nearestVenuesStatus.error,
  //   nearestVenuesStatus.fetching,
  //   nearestVenuesStatus.success,
  //   venues.length,
  // ]);

  // const location = userLocationRecorded
  //   ? {
  //     latitude: latitude,
  //     longitude: longitude,
  //   } : {
  //     latitude: get(campaignLocation.data, 'location.lat', '-33.86785'),
  //     longitude: get(campaignLocation.data, 'location.lng', '151.20732'),
  //   };

  const onLocationUpdate = useCallback(() => {
    if (
      googleState.objects &&
      googleState.objects.get('search-venues') &&
      googleState.objects.get('search-venues') &&
      googleState.objects.get('search-venues').getPlace()
    ) {
      let newCoords = googleState.objects.get('search-venues').getPlace()
        .geometry.location;

      googleState.map.setCenter(newCoords);

      dispatch(updateMapCenter(newCoords));

      // dispatch(
      //   resourceListReadRequest(
      //     'campaign',
      //     'venues',
      //     {
      //       coords: `${newCoords.lat},${newCoords.lng}`,
      //     },
      //     'venuesNearMe'
      //   )
      // );
    }
  }, [googleState, dispatch]);

  useEffect(() => {
    if (googleState.map) {
      // googleState.map.addListener('zoom_changed', () => {
      //   console.log(googleState.map.zoom);
      //   console.log("statezoom", zoom);
      //   setZoom(googleState.map.zoom);
      // });

      // googleState.map.addListener('dragend', () => {
      //   const centerCoords = googleState.map.center;

      //   googleState.map.setCenter(centerCoords);
      // });
      googleState.map.addListener('places_changed', () => {
        onLocationUpdate();
      });
    }
  }, [
    setZoom,
    onLocationUpdate,
    googleState.map,
    nearestVenuesStatus.error,
    nearestVenuesStatus.fetching,
    nearestVenuesStatus.success,
  ]);

  const selectedVenue = useSelector(state => state.location.venue.selected);

  useEffect(() => {
    // if (selectedVenue) {
    //   const selectedEl = document.querySelector(`li[data-venue-id="${selectedVenue}"]`);
    //   console.log(selectedEl && selectedEl.offsetTop);
    //   venuesList.scrollTo(0, selectedEl && selectedEl.offsetTop || 0);
    // }
  }, [selectedVenue]);

  return (
    <>
      <MapBox
        LoadingComponent={<CircleProgress color="secondary" />}
        apiKey={config.gmapKey}
        opts={{
          center: {
            lat: Number(latitude) || -33.86785,
            lng: Number(longitude) || 151.20732,
          },
          zoom: zoom,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        }}
        style={{ height: '100%', width: '100%' }}
        usePlaces
      />
      <StyledAutocomplete
        id="search-venues"
        placeholder="Search"
        data-icon={search}
        opts={{
          componentRestrictions: {
            country: ['au'],
          },
        }}
        onPlaceChanged={onLocationUpdate}
      />
      {allVenues &&
        allVenues.map(venue => {
          return <MapMarker key={venue.id} {...venue} />;
        })}
    </>
  );
};

export default Map;
