export const RESOURCE_CREATE_REQUEST = 'RESOURCE_CREATE_REQUEST';
export const RESOURCE_CREATE_SUCCESS = 'RESOURCE_CREATE_SUCCESS';
export const RESOURCE_CREATE_FAILURE = 'RESOURCE_CREATE_FAILURE';

export const resourceCreateRequest = (resource, data, endpoint) => ({
  type: RESOURCE_CREATE_REQUEST,
  payload: { data },
  meta: { resource, endpoint },
});

export const resourceCreateSuccess = (resource, detail, request) => ({
  type: RESOURCE_CREATE_SUCCESS,
  payload: detail,
  meta: {
    request,
    resource,
    // https://github.com/diegohaz/arc/wiki/Example-redux-modules#entities
    entities: resource,
  },
});

export const resourceCreateFailure = (resource, error, request) => {
  return {
    type: RESOURCE_CREATE_FAILURE,
    error: true,
    payload: error,
    meta: { request, resource },
  };
};

export const RESOURCE_CLEAR_FAILURE = 'RESOURCE_CLEAR_FAILURE';

export const resourceClearFailure = (resource, detail, request) => {
  return {
    type: RESOURCE_CLEAR_FAILURE,
    payload: detail,
    meta: {
      request,
      resource,
      // https://github.com/diegohaz/arc/wiki/Example-redux-modules#entities
      entities: resource,
    },
  };
};

export const RESOURCE_LIST_READ_REQUEST = 'RESOURCE_LIST_READ_REQUEST';
export const RESOURCE_LIST_READ_SUCCESS = 'RESOURCE_LIST_READ_SUCCESS';
export const RESOURCE_LIST_READ_FAILURE = 'RESOURCE_LIST_READ_FAILURE';

export const resourceListReadRequest = (resource, detail, params, dest) => ({
  type: RESOURCE_LIST_READ_REQUEST,
  payload: { params },
  meta: { resource, detail, dest },
});

export const resourceListReadSuccess = (resource, detail, list, request) => ({
  type: RESOURCE_LIST_READ_SUCCESS,
  payload: list,
  meta: {
    request,
    resource,
    detail,
    entities: resource,
  },
});

export const resourceListReadFailure = (resource, detail, error, request) => ({
  type: RESOURCE_LIST_READ_FAILURE,
  error: true,
  payload: error,
  meta: {
    request,
    resource,
    detail,
  },
});

export const RESOURCE_UPDATE = 'RESOURCE_UPDATE';

export const resourceUpdate = (resource, needle, data) => ({
  type: RESOURCE_UPDATE,
  payload: { needle, data },
  meta: { resource },
});

export const RESOURCE_UPDATE_REQUEST = 'RESOURCE_UPDATE_REQUEST';
export const RESOURCE_UPDATE_SUCCESS = 'RESOURCE_UPDATE_SUCCESS';
export const RESOURCE_UPDATE_FAILURE = 'RESOURCE_UPDATE_FAILURE';

export const resourceUpdateRequest = (resource, endpoint, data = {}) => ({
  type: RESOURCE_UPDATE_REQUEST,
  payload: endpoint,
  meta: { resource, data },
});

export const resourceUpdateSuccess = (resource, detail) => ({
  type: RESOURCE_UPDATE_SUCCESS,
  payload: detail,
  meta: {
    resource,
    entities: resource,
  },
});

export const resourceUpdateFailure = (resource, error) => ({
  type: RESOURCE_UPDATE_FAILURE,
  error: true,
  payload: error,
  meta: { resource },
});

export const RESOURCE_CLEAR = 'RESOURCE_CLEAR';

export const resourceClearResource = (resource, detail) => ({
  type: RESOURCE_CLEAR,
  meta: { resource, detail },
});

export const RESOURCE_ACCESS_TOKEN_DATA = 'RESOURCE_ACCESS_TOKEN_DATA';

export const resourceAccessTokenData = payload => ({
  type: RESOURCE_ACCESS_TOKEN_DATA,
  payload,
});
