import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  getResourcesDataSelector,
  getResourceSelector,
} from '../../store/resource/selectors';
import Image from '../Image';
import PageContext from '../../contexts/page';
// import get from 'lodash/get';

const StyledBox = styled(Box)`
  line-height: 0;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 90px;
    top: -90px;
    left: 0;
  }
`;

const HeroImage = () => {
  const { brand, ...theme } = useTheme();
  const campaignData = useSelector(getResourcesDataSelector)('campaign');
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isQuiz = campaignData.quiz_question_count > 0;
  const quizData = useSelector(getResourceSelector)('quiz');
  const selectedAnswer = quizData.selected_answer || {};
  const selectedAnswerImage = selectedAnswer.image_url || false;
  const { pageConfig } = useContext(PageContext);
  const currentSlug = pageConfig.pageSlug;
  const quizAltExcludeScreens = [
    'home',
    'signUp',
    'participating-venues',
    'whereToClaim',
    'rob',
  ];

  const isQuizSignup = pageConfig.pageSlug === 'signUp' && selectedAnswerImage;

  const heroImage = isDesktop
    ? {
        url: campaignData.hero_image_desktop_url || '',
        meta: campaignData.hero_image_desktop_meta || '',
      }
    : {
        url:
          campaignData.hero_image_mobile_url ||
          campaignData.hero_image_desktop_url ||
          '',
        meta:
          campaignData.hero_image_mobile_meta ||
          campaignData.hero_image_desktop_meta ||
          '',
      };

  return (
    <StyledBox
      display={'block'}
      textAlign="center"
      maxHeight="700px"
      height="100%"
      justifyContent="center"
      bgcolor={
        isQuiz &&
        ((isQuizSignup && selectedAnswer.text === 'Dob') ||
          currentSlug === 'rob')
          ? '#336394'
          : isQuiz
          ? 'transparent'
          : theme.palette.primary.main
      }
      className="hero"
      style={
        ['signUp', 'rob'].includes(currentSlug) ? { padding: '15px 0' } : {}
      }
    >
      {(currentSlug === 'rob' ||
        (isQuizSignup && selectedAnswer.text === 'Dob')) && (
        <Image
          config={heroImage.meta}
          src={`https://xxxx-savannah.s3.ap-southeast-2.amazonaws.com/${selectedAnswerImage}`}
          width="auto"
          height={isDesktop ? 206 : 128}
        />
      )}
      {((!isQuizSignup && currentSlug === 'home') || !isQuiz) && (
        <Image
          config={heroImage.meta}
          src={`https://xxxx-savannah.s3.ap-southeast-2.amazonaws.com/${heroImage.url}`}
          width={heroImage.meta.width}
          height={heroImage.meta.height}
          alt={heroImage.meta.alt}
          title={heroImage.meta.title}
        />
      )}
      {((isQuiz && !quizAltExcludeScreens.includes(currentSlug)) ||
        (isQuizSignup && selectedAnswer.text !== 'Dob')) && (
        <Image
          config={heroImage.meta}
          src={`https://xxxx-savannah.s3.ap-southeast-2.amazonaws.com/campaign_imagery/36/Hn2slTPFM7Af3ak9WmsIM9mRqqnDWvc5sbQwp6ed.svg`}
          width="auto"
          height={isDesktop ? 401 : 274}
        />
      )}
    </StyledBox>
  );
};

export default HeroImage;
