import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { useTheme } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { resourceCreateRequest } from '../../store/resource/actions';
import { saveForLater } from '../../store/premise/actions';
import { getResourceSelector } from './../../store/resource/selectors';
import get from 'lodash/get';
import omit from 'lodash/omit';
import PageContext from '../../contexts/page';
import { useHistory } from 'react-router-dom';
import { getFormValues } from 'redux-form';
import ButtonStates from '../ButtonStates';

const SubmitBtn = styled(ButtonStates)`
  display: block;
  text-align: center;
  margin: ${({ theme }) =>
    `${theme.spacing(4)}px auto ${theme.spacing(4)}px auto`};
`;

const SignUpBtn = ({ redeemSaved = false, disabled, ...props }) => {
  const { brand, ...theme } = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const campaignId = useSelector(state => state.campaign.id);
  const venueId = useSelector(state => state.location.venue.selected);
  const quizData = useSelector(getResourceSelector)('quiz');
  const selectedAnswer = quizData.selected_answer || {};
  const selectedOffer = selectedAnswer.offer || {};
  const offerId = selectedOffer.id || false;
  const customer = useSelector(getResourceSelector)('customer');
  const pathname = history.location.pathname;
  const { pageConfig } = useContext(PageContext);
  const { data: campaignData } = useSelector(getResourceSelector)('campaign');
  const cta_text = useSelector(
    state => state.resource.campaign.data.form_cta_text
  );

  // This is a pretty poor way, but redux-form is a real pain in the arse
  const formData = useSelector(state => get(state.form, 'signup.values', {}));

  const [clicked, setClicked] = useState(false);
  const signupValues = useSelector(getFormValues('signup'));
  const redeemTime = get(signupValues, 'redeem_time', '');

  useEffect(() => {
    if (clicked && (customer.success || customer.code)) {
      let path;

      switch (pageConfig.pageSlug) {
        case 'signUp':
          if (
            Object.keys(selectedAnswer).length > 0 &&
            selectedAnswer.text === 'Dob'
          ) {
            path = 'congratulations';
          } else {
            if (redeemTime !== 'redeem-now') {
              dispatch(saveForLater());
              path = 'save-for-later';
            } else {
              path =
                ['on-prem', 'event'].indexOf(campaignData.type) !== -1
                  ? 'where-to-claim'
                  : 'save-for-later';
            }
          }
          break;
        case 'saveForLater':
          path = 'where-to-claim';
          break;
        case 'whereToClaim':
          path = 'order';
          break;
        default:
          path = '';
      }

      setClicked(false);

      history.push(`/${path}${history.location.search}`);
    }
  }, [
    pathname,
    pageConfig.pageSlug,
    campaignData.type,
    clicked,
    customer,
    customer.success,
    dispatch,
    history,
    redeemSaved,
    redeemTime,
    customer.code,
    selectedAnswer,
  ]);

  const handleClick = (dispatch, campaign_id, venue_id, offer_id, data) => {
    setClicked(true);
    if (!customer.success && !redeemSaved) {
      const opt_in_1 = get(data, 'opt_in_1', false);
      const opt_in_2 = get(data, 'opt_in_2', false);
      const redeem_time = get(data, 'redeem_time');

      // if opt_ins formats are changed pls update the corresponding code in the backend API
      const dataToSend = {
        //...omit(data, ['terms', 'opt_in_1', 'opt_in_2']),
        //opt_in: [opt_in_1, opt_in_2],

        ...omit(data, ['terms', 'opt_in_1', 'opt_in_2']),
        // if opt_in isn't included we want it to be false
        opt_in_1: opt_in_1,
        opt_in_2: opt_in_2,
        ...(campaign_id && { campaign_id }),
        ...(venue_id && { venue_id }),
        ...(offer_id && { offer_id }),
        ...(!redeem_time && offer_id && { redeem_time: 'save-for-later' }),
      };

      dispatch(
        resourceCreateRequest('customer', {
          ...dataToSend,
        })
      );
    }
  };

  const savedForLater_ctaText = brand.saveForLater && brand.saveForLater.button;

  return (
    <SubmitBtn
      className="subBtn"
      onClick={() => {
        handleClick(dispatch, campaignId, venueId, offerId, formData);
      }}
      disabledTip={
        !redeemSaved ? 'Complete the form before continuing.' : 'Please wait...'
      }
      theme={theme}
      {...props}
      variant="contained"
      size="large"
      disabled={disabled || customer.fetching || (redeemSaved && clicked)}
      loading={customer.fetching}
    >
      {(redeemSaved && savedForLater_ctaText) || cta_text || 'Continue'}
    </SubmitBtn>
  );
};

export default SignUpBtn;
