import { campaignId } from '../../config';

export const initialState = {
  id: campaignId,
  campaigns: [
    { name: 'DEFAULT', id: '0' },
    { name: 'Furphy', id: '2' },
    { name: 'Lion', id: '1' },
    { name: 'Iron Jack', id: '3' },
    { name: 'Byron Bay', id: '4' },
    { name: 'Furphy - on-prem', id: '5' },
    { name: 'XXXX', id: '10' },
    { name: 'Guinness', id: '11' },
    { name: 'Heineken 0.0', id: '12' },
    { name: 'Broken Shackles', id: '13' },
    { name: 'Heineken', id: '16' },
  ],
};
