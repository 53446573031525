// https://github.com/diegohaz/arc/wiki/Reducers
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import get from 'lodash/get';
import { initialState } from './selectors';
import {
  RESOURCE_CREATE_REQUEST,
  RESOURCE_CREATE_SUCCESS,
  RESOURCE_CREATE_FAILURE,
  RESOURCE_CLEAR_FAILURE,
  RESOURCE_LIST_READ_REQUEST,
  RESOURCE_LIST_READ_SUCCESS,
  RESOURCE_LIST_READ_FAILURE,
  RESOURCE_ACCESS_TOKEN_DATA,
  RESOURCE_UPDATE,
  RESOURCE_UPDATE_REQUEST,
  RESOURCE_UPDATE_SUCCESS,
  RESOURCE_UPDATE_FAILURE,
  RESOURCE_CLEAR,
} from './actions';

export default (state = initialState, { type, payload, meta }) => {
  if (type === RESOURCE_ACCESS_TOKEN_DATA) {
    return {
      ...state,
      access_token_data: payload,
    };
  }

  const resource = get(meta, 'resource');
  const detail = get(meta, 'detail');

  const detailChange = newDetailState => ({
    [detail]: {
      ...state[resource][detail],
      ...newDetailState,
    },
  });
  if (
    [
      RESOURCE_CREATE_FAILURE,
      RESOURCE_UPDATE_FAILURE,
      RESOURCE_LIST_READ_FAILURE,
    ].indexOf(type) !== -1
  ) {
    if (payload.message === 'notActiveRedirect') {
      state.campaign.data.active = false;
      payload.message = 'Campaign is not active';
    }
  }

  switch (type) {
    case RESOURCE_CREATE_REQUEST:
      return {
        ...state,
        [resource]: {
          ...state[resource],
          fetching: true,
        },
      };
    case RESOURCE_CREATE_SUCCESS:
    case RESOURCE_UPDATE_SUCCESS:
      return {
        ...state,
        [resource]: {
          ...state[resource],
          ...payload,
          fetching: false,
          success: true,
          error: false,
          message: '',
        },
      };
    case RESOURCE_CREATE_FAILURE:
    case RESOURCE_UPDATE_FAILURE:
      return {
        ...state,
        [resource]: {
          ...state[resource],
          fetching: false,
          error: true,
          errorScroll: true,
          message: payload.message,
          errors: { ...state[resource].errors, ...payload.errors },
          success: false,
        },
      };
    case RESOURCE_CLEAR_FAILURE:
      let updatedResource = { ...state[resource] };
      Object.keys(state[resource].errors).forEach(k => {
        if (payload === k || payload === undefined) {
          delete updatedResource.errors[k];
        }
      });
      if (!updatedResource.errors.length) {
        updatedResource.message = '';
      }
      return {
        ...state,
        [resource]: updatedResource,
      };
    case RESOURCE_LIST_READ_REQUEST: {
      let update = {
        fetching: true,
      };
      if (resource === 'campaign' && detail) {
        update = detailChange(update);
      }
      return {
        ...state,
        [resource]: {
          ...state[resource],
          ...update,
        },
      };
    }
    case RESOURCE_LIST_READ_SUCCESS: {
      let update = {
        data: payload,
        fetching: false,
        fetched: true,
        success: true,
        message: undefined,
      };
      if (resource === 'campaign' && detail) {
        update = detailChange(update);
      }
      return {
        ...state,
        [resource]: {
          ...state[resource],
          ...update,
        },
      };
    }
    case RESOURCE_LIST_READ_FAILURE: {
      let update = {
        error: true,
        message: payload.message,
        // fetching: false,
      };
      if (resource === 'campaign' && detail) {
        update = detailChange(update);
      }
      return {
        ...state,
        [resource]: {
          ...state[resource],
          ...update,
        },
      };
    }
    case RESOURCE_UPDATE: {
      return {
        ...state,
        [resource]: {
          ...state[resource],
          [payload.needle]: payload.data,
        },
      };
    }
    case RESOURCE_UPDATE_REQUEST: {
      return {
        ...state,
        [resource]: {
          ...state[resource],
          [payload.needle]: payload.data,
        },
      };
    }
    case RESOURCE_CLEAR: {
      const detailReset = initalResourceState => ({
        [detail]: initalResourceState,
      });
      const initalResourceState = get(
        initialState,
        [resource, detail],
        get(initialState, resource, {})
      );
      const update = detail
        ? detailReset(initalResourceState)
        : initalResourceState;
      return {
        ...state,
        [resource]: update,
      };
    }

    default:
      return state;
  }
};
