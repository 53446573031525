const url = `${process.env.PUBLIC_URL}/brands/heineken/`;

const HeinekenSerif18RegularOtf = `${url}fonts/HeinekenSerif18-Regular.otf`;
const HeinekenSerif18BoldOtf = `${url}fonts/HeinekenSerif18-Bold.otf`;

const heinekenFont = {
  fontFamily: 'Heineken Serif',
  fontWeight: 'normal',
  src: `
    url('${HeinekenSerif18RegularOtf}') format('opentype');
  `,
};

const heinekenBold = {
  fontFamily: 'Heineken Serif Bold',
  fontWeight: 'bold',
  src: `
    url('${HeinekenSerif18BoldOtf}') format('opentype');
  `,
};

const bodyFont = {
  fontFamily: [
    heinekenFont.fontFamily,
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
};

const boldFont = {
  fontFamily: [heinekenBold.fontFamily, 'sans-serif'].join(','),
};

const palette = {
  background: {
    default: '#FFFFFF',
    paper: 'transparent',
  },
  primary: {
    main: '#007F2E',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#FFFFFF',
    contrastText: '#007F2E',
  },
  text: {
    primary: '#FFF',
    secondary: '#007F2E',
  },
  action: {
    active: '#000000',
  },
  divider: 'rgba(255, 255, 255, 0.4)',
};

const paletteDark = {
  text: {
    primary: '#FFFFFF',
    secondary: '#007F2E',
  },
  action: {
    active: '#fff',
  },
};

const typography = {
  h1: {
    ...boldFont,
    fontSize: '7rem',
    // textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  h2: {
    ...boldFont,
    fontSize: '2.75rem',
    // textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  h3: { ...boldFont, textTransform: 'uppercase', fontWeight: 'bold' },
  h4: { ...boldFont, fontWeight: 'bold' },
  h5: { ...boldFont, fontWeight: 'bold', fontSize: '1.125 rem' },
  h6: { ...boldFont, fontWeight: 'bold', fontSize: '1rem' },
  caption: { ...bodyFont, fontSize: '12px', fontWeight: 'lighter' },
  body1: bodyFont,
  body2: bodyFont,
  subtitle1: { ...boldFont, fontWeight: 'bold' },
  subtitle2: { ...boldFont, textTransform: 'uppercase', fontWeight: 'bold' },
  button: { ...bodyFont, textTransform: 'initial' },
};

export const heineken = {
  name: 'Heineken',
  id: 13,
  css: 'heineken',
  mode: 'light',
  campaigns: ['16'],
  userTheme: {
    default: {
      mui: {
        mixins: {
          toolbar: {
            minHeight: 70,
          },
        },
        typography,
        palette,
        props: {
          MuiButton: {
            color: 'secondary',
            variant: 'contained',
            size: 'large',
          },
          MuiLink: {
            underline: 'always',
            color: 'secondary',
          },
          MuiCheckbox: {
            color: 'secondary',
          },
          MuiRadio: {
            color: 'secondary',
          },
        },
        overrides: {
          MuiMenu: {
            list: {
              backgroundColor: '#fff',
            },
          },
          MuiCssBaseline: {
            '@global': {
              '@font-face': [heinekenFont, heinekenBold],
            },
          },
          MuiButton: {
            root: {
              borderRadius: '1em',
              lineHeight: '1',
            },
            contained: {
              boxShadow: 'none',
            },
            containedPrimary: {
              color: '#000',
              backgroundColor: '#fff',
              '&:hover': {
                backgroundColor: 'rgb(178, 178, 178)',
              },
            },
            containedSizeLarge: {
              fontSize: '1.25rem',
              padding: '0.75rem 2rem',
            },
            label: typography.button,
          },
          MuiInputLabel: {
            root: {
              color: palette.text.primary,
              fontSize: '18px',
              '&$focused': {
                color: palette.text.primary,
              },
              '&$error': {
                color: '#f44336',
              },
            },
          },
          MuiInputBase: {
            root: {
              fontFamily: [heinekenFont.fontFamily],
              fontSize: '18px',
            },
          },
          MuiCheckbox: {
            root: {
              color: '#ffffff',
            },
          },
          MuiRadio: {
            root: {
              color: '#ffffff',
            },
          },
          MuiExpansionPanelSummary: {
            root: {
              padding: '0',
            },
          },
        },
      },
      brand: {
        paper: false,
        vendor: {
          name: 'Heineken',
          image: {
            url: `${url}vendor.svg`,
            alt: 'Heineken',
          },
        },
        home: {
          layout: {
            direction: 'row',
            sections: [
              {
                id: 'first',
                responsive: {
                  sizes: {
                    xs: 12,
                    sm: 7,
                  },
                  order: {
                    xs: 0,
                    lg: 0,
                  },
                },
                components: [''],
              },
              {
                id: 'second',
                responsive: {
                  sizes: {
                    xs: 12,
                    sm: 5,
                  },
                  order: {
                    xs: 0,
                    lg: 0,
                  },
                },
                components: [
                  'landing_title',
                  'landing_subtitle',
                  'secondary',
                  'landing_description',
                  'cta',
                ],
              },
            ],
          },
          howTo: {
            active: false,
            icon: {
              color: palette.primary.main,
              marginTop: '5px',
            },
            disclaimer: {
              color: palette.primary.main,
              text: "*make sure you're in the venue before you redeem",
            },
          },
          images: {
            hero: {
              fullSize: true,
            },
            secondary: {
              maxWidth: { mobile: '270px', desktop: '335px' },
            },
          },
          // subTitle: {
          //   text: 'Buy one give one on us',
          //   westernText: '#SocialiseReponsibly',
          //   variant: 'h6',
          // },
          secondaryImage: {
            justifycontent: 'flex-end',
          },
        },
        lists: {
          point: `${url}number-point.svg`,
          fontColor: palette.secondary.contrastText,
          height: '60px',
          width: '60px',
        },
        quiz: {
          layout: {
            naked: true,
          },
        },
        logo: {
          url: `${url}logo.png`,
          alt: 'Heineken',
        },
        header: {
          height: '64px',
          border: false,
          shadow: true,
          logo: {
            url: `${url}header-logo.svg`,
            alt: 'Heineken',
            height: '64px',
            paddingTop: '64px',
            position: 'left',
          },
        },
        draw: {
          side: 'right',
          inverse: true,
        },
        hero: {
          background: { color: 'transparent' },
          inverse: true,
          subtitle: {
            text: '#SocialiseResponsibly',
            exclude: ['privacy', 'terms', 'where-to-claim'],
          },
        },
        footer: {
          drinkwise: false,
        },
        heroImage: {
          maxWidth: '230px',
        },
        nav: {
          // burgerColor: '#fff',
          // desktopColor: '#fff',
        },
        faq: {
          title: 'FAQs',
        },
        signup: {
          indicator: false,
          dobCaption:
            '*We need this to confirm you are of legal drinking age. dd/mm/yyyy',
          postcodeCaption: null,
          tncCaption: 'T&Cs apply.',
        },
        wheretoclaim: {
          blerb: 'Select your local participating venue below:',
          includeSuburb: true,
          message:
            'The offer is valid for 15 minutes. Show timer to the bar staff, purchase your first Heineken and they will validate your free Heineken to give to a friend.',
        },
        timer: {
          image: {
            url: `${url}home-background.png`,
            alt: 'Heineken',
          },
          messagePosition: 'below',
          success: {
            socialText:
              'Find out more about our upcoming promotions by following us on:',
            logo: `${url}HeinekenLogoHomepage.svg`,
            // button: {
            //   url: 'https://www.jamessquire.com.au/brewhouses/',
            //   text: 'Continue',
            // },
          },
          during: {
            button: 'Offer redeemed',
          },
          expired: {
            title: ' ',
          },
          already: {
            title: 'WHOOPS!',
            messagePrefix:
              '<strong>Looks like your offer has already been redeemed.</strong>',
          },
          // description: {
          //   text: 'Your free imperial pint for a friend, is now on us!',
          // },
          socialStickers: {
            instagram: {
              url: `${url}/Instagram.svg`,
            },
            facebook: {
              url: `${url}/Facebook.svg`,
            },
          },
        },
        map: {
          inverse: false,
          onPrem: {
            color: 'primary',
            inactive: `${url}on-prem-marker.svg`,
            active: `${url}on-prem-marker-active.svg`,
          },
          offPrem: {
            color: 'primary',
            inactive: `${url}off-prem-marker.svg`,
            active: `${url}off-prem-marker-active.svg`,
          },
        },
        finished: {
          inverse: false,
          message:
            'You can find out more about future promotions by following us on Facebook or Instagram.',
        },
        background: {
          value: {
            xs: `url(${url}streamers.png) top / contain no-repeat`,
          },
          exclude: [],
        },
        typography: {
          h1: { color: 'textSecondary' },
          h2: { color: 'textSecondary' },
          h3: { color: 'textSecondary' },
          h4: { color: 'textSecondary' },
          h5: { color: 'textSecondary' },
          h6: { color: 'textSecondary' },
        },
      },
    },
    light: {
      mui: {
        palette: {},
      },
      brand: {},
    },
    dark: {
      mui: {
        palette: paletteDark,
      },
      brand: {},
    },
  },
};
