import { createSelector } from 'reselect';
import { getDistance } from 'geolib';
import get from 'lodash/get';

const initialResourceStatus = {
  fetching: false,
  success: false,
  error: false,
};

export const initialResourceState = {
  ...initialResourceStatus,
  data: [],
};

const venueInitalState = {
  id: '',
  name: '',
  google_place_id: 1,
  venue_type: 1,
  latitude: '0',
  longitude: '0',
  street_address: '',
  suburb: '',
  state: '',
  postcode: '',
};

const quizInitialState = {
  selected_answer: false,
};

export const additional_menu_item = { name: '', url: '' };

export const campaignVenueResourceState = {
  ...initialResourceState,
  data: {
    finish: '',
    vendor: {
      name: '',
      image: {
        url: '',
        alt: '',
      },
    },
    background_image: {
      url: '',
    },
    active: true,
    additional_menu_items: [],
    additional_menu_pages: [],
    inactive_message: '',
    name: '',
    type: '',
    description: '',
    hero_image: {
      url: '',
      alt: '',
    },
    secondary_image: {
      url: '',
      alt: '',
    },
    has_faqs: '',
    quiz_question_count: 0,
    terms: '',
    landing_title: '',
    landing_description: '',
    howto_title: '',
    howto_step1: '',
    howto_step2: '',
    howto_step3: '',
    howto_step4: '',
    howto_cta_text: '',
    form_title: '',
    form_description: '',
    form_cta_text: '',
    offer_title: '',
    offer_timer: '',
    offer_expired: '',
    offer_confirmation: '',
    facebook: '',
    instagram: '',
    youtube: '',
    copyright: '',
    subscription_checkbox_1_label: '',
    subscription_checkbox_1_required: false,
    subscription_checkbox_2_label: '',
    subscription_checkbox_2_required: false,
    venues_page: true,
  },
};

const initialLocationState = {
  location: { lat: '', lng: '' },
  map_zoom_level: 15,
};

export const initialState = {
  campaign: {
    ...campaignVenueResourceState,
    venuesNearMe: initialResourceState,
    venuesSearch: initialResourceState,
    quiz: initialResourceState,
    faqs: {
      ...initialResourceState,
      data: { image: { url: '', alt: '' }, faqs: [] },
    },
    wallet: { ...initialResourceState, data: {} },
    location: {
      ...initialResourceState,
      data: initialLocationState,
    },
    all_venues: {
      ...initialResourceState,
      success: false,
      fetching: false,
      fetched: false,
      data: {
        data: {},
      },
    },
  },
  quiz: {
    ...quizInitialState,
  },
  customer: {
    message: '',
    error: false,
    errors: {
      selected_venue: '',
      campaign_id: '',
      dob: '',
      email: '',
      first_name: '',
      last_name: '',
      postcode: '',
      receipt: false,
    },
    success: false,
  },
};

// TODO: This maybe belongs somewhere else.
const sortLatLngFunction = venues => {
  return ({ location }) => {
    return venues.data.sort((a, b) => {
      if (
        getDistance(
          { longitude: a.longitude, latitude: a.latitude },
          location
        ) >
        getDistance({ longitude: b.longitude, latitude: b.latitude }, location)
      ) {
        return 1;
      }
      return -1;
    });
  };
};

const ourExpensiveFunction = resources => {
  return filters => {
    return resources[filters.resource][filters.detail];
  };
};

const resourceData = resources => {
  return resource => {
    const response = resources[resource]
      ? resources[resource]
      : initialState[resource];
    return response.data;
  };
};

const resource = resources => {
  return resource => {
    const results = get(
      resources,
      resource,
      get(initialState, resource, initialResourceState)
    );
    return { ...initialResourceStatus, ...results };
  };
};

export const getResourceSelector = createSelector(
  state => state.resource,
  resource
);

export const onPremDataSelector = () => {
  return createSelector(
    state => getResourceSelector(state)(['campaign', 'on-prem']),
    resource => {
      return {
        timer_cta_text: '',
        timer_cta_subtext: '',
        timer_expired_message: '',
        timer_expired_terms: '',
        timer_success_message: '',
        timer_success_title: '',
        timer_title: '',
        ...resource.data,
      };
    }
  );
};

export const getResourcesDataSelector = createSelector(
  state => state.resource,
  resourceData
);

export const getResourceDetailStateSelector = createSelector(
  state => state.resource,
  ourExpensiveFunction
);

export const getVenuesByDistanceSelector = createSelector(
  state => state.resource.campaign.venuesNearMe,
  sortLatLngFunction
);

const venueFinder = venues => {
  return id => {
    return {
      ...venueInitalState,
      ...venues.filter(venue => venue.id === id)[0],
    };
  };
};

export const venueByIdSelector = createSelector(
  state => state.resource.campaign.all_venues.data,
  venueFinder
);

export const accessTokenDataSelector = createSelector(
  state => state.resource,
  resource => resource.access_token_data
);
