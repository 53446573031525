import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Field } from 'redux-form';

const required = value => (value ? undefined : 'Required');

const SignUpNowOrLater = props => {
  const radioButton = ({ input, ...rest }) => (
    <FormControl>
      <RadioGroup {...input} {...rest}>
        <FormControlLabel
          required
          className="radioField"
          value="redeem-now"
          control={<Radio />}
          label="Redeem now"
        />
        <FormControlLabel
          required
          className="radioField"
          value="save-for-later"
          control={<Radio />}
          label="Save for later"
        />
      </RadioGroup>
    </FormControl>
  );

  return (
    <Field
      id="redeem_time"
      required
      validate={required}
      name="redeem_time"
      component={radioButton}
      {...props}
    >
      <Radio color="primary" value="redeem-now" label="redeem-now" />
      <Radio color="primary" value="save-for-later" label="save-for-later" />
    </Field>
  );
};

export default SignUpNowOrLater;
