import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import Head from '../../Head';
import { getResourcesDataSelector } from '../../../store/resource/selectors';
import SocialStickers from './../../SocialStickers';
import PageContext from '../../../contexts/page';
import { useTheme } from '@material-ui/styles';

const FinishedCampaignPage = () => {
  const campaignData = useSelector(getResourcesDataSelector)('campaign');
  const { brand } = useTheme();
  const { dispatchPageConfig } = useContext(PageContext);

  useEffect(() => {
    dispatchPageConfig({
      type: 'reset-blacklist',
      hero: {
        title: campaignData.inactive_message,
        titleColour: brand.finished && brand.finished.titleColor,
        present: true,
        logo: brand.finished ? brand.finished.logo : true,
        border: false,
        inverse: brand.finished && brand.finished.inverse,
      },
      footer: { stamp: false },
      pageSlug: 'finishedCampaign',
    });
  }, [campaignData.inactive_message, brand.finished, dispatchPageConfig]);

  return (
    <>
      <Head pageTitle="Campaign Finished" />
      <Container maxWidth="md" component="section">
        <SocialStickers
          message={
            (brand.finished && brand.finished.message) ||
            'You can find out more about our upcoming promotions by following us on:'
          }
        />
      </Container>
    </>
  );
};

export default FinishedCampaignPage;
