// https://github.com/diegohaz/arc/wiki/API-service
import 'whatwg-fetch';
import { stringify } from 'query-string';
import merge from 'lodash/merge';
import { apiUrl as staticApiUrl } from '../../config';
import get from 'lodash/get';

export const checkStatus = response => {
  if (response.ok) {
    return response.json().then(function (object) {
      return object;
    });
  } else if (response.status === 404) {
    throw new Error(404);
  } else {
  }
  return response.json().then(function ({ message = '', errors = [] }) {
    const error = new Error(message || response.statusText);
    error.response = response;
    error.errors = errors;
    throw error;
  });

  // if (response.ok) {
  //   return response;
  // }
  // const error = new Error(`${response.status} ${response.statusText}`);
  // error.response = response;
  // throw error;
};

export const parseJSON = response => response.json();

const parseParams = params => {
  if (get(params, 'coords', false)) {
    return `/@${params.coords}`;
  }
  return `?${stringify(params)}`;
};

export const parseSettings = ({
  method = 'get',
  data,
  locale,
  ...otherSettings
} = {}) => {
  let formData = new FormData();

  if (data) {
    Object.entries(data).forEach(([key, val]) => {
      if (Array.isArray(val)) {
        val.forEach((currVal, i) => {
          formData.append(`${key}[${i}]`, currVal);
        });
      } else {
        formData.append(key, val);
      }
    });

    return merge(
      {
        body: formData,
        method,
        headers: {
          Accept: 'application/json',
          // 'Accept-Language': locale,
        },
      },
      otherSettings
    );
  } else {
    return merge(
      {
        method,
        headers: {
          Accept: 'application/json',
          // 'Accept-Language': locale,
        },
      },
      otherSettings
    );
  }
};

export const parseEndpoint = (endpoint, params, apiUrl) => {
  const url =
    endpoint.indexOf('http') === 0
      ? endpoint
      : apiUrl.path + endpoint + (apiUrl.local ? '.json' : '');

  const querystring = params ? parseParams(params) : '';

  return `${url}${apiUrl.local ? '' : querystring}`;
};

const api = {};

api.request = (
  endpoint,
  { params, apiUrl = staticApiUrl, ...settings } = {}
) => {
  // If we're running we're posting without an API just return ok.
  if (apiUrl.local && settings.method === 'post') {
    return true;
  }

  return fetch(
    parseEndpoint(endpoint, params, apiUrl),
    parseSettings(settings)
  ).then(checkStatus);
  // .then(parseJSON)
};

['delete', 'get'].forEach(method => {
  api[method] = (endpoint, { params, apiUrl = staticApiUrl, ...settings }) =>
    api.request(parseEndpoint(endpoint, params, apiUrl), {
      method,
      ...settings,
    });
});

['post', 'put', 'patch'].forEach(method => {
  api[method] = (endpoint, { params, apiUrl = staticApiUrl, ...settings }) => {
    return api.request(parseEndpoint(endpoint, params, apiUrl), {
      method,
      ...settings,
    });
  };
});

api.create = (settings = {}) => ({
  settings,

  setToken(token) {
    this.settings.headers = {
      ...this.settings.headers,
      Authorization: `Bearer ${token}`,
    };
  },

  unsetToken() {
    this.settings.headers = {
      ...this.settings.headers,
      Authorization: undefined,
    };
  },

  request(endpoint, settings) {
    return api.request(endpoint, merge({}, this.settings, settings));
  },

  post(endpoint, data, settings) {
    return this.request(endpoint, { method: 'post', data, ...settings });
  },

  get(endpoint, settings) {
    return this.request(endpoint, { method: 'get', ...settings });
  },

  put(endpoint, data, settings) {
    return this.request(endpoint, { method: 'put', data, ...settings });
  },

  patch(endpoint, data, settings) {
    return this.request(endpoint, { method: 'patch', data, ...settings });
  },

  delete(endpoint, settings) {
    return this.request(endpoint, { method: 'delete', ...settings });
  },
});

export default api;
