import React, { forwardRef } from 'react';
import styled from 'styled-components/macro';
import MenuItem from '@material-ui/core/MenuItem';
import Distance from '../Distance';
import { useTheme } from '@material-ui/styles';

const DistancePositioned = styled(Distance)`
  margin-left: auto;
`;

const VenueDropdownItem = forwardRef(({ venue, ...props }, ref) => {
  const { name, suburb, latitude, longitude } = venue;

  const { brand } = useTheme();
  let label = name;
  if (brand.wheretoclaim && brand.wheretoclaim.includeSuburb) {
    label += ' - ' + suburb;
  }
  return (
    <MenuItem ref={ref} {...props}>
      {/* <Box width="100%" display="flex"> */}
      <span>{label}</span>
      <DistancePositioned
        component="span"
        variant="body2"
        destination={{ latitude, longitude }}
      />
      {/* </Box> */}
    </MenuItem>
  );
});

export default VenueDropdownItem;
