const url = `${process.env.PUBLIC_URL}/brands/brokenShackles/`;
const brandUrl = `${process.env.PUBLIC_URL}/brands/brokenShackles/`;
const campaignUrl = `${process.env.PUBLIC_URL}/campaign/brokenShackles/`;

const KnockoutHTF49LiteweightRegularOtf = `${url}fonts/KnockoutHTF49LiteweightRegular.otf`;
const KnockoutHTF70FullWelterwtRegular = `${url}fonts/KnockoutHTF70FullWelterwtRegular.otf`;

const knockout = {
  fontFamily: 'Knockout HTF49-Liteweight',
  fontWeight: 'normal',
  src: `
    url('${KnockoutHTF49LiteweightRegularOtf}') format('opentype');
  `,
};

const knockoutBold = {
  fontFamily: 'Knockout HTF70-FullWelterwt',
  fontWeight: 'bold',
  src: `
    url('${KnockoutHTF70FullWelterwtRegular}') format('opentype');
  `,
};

const bodyFont = {
  fontFamily: [
    knockout.fontFamily,
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
};

const boldFont = {
  fontFamily: [knockoutBold.fontFamily, 'sans-serif'].join(','),
};

const palette = {
  background: {
    default: 'transparent',
    paper: 'transparent',
  },
  primary: {
    main: '#000',
    contrastText: '#fff',
  },
  secondary: {
    main: '#fff',
    contrastText: '#000',
  },
  text: {
    primary: '#000000',
    secondary: '#000000',
  },
  action: {
    active: '#000000',
  },
};

const paletteDark = {
  text: {
    primary: '#000',
    secondary: '#000',
  },
  action: {
    active: '#fff',
  },
};

const typography = {
  h1: {
    ...boldFont,
    fontSize: '7rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  h2: {
    ...boldFont,
    fontSize: '3.19rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  h3: { ...boldFont, textTransform: 'uppercase', fontWeight: 'bold' },
  h4: { ...boldFont, fontWeight: 'bold' },
  h5: { ...boldFont, fontWeight: 'bold' },
  h6: { ...boldFont, fontWeight: 'bold' },
  caption: { ...bodyFont, fontSize: '13px', fontWeight: 'lighter' },
  body1: bodyFont,
  body2: bodyFont,
  subtitle1: { ...boldFont, fontWeight: 'bold' },
  subtitle2: { ...boldFont, textTransform: 'uppercase', fontWeight: 'bold' },
  button: { ...bodyFont, textTransform: 'uppercase' },
};

export const brokenShackles = {
  name: 'Broken Shackles',
  id: 11,
  css: 'brokenShackles',
  mode: 'light',
  campaigns: ['12'],
  userTheme: {
    default: {
      mui: {
        mixins: {
          toolbar: {
            minHeight: 70,
          },
        },
        typography,
        palette,
        props: {
          MuiButton: {
            color: 'secondary',
            variant: 'contained',
            size: 'large',
          },
          MuiLink: {
            underline: 'always',
          },
          MuiCheckbox: {
            color: 'secondary',
          },
          MuiRadio: {
            color: 'secondary',
          },
        },
        overrides: {
          MuiMenu: {
            list: {
              backgroundColor: '#fff',
            },
          },
          MuiCssBaseline: {
            '@global': {
              '@font-face': [knockout, knockoutBold],
            },
          },
          MuiButton: {
            root: {
              borderRadius: '1em',
              lineHeight: '1',
            },
            contained: {
              boxShadow: 'none',
            },
            containedPrimary: {
              color: '#000',
              backgroundColor: '#fff',
              '&:hover': {
                backgroundColor: 'rgb(178, 178, 178)',
              },
            },
            containedSizeLarge: {
              fontSize: '1.25rem',
              padding: '0.75rem 2rem',
            },
            label: typography.button,
          },
          MuiInputLabel: {
            root: {
              color: palette.text.primary,
              fontSize: '18px',
              '&$focused': {
                color: palette.text.primary,
              },
              '&$error': {
                color: '#f44336',
              },
            },
          },
          MuiInputBase: {
            root: {
              fontFamily: [knockout.fontFamily],
              fontSize: '18px',
            },
          },
          MuiCheckbox: {
            root: {
              color: '#fff',
            },
          },
          MuiRadio: {
            root: {
              color: '#fff',
            },
          },
          MuiExpansionPanelSummary: {
            root: {
              padding: '0',
            },
          },
        },
      },
      brand: {
        paper: false,
        vendor: {
          name: 'Star Liquor',
          image: {
            url: `${url}vendor.svg`,
            alt: 'Star Liquor',
          },
        },
        home: {
          layout: {
            direction: 'row',
            sections: [
              {
                id: 'first',
                responsive: {
                  sizes: {
                    xs: 12,
                    sm: 12,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: [
                  'hero',
                  'landing_title',
                  'landing_description',
                  'cta',
                ],
              },
            ],
          },
          howTo: {
            active: false,
            icon: {
              color: palette.primary.main,
              marginTop: '5px',
            },
          },
          images: {
            hero: {
              fullSize: true,
            },
            secondary: {
              maxWidth: { mobile: '270px', desktop: '335px' },
            },
          },
        },
        lists: {
          point: `${url}number-point.svg`,
          fontColor: palette.secondary.contrastText,
        },
        quiz: {
          layout: {
            naked: true,
          },
        },
        logo: {
          url: `${url}logo.png`,
          alt: 'James Squire Broken Shackles',
        },
        header: {
          height: '64px',
          border: false,
          shadow: true,
          logo: {
            url: `${url}header-logo.png`,
            alt: 'James Squire',
            height: '64px',
            position: 'left',
          },
        },
        draw: {
          side: 'right',
          inverse: true,
        },
        hero: {
          background: { color: 'transparent' },
          inverse: true,
        },
        footer: {
          inverse: true,
          logo: {
            url: `${brandUrl}logo-light.svg`,
            alt: 'James Squire',
            width: '54px',
            height: '54px',
          },
          partner: {
            url: `${campaignUrl}bws.png`,
            alt: 'BWS',
            width: '106px',
            height: '40px',
          },
          drinkwise: false,
        },
        heroImage: {
          maxWidth: '230px',
        },
        nav: {
          burgerColor: '#fff',
          desktopColor: '#fff',
        },
        faq: {
          title: 'FAQs',
        },
        signup: {
          indicator: true,
          dobCaption:
            '*We need this to confirm you are of legal drinking age. dd/mm/yyyy',
          postcodeCaption: null,
        },
        wheretoclaim: {
          blerb: 'Select your local participating brewhouse below',
          includeSuburb: true,
          message:
            'Press the button below, but don’t start the timer until you’re at the venue and ready to order. The offer is only valid for 15 minutes after activating.',
        },
        timer: {
          image: {
            url: `${url}logo.png`,
            alt: 'James Squire Broken Shackles',
          },
          messagePosition: 'below',
          success: {
            button: {
              url: 'https://www.jamessquire.com.au/brewhouses/',
              text: 'Broken Shackles Brewhouse Finder',
            },
          },
          during: {
            button: 'Validate Offer (Bar Staff Only)',
          },
          expired: {
            title: "Time's Up!",
          },
          already: {
            title: 'WHOOPS!',
            messagePrefix:
              "<strong>Looks like you've already claimed your free pint!</strong>",
          },
        },
        map: {
          inverse: false,
          onPrem: {
            color: 'primary',
            inactive: `${url}on-prem-marker.svg`,
            active: `${url}on-prem-marker-active.svg`,
          },
          offPrem: {
            color: 'primary',
            inactive: `${url}off-prem-marker.svg`,
            active: `${url}off-prem-marker-active.svg`,
          },
        },
        finished: {
          inverse: false,
          message:
            'You can find out more about future promotions by following us on Facebook or Instagram.',
        },
        background: {
          value: {
            xs: `url(${url}streamers.png) top / contain no-repeat`,
          },
          exclude: [],
        },
        typography: {
          h1: { color: 'textSecondary' },
          h2: { color: 'textSecondary' },
          h3: { color: 'textSecondary' },
          h4: { color: 'textSecondary' },
          h5: { color: 'textSecondary' },
          h6: { color: 'textSecondary' },
        },
      },
    },
    light: {
      mui: {
        palette: {},
      },
      brand: {},
    },
    dark: {
      mui: {
        palette: paletteDark,
      },
      brand: {},
    },
  },
};
