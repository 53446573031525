import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/styles';
import StartTimerButton from '../StartTimerButton';
import Typography from '@material-ui/core/Typography';
import useInterval from '../../hooks/new-counter';
import { useSelector } from 'react-redux';
import { countdownFinished } from '../../store/premise/actions';
import {
  getCountdownTime,
  getCountdownFinished,
} from './../../store/premise/selectors';
import { getResourceSelector } from '../../store/resource/selectors';
import { resourceUpdateRequest } from '../../store/resource/actions';
import addSeconds from 'date-fns/addSeconds';
import format from 'date-fns/format';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const CountDownText = styled(Typography)`
  ${({ theme, brand }) => `
    font-family: ${brand.timer.countDown.fontFamily || 'inherit'};
    text-align: center;
    margin: 0 auto;
    &.enter {
      opacity: 0;
      transform: translateY(-100%);
    }
    &.enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: transform ${theme.transitions.duration.standard}ms ${
    theme.transitions.easing.easeInOut
  }, opacity ${theme.transitions.duration.shortest}ms ${
    theme.transitions.easing.easeInOut
  };
    }
    &.exit {
      opacity: 1;
      transform: translateY(0);
    }
    &.exit-active {
      opacity: 0;
      transform: translateY(100%);
      transition: transform ${theme.transitions.duration.standard}ms ${
    theme.transitions.easing.easeInOut
  }, opacity ${theme.transitions.duration.shortest}ms ${
    theme.transitions.easing.easeInOut
  };
    }
  `}
`;

const ResponsiveBox = styled(Box)`
  // height: 70px;

  ${({ theme }) => `
    ${theme.breakpoints.up('sm')}{
      // height: 85px;
    }
    ${theme.breakpoints.up('md')}{
      // height: 100px;
    }
  `}
`;

const TimerValidator = ({ children, desktop, timerState, ...props }) => {
  const { brand, ...theme } = useTheme();
  const dispatch = useDispatch();
  const redemption = useSelector(getResourceSelector)('redemption');
  const startTime = useSelector(getCountdownTime);
  const [ticktock, setTickTock] = useState(0);
  const expired = useSelector(getCountdownFinished);
  const selectedVenueId = useSelector(state => state.location.venue.selected);
  const { code } = useSelector(getResourceSelector)('customer');
  const customer = useSelector(getResourceSelector)('customer');
  const { data: codeData } = useSelector(getResourceSelector)('code');
  const emailOptIn =
    codeData && 'opt_in_email' in codeData
      ? codeData.opt_in_email
      : customer.opt_in_email;
  const smsOptIn =
    codeData && 'opt_in_sms' in codeData
      ? codeData.opt_in_sms
      : customer.opt_in_sms;

  let timeLeft = 900;
  if (startTime !== 0) {
    timeLeft -= (Date.now() - startTime) / 1000;
  }
  useInterval(() => {
    if (startTime !== 0) {
      if (timeLeft > 0) {
        setTickTock(ticktock - 1);
      } else if (!expired) {
        dispatch(countdownFinished(true));
        dispatch(
          resourceUpdateRequest(
            'redemption',
            `/code/${code}/venue/${selectedVenueId}/redeem`,
            {
              opt_in_email: emailOptIn,
              opt_in_sms: smsOptIn,
            }
          )
        );
      }
    }
  }, 1000);

  const formattedTime = format(addSeconds(new Date(0), timeLeft), 'mm:ss');

  const expiredTitle =
    (brand.timer && brand.timer.expired && brand.timer.expired.title) ||
    'Expired';

  if (redemption.success) {
    return <></>;
  } else {
    return (
      <>
        <ResponsiveBox
          textAlign="center"
          position="relative"
          mt={-4}
          width="100%"
          theme={theme}
        >
          <TransitionGroup component={null}>
            <CSSTransition
              key={expired}
              timeout={theme.transitions.duration.standard}
            >
              <CountDownText
                className="countdown"
                display="block"
                align="center"
                component="span"
                style={{
                  fontSize: '88px',
                  fontWeight: brand.timer.countDown.fontWeight || 800,
                  lineHeight: 1,
                }}
                color="textPrimary"
                theme={theme}
                brand={brand}
              >
                {expired ? expiredTitle : formattedTime}
              </CountDownText>
            </CSSTransition>
          </TransitionGroup>
        </ResponsiveBox>

        {!expired && (
          <Typography
            className="countdownDescription"
            display="block"
            width="100%"
            position="relative"
            align="center"
            component="span"
            variant="h4"
            style={brand.timer.subtitle}
          >
            to claim
          </Typography>
        )}

        {!desktop &&
        timerState === 'during' &&
        (timerState !== 'success' || timerState === 'expired') ? (
          <>
            <Typography
              color="textPrimary"
              style={{
                fontFamily: brand.timer.countDown.fontFamily || 'inherit',
                fontSize: '32px',
                lineHeight: '40px',
                fontWeight: brand.timer.countDown.fontWeight || '800',
                marginTop: '20px',
              }}
              className="message body__text--primary"
              align="center"
            >
              Your free Furphy Crisp is waiting.
            </Typography>
            <Typography
              color="textPrimary"
              className="message"
              align="center"
              style={{ fontSize: '18px', marginTop: '12px' }}
            >
              And time’s ticking.
              <br />
              Show this screen to a bar tender to claim your free Furphy Crisp.
            </Typography>
          </>
        ) : null}

        <Box my={4}>
          <StartTimerButton expired={expired} started={startTime !== 0} />
        </Box>
      </>
    );
  }
};

export default TimerValidator;
