import { useState, useEffect, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
// import { GoogleMapProvider, MapBox, Marker } from '@googlemap-react/core';
// import styled, { css } from 'styled-components/macro';
// import Box from '@material-ui/core/Box';
// import { useTheme } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
// import Map from '../Map';
// import VenueSearchAndList from '../VenueSearchAndList';
// import VenueContext from '../../contexts/venue';
import { venueByIdSelector } from '../../store/resource/selectors';
import { getVenuesByDistanceSelector } from './../../store/resource/selectors';
// import find from 'lodash/find';
import { GoogleMapContext } from '@googlemap-react/core';

// const PositionedMap = styled.div`
//   position: relative;
//   flex: 1 0 60vh;
// `;

// const ControlsContainer = styled.div`
//   display: flex;
//   position: relative;
//   overflow: hidden;
//   flex: 0 0 392px;
//   justify-content: center;

//   ${({ theme }) => css`
//     ${theme.breakpoints.up('md')} {
//       justify-content: normal;
//       margin: 0;
//     }
//   `}
// `;

// const Wrapper = styled.div`
//   display: flex;
//   flex-direction: column-reverse;
//   flex: 1;
//   ${({ theme }) => css`
//     ${theme.breakpoints.up('md')} {
//       position: absolute;
//       top: 0;
//       width: 100%;
//       flex-direction: row;
//       height: 100%;
//     }
//   `};
// `;

const VenueFinder = ({ urlRespect = false, match = { venueId: '' } }) => {
  const { venueId } = match;
  const userCoords = useSelector(state => state.location.user.coords);
  const { state: googleState } = useContext(GoogleMapContext);

  const history = useHistory();
  const venues = useSelector(getVenuesByDistanceSelector)({
    location: userCoords,
  });

  const [venueIdChosen, setVenueIdChosen] = useState(venueId);

  const venueData = useSelector(venueByIdSelector)(venueIdChosen);
  // const theme = useThe me();

  const setVenueChosen = useCallback(
    newVenueId => {
      if (googleState) {
        //TODO: (sb) This feels unnecessary.
        // const venueChosen = find(venues, venue => venue.id === newVenueId);
        if (googleState.map) {
          // googleState.map.setCenter({ lat: Number(lat), lng: Number(lng) });
        }
      }
      setVenueIdChosen(newVenueId);
      if (urlRespect) {
        history.push(`/venues/${newVenueId}`);
      }
    },
    [history, googleState, urlRespect]
  );

  useEffect(() => {
    if (!(venueData && venueData.id) && venues.length > 0) {
      setVenueChosen(venues[0].id);
    }
  }, [venues, venueIdChosen, venueData, setVenueChosen]);

  return true;
};

export default VenueFinder;
