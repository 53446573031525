import { createContext } from 'react';

export const pageInitalConfig = {
  header: {
    present: true,
  },
  footer: {
    stamp: true,
  },
  hero: {
    present: false,
    title: '',
    subtitle: '',
    blerb: '',
    logo: false,
  },
  background: {
    lg: ``,
  },
};

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
// const PageContext = createContext([{}, () => {}]);
// const PageContext = createContext({
//   footer: { footerConfig: pageStates.footer, setFooterConfig: () => true },
//   setDefault: () => true,
// });
const PageContext = createContext({
  pageConfig: pageInitalConfig,
  dispatchPageConfig: () => {},
});

export default PageContext;
