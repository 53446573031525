import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useTheme } from '@material-ui/styles';

const Button = styled.button`
  width: 26px;
  height: 22px;
  position: relative;
  cursor: pointer;
  padding: 0;
  border-width: 0;
  color: transparent;
  background: transparent;
  will-change: transform;
  z-index: 30;

  ${props =>
    css`
      transform: ${props['data-menu-open'] ? `translateX(-280px)` : `translateX(0px)`};
  `}

  ${({ theme }) =>
    css`
      transition: transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeOut};
  `}
`;

const Patty = styled.span`
  display: block;
  position: absolute;
  height: 3px;
  background-color: ${({ color }) => color};
  width: 100%;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);

  &:nth-child(1),
  &:nth-child(4) {
    transition: transform 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.35s,
      opacity 0s linear 0.3192s,
      background-color 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

    ${props => props['data-menu-open'] &&
    css`
        transition: transform 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96),
        opacity 0s linear 0.3192s,
        background-color 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
      `}
  }

  &:nth-child(1) {
    top: 0;

    ${props => props['data-menu-open'] &&
    css`
        opacity: 0;
        transform: translateY(9px);
      `}
  }

  &:nth-child(2),
  &:nth-child(3) {
    top: 9px;
    transition: transform 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96),
      background-color 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

    ${props => props['data-menu-open'] &&
    css`
        transition: transform 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.35s,
          opacity 0s linear 0.3192s,
          background-color 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
      `}
  }

  &:nth-child(2) {
    ${props => props['data-menu-open'] &&
    css`
        transform: rotate(45deg);
      `}
  }

  &:nth-child(3) {
    ${props => props['data-menu-open'] &&
    css`
        transform: rotate(-45deg);
      `}
  }

  &:nth-child(4) {
    top: 18px;
    
    ${props => props['data-menu-open'] &&
    css`
        transform: translateY(-9px);
        opacity: 0;
      `}
  }
`;

const Burger = ({ className, ...props }) => {
  const patties = [];
  const { brand, ...theme } = useTheme();
  const menuOpen = props['data-menu-open'];
  const setMenuOpen = props['data-set-menu-open'];

  for (let count = 0; count < 4; count++) {
    patties.push(
      <Patty
        theme={theme}
        color={
          (brand.nav && brand.nav.burgerColor) || theme.palette.primary.main
        }
        className="burger__patty"
        data-menu-open={menuOpen}
        key={count}
      />
    );
  }

  return (
    <Button theme={theme} data-menu-open={menuOpen} onClick={() => setMenuOpen(!menuOpen)} className={className}>
      {patties}
    </Button>
  );
};

// Burger.propTypes = {
//   level: PropTypes.number,
//   children: PropTypes.node,
// };

export default Burger;
