/*const MicrobrewSoftFourWoff = '/brands/ironJack/fonts/MicrobrewSoftFour.woff';
const MicrobrewSoftFourWoff2 = '/brands/ironJack/fonts/MicrobrewSoftFour.woff2';
const MicrobrewSoftFourTtf = '/brands/ironJack/fonts/MicrobrewSoftFour.woff2';

const microbrewSoftFour = {
  fontFamily: 'Microbrew Soft Four',
  src: `
    url('${MicrobrewSoftFourWoff2}') format('woff2'),
    url('${MicrobrewSoftFourWoff}') format('woff'),
    url('${MicrobrewSoftFourTtf}') format('truetype');
  `,
};*/

const url = `${process.env.PUBLIC_URL}/brands/lion/`;

const palette = {
  background: {
    default: '#003C79',
  },
  primary: {
    main: '#fff',
  },
  palette: {
    primary: {
      light: '#5b5b5b',
      main: '#323232',
      dark: '#0a0a0a',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffe854',
      main: '#fab617',
      dark: '#c28700',
      contrastText: '#000',
    },
  },
};

export const lion = {
  name: 'Lion',
  id: 5,
  campaigns: ['1'],
  mode: 'dark',
  userTheme: {
    default: {
      mui: {
        palette,
      },
      brand: {
        lists: {
          point: `${url}number-point.svg`,
        },
        logo: {
          url: `${url}logo-icon.svg`,
          alt: 'Lion',
        },
        home: {
          layout: {
            direction: 'row',
            sections: [
              {
                id: 'first',
                responsive: {
                  sizes: {
                    xs: 12,
                    sm: 8,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['hero'],
              },
              {
                id: 'second',
                responsive: {
                  sizes: {
                    xs: 12,
                    sm: 4,
                  },
                  order: {
                    xs: 2,
                    lg: 2,
                  },
                },
                components: ['secondary', 'vendor', 'cta'],
              },
            ],
          },
          howTo: {
            active: true,
          },
        },
        header: {
          height: '78px',
        },
      },
    },
    light: {
      brand: {
        body: {
          background: `${url}background-dark.svg`,
          color: true,
        },
      },
    },
    dark: {
      brand: {
        body: {
          background: `${url}background-light.svg`,
          color: true,
        },
      },
    },
  },
};
