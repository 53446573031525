import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { getResourceSelector } from './../../store/resource/selectors';
import get from 'lodash/get';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Fab from '@material-ui/core/Fab';
import { useTheme } from '@material-ui/styles';

const FabIcon = styled(Fab)`
  margin: ${({ theme }) => `${theme.spacing(2)}px`};
`;

const IconLink = styled.a`
  display: block;
  margin: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(2)}px`};
`;

const SocialStickers = ({ message = '', ...props }) => {
  const { data: campaignData } = useSelector(getResourceSelector)('campaign');
  const theme = useTheme();

  // TODO: (sb) Move these to selectors
  const instagram = get(campaignData, 'instagram', '');
  const facebook = get(campaignData, 'facebook', '');
  const { timer } = theme.brand;
  const social = [
    {
      link: facebook,
      label: 'Facebook',
      tag: get(campaignData, 'facebook_tag', ''),
      icon: timer?.socialStickers?.facebook ? (
        <img
          src={
            timer.socialStickers.facebook.url || timer.socialStickers.facebook
          }
          width={timer.socialStickers.facebook.width || ''}
          height={timer.socialStickers.facebook.height || ''}
          alt="facebook"
        />
      ) : (
        <FacebookIcon fontSize="large" />
      ),
    },
    {
      link: instagram,
      label: 'Instagram',
      tag: get(campaignData, 'instagram_tag', ''),
      icon: timer?.socialStickers?.instagram ? (
        <img
          src={
            timer.socialStickers.instagram.url || timer.socialStickers.instagram
          }
          width={timer.socialStickers.facebook.width || ''}
          height={timer.socialStickers.instagram.height || ''}
          alt="instagram"
        />
      ) : (
        <InstagramIcon fontSize="large" />
      ),
    },
  ];

  if (!instagram && !facebook) {
    return null;
  }

  return (
    <>
      {message && (
        <Box
          mt={2}
          textAlign="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography
            color="textPrimary"
            variant="body1"
            component="span"
            className="socialStickerMessage"
          >
            {message}
          </Typography>
          <ExpandMoreIcon display="block" className="socialStickerIndicator" />
        </Box>
      )}

      <Box display="flex" justifyContent="center">
        {social.map(
          ({ link, label, icon, tag }) =>
            link && (
              <Box key={link} textAlign="center" m={2} display="inline-block">
                {timer.socialStickers.variant &&
                timer.socialStickers.variant === 'basic' ? (
                  <IconLink href={link} target="_blank" theme={theme}>
                    {icon}
                  </IconLink>
                ) : (
                  <FabIcon
                    theme={theme}
                    className="socialSticker"
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={label}
                    color="primary"
                    m={1}
                    display="inline-block"
                    {...props}
                  >
                    {icon}
                  </FabIcon>
                )}
                <Typography
                  color="textPrimary"
                  display="block"
                  variant="body2"
                  component="span"
                  className="socialStickerSubtitle"
                >
                  {tag}
                </Typography>
              </Box>
            )
        )}
      </Box>
    </>
  );
};

export default SocialStickers;
