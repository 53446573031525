import { createSelector } from 'reselect';

export const initialResourceState = {
  countdown: 0,
};

export const initialState = { ...initialResourceState };

export const getCountdownTime = createSelector(
  state => state.premise.countdown,
  countdown => countdown || 0
);

export const getCountdownFinished = createSelector(
  state => state.premise.finished,
  (finished = false) => finished
);

export const getSelectedVenue = createSelector(
  state => state.premise.venue,
  venue => venue || 0
);

export const savedForLaterSelector = createSelector(
  state => state.premise.saved,
  (saved = false) => saved
);
