import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import get from 'lodash/get';
import { useTheme } from '@material-ui/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const NavWrapper = styled(Box)`
  position: fixed;
  display: flex;
  width: 280px;
  top: 0;
  left: 100%;
  bottom: 0;
  padding-top: 0;
  z-index: 20;

  ${({ theme, ...props }) => css`
    box-shadow: ${theme.shadows[1]};
    transition: transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeOut};
    background-color: ${props['data-color'] ? props['data-color'] : theme.palette.background.default};
    transform: ${props['data-menu-open'] ? `translateX(-280px)` : `translateX(0px)`};

    ${theme.breakpoints.up('lg')} {
      position: relative;
      width: auto;
      height: auto;
      top: 0;
      left: auto;
      margin: 0 auto;
      opacity: 1;
      pointer-events: auto;
      background-color: transparent;
      padding: 0;
      transform: none;
      align-items: center;
      box-shadow: none;
    }
  `}
`;

const ContainerList = styled(List)`
  padding: 0;
  margin: 0;
  width: 100%;

  ${({ theme }) => css`
    ${theme.breakpoints.up('lg')} {
      display: flex;
      flex-direction: row;
    }
  `}
`;

const NavItem = styled(ListItem)`
  display: block;
  opacity: 0;
  white-space: nowrap;
  width: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  ${({ theme, delay = 0, ...props }) => css`
    color: ${props['data-mobile-color']};
    transform: translateX(100%);
    padding-bottom: ${theme.spacing(2)}px;
    padding-top: ${theme.spacing(2)}px;

    ${theme.breakpoints.up('lg')} {
      color: ${props['data-desktop-color']};
      font-weight: bold;
      display: inline-block;
      opacity: 1;
      transform: none;
      border-bottom: none;
    }

    transition: transform 0ms linear
      ${150 + delay + theme.transitions.duration.shortest}ms,
      opacity 0ms linear ${150 + delay + theme.transitions.duration.shortest}ms;

      ${props => props['data-menu-open'] &&
      css`
      transition: transform ${theme.transitions.duration.standard}ms
          ${theme.transitions.easing.easeInOut} ${150 + delay}ms,
        opacity ${theme.transitions.duration.shortest}ms
          ${theme.transitions.easing.easeInOut} ${150 + delay}ms;
      transform: translateX(0);
      opacity: 1;
    `}
  `}
`;

const ArrowItem = styled(ArrowForwardIos)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 0.85rem;
`;

const Nav = ({ className, navItems, ...props }) => {
  const { brand, ...theme } = useTheme();
  const isBreakpointLg = useMediaQuery(theme.breakpoints.up('lg'));
  const wrapperRef = useRef(null);
  const menuOpen = props['data-menu-open'];
  const setMenuOpen = props['data-set-menu-open'];

  useEffect(() => {
    const handleClick = event => {
      const navRef = wrapperRef.current;
      const button = navRef.parentNode.querySelector('button');

      if (navRef.contains(event.target) || button.contains(event.target)) {
        return;
      }

      setMenuOpen(false);
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  return (
    <NavWrapper
      component="nav"
      theme={theme}
      data-menu-open={menuOpen}
      className={className}
      ref={wrapperRef}
    >
      <ContainerList theme={theme}>
        {navItems
          .filter(item => !item.mobileOnly || !isBreakpointLg)
          .map((item, index) => {
            const props = item.link.internal
              ? {
                component: Link,
                to: get(item, 'link.url', item.link),
              }
              : {
                component: 'a',
                href: get(item, 'link.url', item.link),
                target: '_blank',
                rel: 'noopener noreferrer',
              };
            return (
              <NavItem
                dense
                data-desktop-color={theme.palette.primary.contrastText}
                data-mobile-color={theme.palette.secondary.contrastText}
                key={index}
                button={!isBreakpointLg}
                theme={theme}
                data-menu-open={menuOpen}
                delay={50 * index}
                onClick={() => setMenuOpen(false)}
                {...props}
              >
                <Typography theme={theme} className="nav__item--link" component="span" variant="body1" style={{ fontWeight: 900 }}>
                  {item.name}
                </Typography>
                {!isBreakpointLg && (
                  <ListItemSecondaryAction>
                    <ArrowItem theme={theme} />
                  </ListItemSecondaryAction>
                )}
              </NavItem>
            );
          })}
      </ContainerList>
    </NavWrapper>
  );
};

export default Nav;
