const merge = require('lodash/merge');
const find = require('lodash/find');

const currentScript = document.getElementById('wl-holder');
const getAttCurrScript = attr =>
  currentScript ? currentScript.getAttribute(attr) : '';

const remoteApi =
  [
    '127.0.0.1',
    'localhost',
    'beertasting-dev.akcelo-dev.com',
    'beertasting-uat.akcelo-dev.com',
  ].indexOf(window.location.hostname) !== -1
    ? 'https://savannah.akcelo-dev.com'
    : `${window.location.protocol}//${window.location.hostname}`;

const firstPathNumber = Number(
  find(document.location.pathname.match(/\d+/g), Number)
);

const URLCampaignId = firstPathNumber ? firstPathNumber : 0;

const config = {
  all: {
    host: process.env.HOST_ENV,
    env: process.env.NODE_ENV || 'development',
    isDev: process.env.NODE_ENV !== 'production',
    isStaging: process.env.NODE_ENV !== 'staging',
    basename: `${process.env.PUBLIC_URL}`,
    isBrowser: typeof window !== 'undefined',
    rootDir: '',
    apiUrl: {
      path: '',
      local: true,
    },
    staticBrandId:
      process.env.REACT_APP_STATIC_BRAND_ID ||
      getAttCurrScript('data-brand-id'),
    campaignId:
      process.env.REACT_APP_CAMPAIGN_ID ||
      getAttCurrScript('data-campaign-id') ||
      URLCampaignId,
    gmapKey:
      // process.env.REACT_APP_GOOGLE_API_KEY || getAttCurrScript('data-gmap-key'),
      'AIzaSyDFTejyl1ld1OEZ2VtZfrcgOU17QOKdjQo'
  },
  test: {},
  development: {
    rootDir: '',
    apiUrl: {
      path: '',
      local: true,
    },
  },
  production: {
    apiUrl: {
      path:
        process.env.REACT_APP_API_PATH ||
        getAttCurrScript('data-api-path') ||
        remoteApi,
      local: false,
    },
  },
};

module.exports = merge(config.all, config[config.all.env], { raw: config });
