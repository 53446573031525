const url = `${process.env.PUBLIC_URL}/brands/xxxxOriginGold/`;

const MADEDillanWoff2 = `${url}fonts/MADEDillan/MADEDillan.woff2`;
const MADEDillanWoff = `${url}fonts/MADEDillan/MADEDillan.woff`;

const MADEDillanCondensedWoff2 = `${url}fonts/MADEDillan/MADEDillan.woff2`;
const MADEDillanCondensedWoff = `${url}fonts/MADEDillan/MADEDillan.woff`;

//Gotham

const GothamBoldWoff2 = `${url}fonts/Gotham/Gotham-Bold.woff2`;
const GothamBoldWoff = `${url}fonts/Gotham/Gotham-Bold.woff`;

const GothamWoff2 = `${url}fonts/Gotham/Gotham-Book.woff2`;
const GothamWoff = `${url}fonts/Gotham/Gotham-Book.woff`;

const GothamMediumWoff2 = `${url}fonts/Gotham/Gotham-Medium.woff2`;
const GothamMediumWoff = `${url}fonts/Gotham/Gotham-Medium.woff`;

const MADEDillan = {
  fontFamily: 'MADEDillan',
  src: `
    url('${MADEDillanWoff2}') format('woff2'),
    url('${MADEDillanWoff}') format('woff');
  `,
};

const MADEDillanCondensed = {
  fontFamily: 'MADEDillan Condensed',
  src: `
    url('${MADEDillanCondensedWoff2}') format('woff2'),
    url('${MADEDillanCondensedWoff}') format('woff');
  `,
};

const GothamBook = {
  fontFamily: 'Gotham',
  src: `
    url('${GothamWoff2}') format('woff2'),
    url('${GothamWoff}') format('woff');
  `,
};

const GothamBold = {
  fontFamily: GothamBook.fontFamily,
  fontWeight: 'bold',
  src: `
    url('${GothamBoldWoff2}') format('woff2'),
    url('${GothamBoldWoff}') format('woff');
  `,
};

const GothamMedium = {
  fontFamily: 'GothamMedium',
  src: `
    url('${GothamMediumWoff2}') format('woff2'),
    url('${GothamMediumWoff}') format('woff');
  `,
};

const headerFont = {
  fontFamily: [
    MADEDillan.fontFamily,
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  textTransform: 'uppercase',
};

const headerFontCon = {
  fontFamily: [
    MADEDillanCondensed.fontFamily,
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  textTransform: 'uppercase',
};

const bodyFont = {
  fontFamily: [
    GothamBook.fontFamily,
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
};

const palette = {
  background: {
    default: '#fff',
    paper: '#fff',
  },
  primary: {
    main: '#000000',
    contrastText: '#003C79',
  },
  secondary: {
    main: '#003C79',
    contrastText: '#FEFCE1',
  },
  text: {
    primary: '#fff',
    // secondary: '#003C79',
  },
  // divider: '#FEFCE1',
};

const typography = {
  h1: {
    ...headerFont,
    fontSize: '7rem',
  },
  h2: {
    ...headerFontCon,
    fontSize: '3.75rem',
  },
  h3: headerFontCon,
  h4: headerFontCon,
  h5: { ...bodyFont, fontWeight: 'bold', textTransform: 'uppercase' },
  h6: { ...bodyFont, fontWeight: 'bold' },
  body1: bodyFont,
  body2: bodyFont,
  button: { ...bodyFont, fontSize: '1rem' },
  subtitle1: bodyFont,
  subtitle2: bodyFont,
};

export const xxxxOriginGold = {
  name: 'XXXX Gold Origin',
  id: 20,
  campaigns: ['21'],
  mode: 'dark',
  css: 'xxxxOriginGold',
  userTheme: {
    default: {
      mui: {
        mixins: {
          toolbar: {
            minHeight: 70,
          },
        },
        typography,
        palette,
        props: {
          MuiLink: {
            underline: 'always',
          },
          MuiButtonBase: {
            disableRipple: true,
          },
          MuiCheckbox: {
            color: 'primary',
          },
          MuiRadio: {
            color: 'primary',
          },
          MuiButton: {
            color: 'primary',
            variant: 'contained',
            size: 'large',
          },
        },
        overrides: {
          MuiCssBaseline: {
            '@global': {
              // '@font-face': [microbrewSoftFour, oswald],
              '@font-face': [
                MADEDillan,
                MADEDillanCondensed,
                GothamBook,
                GothamBold,
                GothamMedium,
              ],
            },
          },
          MuiButton: {
            root: {
              borderRadius: '2rem',
              lineHeight: '1',
            },
            contained: {
              boxShadow: 'none',
            },
            containedSizeLarge: {
              minWidth: '200px',
              padding: '1rem 2rem',
            },
            label: typography.button,
          },
          MuiInputLabel: {
            root: {
              color: palette.text.primary,
              '&$focused': {
                color: palette.text.primary,
              },
            },
          },
          MuiExpansionPanelSummary: {
            root: {
              padding: '0 1rem',
            },
            content: {
              margin: '1.5rem 0',
              '&$expanded': {
                margin: '1.5rem 0',
              },
            },
          },
        },
      },
      brand: {
        paper: false,
        vendor: {
          name: 'Star Liquor',
          image: {
            url: `${url}vendor.svg`,
            alt: 'Star Liquor',
          },
        },
        home: {
          layout: {
            direction: 'row',
            sections: [
              {
                id: 'first',
                responsive: {
                  sizes: {
                    xs: 12,
                    sm: 6,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['hero', 'landing_title', 'landing_subtitle'],
              },
              {
                id: 'second',
                responsive: {
                  sizes: {
                    xs: 12,
                    sm: 6,
                  },
                  order: {
                    xs: 2,
                    lg: 2,
                  },
                },
                components: ['secondary', 'cta'],
              },
            ],
          },
          howTo: {
            active: false,
            inverse: false,
            icon: {
              color: palette.primary.main,
              marginTop: '5px',
            },
          },
          images: {
            hero: {
              fullSize: true,
            },
            secondary: {
              maxWidth: { mobile: '150px', desktop: '235px' },
            },
            background: {
              type: 'full',
            },
          },
        },
        lists: {
          //point: `${url}number-point.svg`,
        },
        quiz: {
          layout: {
            naked: true,
          },
        },
        logo: {
          url: `${url}xxxx-logo.png`,
          alt: 'XXXX',
        },
        header: {
          height: '78px',
          border: false,
          color: 'inherit',
          logo: {
            url: `${url}xxxx-logo.png`,
            alt: 'XXXX',
            height: '91px',
            width: '255px',
            position: 'left',
          },
        },
        nav: {
          side: 'right',
        },
        hero: {
          hero_image: {
            url: `${url}xxxx-logo.png`,
          },
          divider: {
            image: `${url}afl/divider.svg`,
            size: '100% 100%',
            position: 'center center',
            repeat: 'no-repeat',
            color: false,
          },
        },
        footer: {
          inverse: true,
        },
        heroImage: {
          maxWidth: '230px',
        },
        faq: {
          title: 'FAQs',
        },
        timer: {
          image: {
            url: `${url}afl/home-background.png`,
            alt: 'Furphy',
          },
        },
        map: {
          inverse: true,
          onPrem: {
            inactive: `${url}on-prem-marker.svg`,
            active: `${url}on-prem-marker-active.svg`,
          },
          offPrem: {
            inactive: `${url}off-prem-marker.svg`,
            active: `${url}off-prem-marker-active.svg`,
          },
        },
        typography: {
          h1: { color: 'textSecondary' },
          h2: { color: 'textSecondary' },
          h3: { color: 'textSecondary' },
          h4: { color: 'textSecondary' },
          h5: { color: 'textSecondary' },
          h6: { color: 'textSecondary' },
        },
      },
    },
    light: {
      mui: {
        palette: {
          background: {
            default: '#fff',
            paper: '#fff',
          },
          text: {
            primary: '#000',
            secondary: '#003C79',
          },
        },
      },
      brand: {},
    },
    dark: {
      mui: {},
      brand: {},
    },
  },
};
