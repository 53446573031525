const url = `${process.env.PUBLIC_URL}/brands/guinness/`;

const TradeGothicLTStdBoldWoff2 = `${url}fonts/TradeGothicLTStd-Bold.woff2`;
const TradeGothicLTStdBoldWoff = `${url}fonts/TradeGothicLTStd-Bold.woff`;
const TradeGothicLTStdBoldTtf = `${url}fonts/TradeGothicLTStd-Bold.ttf`;

const TradeGothicLTWoff2 = `${url}fonts/TradeGothicLT.woff2`;
const TradeGothicLTWoff = `${url}fonts/TradeGothicLT.woff`;
const TradeGothicLTTtf = `${url}fonts/TradeGothicLT.ttf`;

const tradeGothicLT = {
  fontFamily: 'TradeGothicLT',
  fontWeight: 'normal',
  src: `
    url('${TradeGothicLTWoff2}') format('woff2'),
    url('${TradeGothicLTWoff}') format('woff'),
    url('${TradeGothicLTTtf}') format('truetype');
  `,
};

const tradeGothicLTStdBold = {
  fontFamily: 'TradeGothicLTStd-Bold',
  fontWeight: 'bold',
  src: `
    url('${TradeGothicLTStdBoldWoff2}') format('woff2'),
    url('${TradeGothicLTStdBoldWoff}') format('woff'),
    url('${TradeGothicLTStdBoldTtf}') format('truetype');
  `,
};

const bodyFont = {
  fontFamily: [
    tradeGothicLT.fontFamily,
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
};

const boldFont = {
  fontFamily: [tradeGothicLTStdBold.fontFamily, 'sans-serif'].join(','),
};

const palette = {
  background: {
    default: '#fcf2e6',
    paper: 'transparent',
  },
  primary: {
    main: '#3e9a4a',
    contrastText: '#fff',
  },
  secondary: {
    main: '#3e9a4a',
    contrastText: '#fff',
  },
  text: {
    primary: '#000000',
    secondary: '#000000',
  },
  action: {
    active: '#000000',
  },
};

const paletteDark = {
  text: {
    primary: '#000000',
    secondary: '#3e9a4a',
  },
  action: {
    active: '#fff',
  },
};

const typography = {
  h1: {
    ...boldFont,
    fontSize: '7rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  h2: {
    ...boldFont,
    fontSize: '2.75rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  h3: { ...boldFont, textTransform: 'uppercase', fontWeight: 'bold' },
  h4: { ...boldFont, fontWeight: 'bold' },
  h5: { ...boldFont, fontWeight: 'bold' },
  h6: { ...boldFont, fontWeight: 'bold' },
  caption: { ...bodyFont, fontSize: '13px', fontWeight: 'lighter' },
  body1: bodyFont,
  body2: bodyFont,
  subtitle1: { ...boldFont, fontWeight: 'bold' },
  subtitle2: { ...boldFont, textTransform: 'uppercase', fontWeight: 'bold' },
  button: { ...boldFont, textTransform: 'uppercase', fontWeight: 'bold' },
};

export const guinness = {
  name: 'Guinness',
  id: 10,
  css: 'guinness',
  mode: 'light',
  campaigns: ['12'],
  userTheme: {
    default: {
      mui: {
        mixins: {
          toolbar: {
            minHeight: 70,
          },
        },
        typography,
        palette,
        props: {
          MuiButton: {
            color: 'secondary',
            variant: 'contained',
            size: 'large',
          },
          MuiLink: {
            underline: 'always',
          },
          MuiCheckbox: {
            color: 'secondary',
          },
          MuiRadio: {
            color: 'secondary',
          },
        },
        overrides: {
          MuiMenu: {
            list: {
              backgroundColor: '#fff',
            },
          },
          MuiCssBaseline: {
            '@global': {
              '@font-face': [tradeGothicLT, tradeGothicLTStdBold],
            },
          },
          MuiButton: {
            root: {
              borderRadius: '1em',
              lineHeight: '1',
            },
            contained: {
              boxShadow: 'none',
            },
            containedSizeLarge: {
              fontSize: '1.25rem',
              padding: '0.75rem 2rem',
            },
            label: typography.button,
          },
          MuiInputLabel: {
            root: {
              color: palette.text.primary,
              fontSize: '18px',
              '&$focused': {
                color: palette.text.primary,
              },
            },
          },
          MuiInputBase: {
            root: {
              fontFamily: [tradeGothicLT.fontFamily],
              fontSize: '18px',
            },
          },
          MuiCheckbox: {
            root: {
              color: '#7DCA98',
            },
          },
          MuiRadio: {
            root: {
              color: 'rgba(0, 164, 76, 0.5)',
            },
          },
          MuiExpansionPanelSummary: {
            root: {
              padding: '0',
            },
          },
        },
      },
      brand: {
        paper: false,
        vendor: {
          name: 'Star Liquor',
          image: {
            url: `${url}vendor.svg`,
            alt: 'Star Liquor',
          },
        },
        home: {
          layout: {
            direction: 'row',
            sections: [
              {
                id: 'first',
                responsive: {
                  sizes: {
                    xs: 12,
                    sm: 12,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: [
                  'hero',
                  'landing_title',
                  'landing_description',
                  'cta',
                ],
              },
            ],
          },
          howTo: {
            active: false,
            icon: {
              color: palette.primary.main,
              marginTop: '5px',
            },
          },
          images: {
            hero: {
              fullSize: true,
            },
            secondary: {
              maxWidth: { mobile: '270px', desktop: '335px' },
            },
          },
        },
        lists: {
          point: `${url}number-point.svg`,
          fontColor: palette.secondary.contrastText,
        },
        quiz: {
          layout: {
            naked: true,
          },
        },
        logo: {
          url: `${url}logo.png`,
          alt: 'Guinness Stout Shout',
        },
        header: {
          height: '64px',
          border: false,
          shadow: true,
          logo: {
            url: `${url}header-logo.png`,
            alt: 'Guinness',
            height: '64px',
            position: 'left',
          },
        },
        draw: {
          side: 'right',
          inverse: true,
        },
        hero: {
          background: { color: 'transparent' },
          inverse: true,
        },
        footer: {
          drinkwise: false,
        },
        heroImage: {
          maxWidth: '230px',
        },
        nav: {
          burgerColor: '#f1c761',
          desktopColor: '#fff',
        },
        faq: {
          title: 'FAQs',
        },
        signup: {
          indicator: true,
          postcodeCaption:
            '*So we have enough Guinness at the right pubs for everyone.',
        },
        saveForLater: {
          button: 'Next',
        },
        wheretoclaim: {
          indicator: true,
        },
        timer: {
          image: {
            url: `${url}timer.png`,
            alt: 'Guinness beer',
          },
          messagePosition: 'below',
          success: {
            button: {
              url: 'https://guinnesspubfinder.com.au/',
              text: 'Guinness Pub Finder',
            },
            socialText: 'Find us on',
          },
          expired: {
            title: "Time's Up!",
          },
          already: {
            title: 'WHOOPS!',
            messagePrefix:
              "<strong>Looks like you've already claimed your free pint!</strong>",
          },
        },
        map: {
          inverse: false,
          onPrem: {
            color: 'primary',
            inactive: `${url}on-prem-marker.svg`,
            active: `${url}on-prem-marker-active.svg`,
          },
          offPrem: {
            color: 'primary',
            inactive: `${url}off-prem-marker.svg`,
            active: `${url}off-prem-marker-active.svg`,
          },
        },
        finished: {
          inverse: false,
          message:
            'You can find out more about future promotions by following us on Facebook or Instagram.',
        },
        background: {
          value: {
            xs: `url(${url}streamers.png) top / contain no-repeat`,
          },
          exclude: [],
        },
        typography: {
          h1: { color: 'textSecondary' },
          h2: { color: 'textSecondary' },
          h3: { color: 'textSecondary' },
          h4: { color: 'textSecondary' },
          h5: { color: 'textSecondary' },
          h6: { color: 'textSecondary' },
        },
      },
    },
    light: {
      mui: {
        palette: {},
      },
      brand: {},
    },
    dark: {
      mui: {
        palette: paletteDark,
      },
      brand: {},
    },
  },
};
