import React, { useCallback, createRef, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import Downshift from 'downshift';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useTheme } from '@material-ui/styles';
import { resourceListReadRequest } from '../../store/resource/actions';
import { getResourceSelector } from './../../store/resource/selectors';
import VenueDropdownItem from '../VenueDropdownItem';
import get from 'lodash/get';
import { userVenueSelected } from '../../store/location/actions';
import PageContext from '../../contexts/page';

const DropDownContainer = styled(Paper)`
  position: absolute;
  z-index: 1;
  margin-top: ${({ theme }) => theme.spacing(1)};
  left: 0;
  right: 0;
  color: ${({ theme }) => theme.palette.primary.main};
  z-index: 20;
`;

export default function VenuesSearchClaim({ focus, setFocus, setVenue }) {
  const { brand, ...theme } = useTheme();
  const dispatch = useDispatch();
  const venuesStatus = useSelector(getResourceSelector)([
    'campaign',
    'venuesSearch',
  ]);
  const downshift = createRef();
  const { pageConfig } = useContext(PageContext);

  // Enable the location permission to see venues near you
  useEffect(() => {
    if (!focus) {
      downshift.current.clearSelection();
    }
  }, [downshift, focus]);

  const fetchResults = useCallback(
    input => {
      if (input) {
        dispatch(
          resourceListReadRequest(
            'campaign',
            ['venues'],
            {
              text: input,
            },
            'venuesSearch'
          )
        );
      }
    },
    [dispatch]
  );

  return (
    <Downshift
      ref={downshift}
      onInputValueChange={input => {
        fetchResults(input);
      }}
      onChange={chosen => {
        const chosenVen = chosen === null ? '' : chosen;
        const chosenId = chosenVen && chosenVen.id;
        setVenue(chosenVen);

        if (chosenId) {
          dispatch(userVenueSelected({ id: chosenId }));
        }
      }}
      onStateChange={(changes, { isOpen, selectedItem }) => {
        if (!isOpen && selectedItem === null) {
          setFocus({ search: false });
        }
      }}
      id="downshift-simple"
      itemToString={item => get(item, 'name', '')}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        selectedItem,
        getRootProps,
        clearSelection,
      }) => {
        const { onBlur, onFocus, ...inputProps } = getInputProps({
          placeholder: 'Search venue',
          onInput: () => setFocus({ near: false, search: true }),
        });

        return (
          <Box mb={1} position="relative" flexGrow="1" {...getRootProps()}>
            {pageConfig.pageSlug === 'signUp' && (
              <TextField
                InputProps={{
                  onBlur,
                  onFocus,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon color="primary" />
                    </InputAdornment>
                  ),
                  variant: 'subtitle1',
                }}
                variant="outlined"
                fullWidth
                label="Dob in your local pub*"
                placeholder="Name of pub"
                InputLabelProps={getLabelProps({ shrink: true })}
                inputProps={inputProps}
              />
            )}
            {pageConfig.pageSlug !== 'signUp' && (
              <TextField
                InputProps={{
                  onBlur,
                  onFocus,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  variant: 'subtitle1',
                }}
                fullWidth
                InputLabelProps={getLabelProps({ shrink: true })}
                inputProps={inputProps}
              />
            )}

            <div {...getMenuProps()}>
              {isOpen ? (
                <DropDownContainer
                  theme={theme}
                  // elevation={0}
                  square
                  className="venueSearchDropdown"
                >
                  {venuesStatus.data.map((suggestion, index) => {
                    const isHighlighted = highlightedIndex === index;
                    const isSelected = selectedItem === suggestion.id;

                    return (
                      <VenueDropdownItem
                        {...getItemProps({ item: suggestion })}
                        venue={suggestion}
                        key={suggestion.id}
                        selected={isHighlighted}
                        style={{
                          fontWeight: isSelected ? 500 : 400,
                        }}
                      />
                    );
                  })}
                </DropDownContainer>
              ) : null}
            </div>
          </Box>
        );
      }}
    </Downshift>
  );
}
