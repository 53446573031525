import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
// import WalletPage from '../../components/pages/WalletPage';
import SignUpPage from '../../components/pages/SignUpPage';
import VenuesPage from '../../components/pages/VenuesPage';
import FaqPage from '../../components/pages/FaqPage';
import ParticipatingVenuesPage from '../../components/pages/ParticipatingVenuesPage';
import TermsPage from '../../components/pages/TermsPage';
import CongratsPage from '../../components/pages/CongratsPage';
import RobPage from '../../components/pages/RobPage';
import PrivacyPage from '../../components/pages/PrivacyPage';
import MenuPage from '../../components/pages/MenuPage';
import HowToEnterPage from '../../components/pages/HowToEnterPage';
// import RedemptionPage from '../../components/pages/RedemptionPage';
import WhereToClaimPage from '../../components/pages/WhereToClaimPage';
import OrderPage from '../../components/pages/OrderPage';
import FinishedCampaignPage from '../../components/pages/FinishedCampaignPage';
import FourOFourPage from '../../components/pages/FourOFourPage';
import SaveForLaterPage from '../../components/pages/SaveForLaterPage';
import useIsCampaignExpired from '../../hooks/useIsCampaignExpired';
import ApplicationRoute from './ApplicationRoute';
import HomePage from '../../components/pages/HomePage';
import HomeRoute from './HomeRoute';

function Routes({ persistor }) {
  const isCampaignExpired = useIsCampaignExpired();

  return (
    <>
      {/* {isDev && (
        <Route
          path="*"
          component={routeProps => (
            <DevBar {...routeProps} persistor={persistor} />
          )}
        />
      )} */}
      <Switch>
        {/* {isDev && (
          <Route
            exact
            path="/dev"
            component={routeProps => (
              <DevPage {...routeProps} persistor={persistor} />
            )}
          />
        )} */}
        <Route
          exact
          path="/campaign-finished"
          component={FinishedCampaignPage}
        />
        <Route exact path="/privacy" component={PrivacyPage} />
        {isCampaignExpired ? (
          <Redirect from="/" to="/campaign-finished" />
        ) : (
          <Redirect exact from="/campaign-finished" to="/" />
        )}
        <Route exact path="/how-to-enter" component={HowToEnterPage} />
        <ApplicationRoute
          exact
          path="/application/order"
          render={routeProps => <OrderPage {...routeProps} />}
        />
        <ApplicationRoute
          exact
          path="/where-to-claim"
          render={routeProps => <WhereToClaimPage {...routeProps} />}
        />
        <ApplicationRoute
          exact
          path="/where-to-claim/:code"
          render={routeProps => <WhereToClaimPage {...routeProps} />}
        />
        <ApplicationRoute
          exact
          path="/save-for-later"
          render={routeProps => <SaveForLaterPage {...routeProps} />}
        />
        <ApplicationRoute
          exact
          path="/save-for-later/:code"
          render={routeProps => <SaveForLaterPage {...routeProps} />}
        />
        <ApplicationRoute
          exact
          path="/congratulations"
          render={routeProps => <CongratsPage {...routeProps} />}
        />
        <ApplicationRoute
          exact
          path="/rob"
          render={routeProps => <RobPage {...routeProps} />}
        />
        <ApplicationRoute
          exact
          path="/application"
          render={routeProps => <SignUpPage {...routeProps} />}
        />
        <Route
          exact
          path="/venues/:venueId"
          render={routeProps => <VenuesPage {...routeProps} />}
        />
        <Route
          exact
          path="/venues"
          render={routeProps => <VenuesPage {...routeProps} />}
        />
        <Route exact path="/terms" component={TermsPage} />
        <Route exact path="/faq" component={FaqPage} />
        <Route
          exact
          path="/participating-venues"
          component={ParticipatingVenuesPage}
        />

        <HomeRoute exact path="/" component={HomePage} />
        {/* this is for prev onprem savannah lite */}
        <Route path="/*" component={MenuPage} />
        <Route render={() => <FourOFourPage homepageLink />} />
      </Switch>
    </>
  );
}

export default Routes;
