import React, { useEffect, useContext, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
// import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/styles';
import { resourceListReadRequest } from '../../../store/resource/actions';
import Head from '../../Head';
import PageContext from '../../../contexts/page';
import ParticipatingVenues from '../../ParticipatingVenues';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import Map from '../../Map';
// import VenueFinder from '../../VenueFinder';
// import { GoogleMapProvider } from '@googlemap-react/core';
// import VenuesList from '../../VenuesList';
import VenueContext, { venueInitialConfig } from '../../../contexts/venue';
import deepmerge from 'deepmerge';

function reducer(state, action) {
  switch (action.type) {
    case 'reset':
      return venueInitialConfig;
    case 'reset-blacklist':
      return deepmerge(venueInitialConfig, action);
    case 'change':
      return deepmerge(state, action);
    default:
      throw new Error();
  }
}

const ParticipatingVenuesPage = () => {
  const { brand, ...theme } = useTheme();
  const { dispatchPageConfig } = useContext(PageContext);
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));

  const allVenues = useSelector(state => state.resource.campaign.all_venues);

  const dispatch = useDispatch();

  const [venueConfig, dispatchVenueConfig] = useReducer(
    reducer,
    venueInitialConfig
  );

  useEffect(() => {
    if (!allVenues.success && !allVenues.fetching && !allVenues.fetched) {
      // This will need to contain the id
      dispatch(resourceListReadRequest('campaign', 'all_venues'));
    }
  }, [allVenues.success, allVenues.fetching, allVenues.fetched, dispatch]);

  useEffect(() => {
    if (allVenues.fetched) {
      let venueArray = [];
      // TO DO: Update Savannah response format
      Object.entries(allVenues.data.data).forEach(stateVenues => {
        stateVenues[1].forEach(currentVenue => {
          venueArray.push({
            id: currentVenue.id,
            google_place_id: currentVenue.google_place_id,
            latitude: Number(currentVenue.lat),
            longitude: Number(currentVenue.lng),
            name: currentVenue.name,
            postcode: currentVenue.postcode,
            state: currentVenue.state,
            street_address: currentVenue.street_address,
            suburb: currentVenue.suburb,
            type: "on-prem",
          });
        });
      });

      dispatchVenueConfig({
        type: 'change',
        venueData: venueArray,
        success: true
      });
    }
  }, [allVenues.data, allVenues.fetched, dispatchVenueConfig]);

  useEffect(() => {
    dispatchPageConfig({
      type: 'reset-blacklist',
      hero: {
        title:
          (brand.participatingvenues && brand.participatingvenues.title) ||
          'Participating Venues',
        present: !desktop,
      },
      pageSlug: 'participating-venues',
    });
  }, [dispatchPageConfig, brand.participatingvenues, desktop]);

  return (
    <>
      <Head pageTitle="Participating Venues" />
      <Box flex="1 1 auto" bgcolor="background.paper">
        {/* <VenueFinder {...props} urlRespect /> */}
        <VenueContext.Provider value={{ venueConfig, dispatchVenueConfig }}>
          <ParticipatingVenues />
        </VenueContext.Provider>
      </Box>
    </>
  );
};

export default ParticipatingVenuesPage;
