import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/styles';
import { resourceListReadRequest } from '../../../store/resource/actions';
import Head from '../../Head';

import PageContext from '../../../contexts/page';
import Faqs from '../../Faqs';

const FaqPage = () => {
  const { brand } = useTheme();
  const { dispatchPageConfig } = useContext(PageContext);
  const faqs = useSelector(state => state.resource.campaign.faqs);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!faqs.success && !faqs.fetching) {
      // This will need to contain the id
      dispatch(resourceListReadRequest('campaign', 'faqs'));
    }
  }, [faqs.success, faqs.fetching, dispatch]);

  useEffect(() => {
    dispatchPageConfig({
      type: 'reset-blacklist',
      hero: {
        title: (brand.faq && brand.faq.title) || 'Frequently Asked Questions',
        present: true,
      },
      pageSlug: 'faq',
    });
  }, [dispatchPageConfig, brand.faq]);

  return (
    <>
      <Head pageTitle="FAQs" />
      <Box flex="1 1 auto">
        <Faqs />
      </Box>
    </>
  );
};

export default FaqPage;
