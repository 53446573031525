import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/styles';
import { getVenuesByDistanceSelector } from './../../store/resource/selectors';
import UserLocation from '../UserLocation';
import { resourceListReadRequest } from '../../store/resource/actions';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import VenueDropdownItem from '../VenueDropdownItem';
import { getResourceSelector } from './../../store/resource/selectors';
import findIndex from 'lodash/findIndex';

const UserLocationPositioned = styled(UserLocation)`
  margin-left: 0;
  margin-left: ${({ theme }) => `-${theme.spacing(1)}px`};
  margin-right: ${({ theme }) => `${theme.spacing(1)}px`};
  margin-bottom: 0;
`;

const HelperTextFade = styled(FormHelperText)`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0 : 1)};
  transition: ${({ theme }) =>
    `opacity ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeOut}`};
`;

export default function VenuesNearMe({
  className,
  focus,
  setFocus,
  setVenue,
  venue,
}) {
  const { brand, ...theme } = useTheme();
  const userLocationFetching = useSelector(
    state => state.location.user.fetching
  );
  const userLocationSuccess = useSelector(state => state.location.highAccuracy);
  const venuesStatus = useSelector(getResourceSelector)([
    'campaign',
    'venuesNearMe',
  ]);

  const [helperText, setHelperText] = useState('');

  const { latitude, longitude } = useSelector(
    state => state.location.user.coords
  );
  const venues = useSelector(getVenuesByDistanceSelector)({
    location: { latitude, longitude },
  });

  const handleChange = event => {
    setVenue(venues.find(venue => venue.id === event.target.value));
  };

  const dispatch = useDispatch();

  const fetchResults = useCallback(() => {
    if (userLocationSuccess) {
      setHelperText('');
      dispatch(
        resourceListReadRequest(
          'campaign',
          'venues',
          {
            coords: `${latitude},${longitude}`,
          },
          'venuesNearMe'
        )
      );
    }
  }, [dispatch, latitude, longitude, userLocationSuccess]);

  // Enable the location permission to see venues near you
  useEffect(() => {
    if (userLocationSuccess && focus) {
      fetchResults();
    }
  }, [userLocationSuccess, fetchResults, focus]);

  useEffect(() => {
    // TODO: Turn this into some sort of reducer
    if (userLocationFetching) {
      setHelperText('Trying to fetch your location...');
    } else if (userLocationSuccess) {
      setHelperText('');
    } else {
      setHelperText('Enable the location permission to see venues near you');
    }
    if (focus) {
      if (venuesStatus.fetching) {
        setHelperText('Loading...');
      }
      if (venuesStatus.success) {
        if (venuesStatus.data.length === 0) {
          setHelperText('No venues found');
        } else {
          setHelperText('');
        }
      }
      if (venuesStatus.error) {
        setHelperText('Error');
      }
    } else if (
      (venuesStatus.success || venuesStatus.error) &&
      venues.length === 0
    ) {
      setHelperText('No venues found');
    }
  }, [
    focus,
    userLocationFetching,
    userLocationSuccess,
    venuesStatus.data.length,
    venuesStatus.error,
    venuesStatus.fetching,
    venuesStatus.success,
    venues.length,
  ]);

  return (
    <Box alignItems="center" display="flex" className="venuesNearMe">
      <UserLocationPositioned theme={theme} />
      <FormControl
        disabled={
          focus &&
          (!userLocationSuccess ||
            (venuesStatus.success && venues.length === 0))
        }
        className={className}
        fullWidth
      >
        <InputLabel htmlFor="venues-near-me" className="body__text--secondary">
          Venues near me
        </InputLabel>
        <Select
          display="flex"
          fullWidth
          // onOpen={fetchResults}
          disabled={
            (!userLocationSuccess ||
              venuesStatus.success ||
              venuesStatus.error) &&
            venues.length === 0
          }
          onChange={e => {
            handleChange(e);
          }}
          inputProps={{
            name: 'venues-near-me',
            id: 'venues-near-me',
            onFocus: () => setFocus({ near: true, search: false }),
          }}
          renderValue={id => {
            const index = findIndex(venues, venue => venue.id === id);

            if (index === -1) {
              return '';
            }
            return venues[index].name;
          }}
        >
          {venues.slice(0, 5).map((suggestion, index) => (
            <VenueDropdownItem
              value={suggestion.id}
              key={index}
              venue={suggestion}
            />
          ))}
        </Select>
        {helperText && (
          <HelperTextFade theme={theme}>{helperText}</HelperTextFade>
        )}
      </FormControl>
    </Box>
  );
}
