import { createSelector } from 'reselect';
import config from '../../config';

export const apiConfigSelector = createSelector(
  state => state.config.api.current,
  state => state.config.api.configs,
  (current, configs) => configs.filter(config => config.name === current)[0]
);

export const initialState = {
  api: {
    current: 'external',
    configs: [
      {
        name: 'external',
        ...config.raw.production.apiUrl,
      },
      {
        name: 'local',
        ...config.raw.development.apiUrl,
      },
    ],
  },
};
