import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
// eslint-disable-next-line
import { GoogleMapProvider } from '@googlemap-react/core';
import Map from '../Map';
import { reduxForm } from 'redux-form';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import RedeemButton from '../RedeemButton';
import VenuesSearchClaim from '../VenuesSearchClaim';
import VenuesNearMe from '../VenuesNearMe';
import WhereToClaimConsent from '../WhereToClaimConsent';
import Box from '@material-ui/core/Box';
import validate from '../../services/validation';
import { useTheme } from '@material-ui/styles';
import { getResourceSelector } from '../../store/resource/selectors';
import VenueContext from '../../contexts/venue';
import { userVenueSelected } from '../../store/location/actions';

const VenuesNearMeSpaced = styled(VenuesNearMe)`
  margin-bottom: ${({ theme }) => `${theme.spacing(4)}px`};
`;

const SelectionViewToggle = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  margin: 0 0 40px;
  padding: 0;
  list-style: none;
  flex-direction: row;
  align-items: center;
  text-align: center;
  background-color: #e9eaea;
  border-radius: 8px;

  > .MuiButton-root {
    flex: 1;
    height: 100%;
    color: #212529;
    border-radius: 8px;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
    }

    &.active {
      color: #fff !important;
      background-color: #212529 !important;
    }
  }
`;

let WhereToClaim = ({ ...props }) => {
  const { brand, ...theme } = useTheme();
  const [focus, setFocus] = useState({ near: false, search: false });
  const [venue, setVenue] = useState(false);
  const [selectionMode, setSelectionMode] = useState('list');
  const { error, message } = useSelector(getResourceSelector)('code');
  const { venueConfig } = useContext(VenueContext);
  const selectedVenue = useSelector(state => state.location.venue.selected);
  const allVenues = venueConfig.venueData;
  const selectedDetails = allVenues.find(venue => venue.id === selectedVenue);
  const dispatch = useDispatch();

  const updateSelectionMode = mode => {
    setSelectionMode(mode);
    setVenue(false);
    dispatch(userVenueSelected(false));
  };

  const updateSelectedVenue = venue => {
    setVenue(venue);
    dispatch(userVenueSelected(venue));
  };

  useEffect(() => {
    if (selectedVenue) {
      setVenue(selectedVenue);
    }
  }, [selectedVenue, setVenue]);

  return (
    <>
      <SelectionViewToggle className={`currentMode-${selectionMode}`}>
        <Button
          className={selectionMode === 'list' ? 'active' : ''}
          onClick={() => updateSelectionMode('list')}
        >
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.25 6H21.25M7.25 12H21.25M7.25 18H21.25"
              stroke="currentColor"
              strokeWidth="2"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.25 6C2.25 5.17157 2.92157 4.5 3.75 4.5C4.57843 4.5 5.25 5.17157 5.25 6C5.25 6.82843 4.57843 7.5 3.75 7.5C2.92157 7.5 2.25 6.82843 2.25 6ZM2.25 12C2.25 11.1716 2.92157 10.5 3.75 10.5C4.57843 10.5 5.25 11.1716 5.25 12C5.25 12.8284 4.57843 13.5 3.75 13.5C2.92157 13.5 2.25 12.8284 2.25 12ZM2.25 18C2.25 17.1716 2.92157 16.5 3.75 16.5C4.57843 16.5 5.25 17.1716 5.25 18C5.25 18.8284 4.57843 19.5 3.75 19.5C2.92157 19.5 2.25 18.8284 2.25 18Z"
              fill="currentColor"
            />
          </svg>
        </Button>
        <Button
          className={selectionMode === 'map' ? 'active' : ''}
          onClick={() => updateSelectionMode('map')}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 4L3.06838 5.97721C3.02754 5.99082 3 6.02903 3 6.07208V20.8613C3 20.9295 3.06687 20.9777 3.13162 20.9561L9 19V4ZM9 4L15 6M15 6L20.8684 4.04387C20.9331 4.02229 21 4.07049 21 4.13874V11M15 6V9"
              stroke="currentColor"
              strokeWidth="2"
            />
            <path
              d="M18.8853 18.8853L23 23M20.0667 16.0333C20.0667 18.2609 18.2609 20.0667 16.0333 20.0667C13.8058 20.0667 12 18.2609 12 16.0333C12 13.8058 13.8058 12 16.0333 12C18.2609 12 20.0667 13.8058 20.0667 16.0333Z"
              stroke="currentColor"
              strokeWidth="2"
            />
          </svg>
        </Button>
      </SelectionViewToggle>
      {selectionMode === 'list' && !error && (
        <>
          <VenuesNearMeSpaced
            theme={theme}
            focus={focus.near}
            setFocus={setFocus}
            setVenue={updateSelectedVenue}
            venue={venue}
          />
          <Typography
            display="block"
            align="center"
            variant="button"
            component="span"
            gutterBottom
          >
            or
          </Typography>
          <VenuesSearchClaim
            setVenue={updateSelectedVenue}
            venue={venue}
            focus={focus.search}
            setFocus={setFocus}
          />
        </>
      )}
      {selectionMode === 'map' && !error && (
        <>
          <Box position="relative" height="356px">
            <GoogleMapProvider>
              <Map />
            </GoogleMapProvider>
          </Box>
          {selectedVenue && !error && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '2px',
                width: '100%',
                margin: '24px 0',
              }}
            >
              <Box>
                <Typography>Selected Venue:&nbsp;</Typography>
              </Box>
              <Box>
                <Typography>
                  {!selectedVenue && <strong>None selected</strong>}
                  {selectedVenue && (
                    <>
                      <strong style={{ fontWeight: 800 }}>
                        {selectedDetails.name}
                      </strong>
                      <br />
                      {selectedDetails.street_address}
                      <br />
                      {selectedDetails.suburb}, {selectedDetails.state},{' '}
                      {selectedDetails.postcode}
                    </>
                  )}
                </Typography>
              </Box>
            </Box>
          )}
          {!selectedVenue && !error && (
            <Box>
              <Typography align="center" style={{ margin: '24px 0' }}>
                <strong>
                  To continue, please select one of the Participating Venues on
                  the map above.
                </strong>
              </Typography>
            </Box>
          )}
        </>
      )}
      {selectedVenue && !error && (
        <Typography className="description" component="p" variant="body1">
          {(brand.wheretoclaim && brand.wheretoclaim.message) ||
            "The offer is valid for 15 minutes. When you're at the bar, press the button below, show timer to the bar staff and they will validate your free beer."}
        </Typography>
      )}
      {error ? (
        <Box mt={2}>
          <Typography color="error" align="center">
            There was a problem with the code: {message}
          </Typography>
        </Box>
      ) : null}
      {selectedVenue && !error && (
        <Box
          mx={2}
          my={4}
          display="flex"
          justifyContent="center"
          className="whereToClaimConsent"
        >
          <form className="signUpForm">
            <WhereToClaimConsent />
          </form>
        </Box>
      )}
      <Box
        mx={2}
        my={4}
        display="flex"
        justifyContent="center"
        className="redeemButton"
      >
        <RedeemButton
          venue={selectedVenue}
          disabled={
            (selectionMode === 'list' && !focus.near && !focus.search) ||
            !selectedVenue ||
            error
          }
        />
      </Box>
      {/* <Box mx={2} my={4} display="flex" justifyContent="center">
        <Typography component="h1" variant="h5">
          #SocialiseResponsibly
        </Typography>
      </Box> */}
    </>
  );
};

WhereToClaim = reduxForm({
  form: 'signup',
  validate,
  destroyOnUnmount: true,
})(WhereToClaim);

export default WhereToClaim;
