import deepmerge from 'deepmerge';
import find from 'lodash/find';
import { createSelector } from 'reselect';
// import { staticBrandId } from '../../config';
import { lion } from './demos/lion';
import { ironJack } from './demos/ironJack';
import { furphy } from './demos/furphy';
import { furphyAFL } from './demos/furphy-afl';
import { xxxxDry } from './demos/xxxx-dry';
import { furphyOnPrem } from './demos/furphy-on-prem';
import { tooheys } from './demos/tooheys';
import { byronBay } from './demos/byronBay';
import { xxxx } from './demos/xxxx';
import { xxxxOriginGold } from './demos/xxxx-origin-gold';
import { xxxxOriginDry } from './demos/xxxx-origin-dry';
import { heinekenZero } from './demos/heineken-zero';
import { heineken } from './demos/heineken';
import { guinness } from './demos/guinness';
import { brokenShackles } from './demos/brokenShackles';
import { savannahLite } from './demos/savannah-lite';
import { furphyQuiz } from './demos/furphy-quiz';
import { campaignId, isDev } from '../../config';
import 'whatwg-fetch';

export const modes = {
  current: 'brand',
  themes: [
    { name: 'Brand', id: 'brand' },
    { name: 'System', id: 'auto' },
    { name: 'Dark', id: 'dark' },
    { name: 'Light', id: 'light' },
  ],
};

const backgroundConfig = {
  size: '',
  position: '',
  repeat: '',
  image: '',
  color: false,
};

const brandTheme = {
  mode: 'auto',
  paper: false,
  vendor: {
    name: '',
    image: {
      url: '',
      alt: '',
    },
  },
  lists: {
    point: '',
    fontColor: '',
  },
  home: {
    howTo: {
      active: true,
      inverse: false,
    },
    images: {
      hero: {
        maxWidth: { mobile: '', desktop: '' },
      },
      secondary: {
        maxWidth: '',
      },
      background: {
        type: '',
      },
    },
    layout: {
      direction: 'row',
      background: '',
      border: '',
      sections: [
        {
          id: 'landing_title',
          components: [''],
          responsive: {
            sizes: {
              xs: 12,
              lg: 6,
            },
            order: {
              xs: 1,
              lg: 1,
            },
          },
        },
        {
          id: 'hero',
          components: [''],
          responsive: {
            sizes: {
              xs: 12,
              lg: 6,
            },
            order: {
              xs: 1,
              lg: 1,
            },
          },
        },
        {
          id: 'cta',
          components: [''],
          responsive: {
            sizes: {
              xs: 12,
              lg: 6,
            },
            order: {
              xs: 1,
              lg: 1,
            },
          },
        },
        {
          id: 'description',
          components: [''],
          responsive: {
            sizes: {
              xs: 12,
              lg: 6,
            },
            order: {
              xs: 1,
              lg: 1,
            },
          },
        },
        {
          id: 'secondary',
          components: [''],
          responsive: {
            sizes: {
              xs: 12,
              lg: 6,
            },
            order: {
              xs: 1,
              lg: 1,
            },
          },
        },
        {
          id: 'vendor',
          components: [''],
          responsive: {
            sizes: {
              xs: 12,
              lg: 6,
            },
            order: {
              xs: 1,
              lg: 1,
            },
          },
        },
      ],
    },
  },
  cover: {
    background: backgroundConfig,
  },
  quiz: {
    layout: {
      naked: true,
    },
  },
  logo: {
    url: '',
    alt: '',
  },
  header: {
    height: '',
    border: '',
    shadow: false,
    color: 'default',
    logo: {
      url: '',
      alt: '',
      height: '',
      width: '',
      position: '',
    },
  },
  burger: {
    nav: {
      side: '',
    },
    left: '',
    right: '',
    width: '',
    color: '',
    bar: {
      height: '',
      radius: '',
      offset: ['', ''],
    },
  },
  hero: {
    divider: '',
    background: backgroundConfig,
    inverse: false,
  },
  draw: {
    side: 'right',
    color: '',
    inverse: false,
  },
  heroImage: {
    url: false,
    maxWidth: '',
  },
  body: {
    background: backgroundConfig,
  },
  footer: {
    drinkwise: true,
    background: backgroundConfig,
    inverse: false,
    border: 0,
    logo: {
      url: '',
      alt: '',
    },
  },
  map: {
    inverse: true,
    onPrem: {
      color: 'secondary',
      inactive: '',
      active: '',
    },
    offPrem: {
      color: 'secondary',
      inactive: '',
      active: '',
    },
  },
  finished: {
    titleColor: '',
    background: {
      xs: '',
      lg: '',
    },
  },
  typography: {
    h1: { color: 'textPrimary' },
    h2: { color: 'textPrimary' },
    h3: { color: 'textPrimary' },
    h4: { color: 'textPrimary' },
    h5: { color: 'textPrimary' },
    h6: { color: 'textPrimary' },
  },
};

const brandData = {
  id: '',
  name: '',
  mode: '',
  userTheme: {
    default: {
      brand: brandTheme,
    },
    light: {
      brand: {},
    },
    dark: {
      brand: {},
    },
  },
};

export const brandDataSelector = createSelector(
  state => state.brand.name,
  state => state.brand.brands,
  (id, brands) => {
    const data = find(brands, brand => brand.id === id);
    return data ? data : {};
  }
);

const brands = [
  // { ...lion, id: 'default', name: 'DEFAULT', campaigns: ['0'] },
  { ...savannahLite, id: 'default', name: 'DEFAULT', campaigns: ['0'] },
  lion,
  furphy,
  furphyAFL,
  ironJack,
  byronBay,
  furphyOnPrem,
  xxxx,
  xxxxDry,
  xxxxOriginGold,
  xxxxOriginDry,
  heinekenZero,
  heineken,
  guinness,
  brokenShackles,
  tooheys,
  savannahLite,
  furphyQuiz,
];

const arrayMerge = (destinationArray, sourceArray, options) => sourceArray;

const brandCampaignMapping = {
  0: [16, 18, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 38],
  5: [1],
  4: [2],
  1: [3],
  3: [4],
  6: [5],
  2: [10],
  10: [11],
  7: [12],
  11: [13, 28],
  // 13: [16],
  // 15: [16, 18],
  16: [19],
  18: [23],
  20: [21],
  21: [22],
  22: [37],
};
var selectedBrandId;
if (typeof window.loadedBrandId === 'undefined') {
  selectedBrandId = Object.keys(brandCampaignMapping).find(brandId =>
    brandCampaignMapping[brandId].includes(campaignId)
  );
  if (selectedBrandId !== undefined) {
    console.log('using selected brand ID');
    selectedBrandId = parseInt(selectedBrandId);
  }

  if (selectedBrandId === undefined) {
    if (campaignId) {
      fetch('/fetch.php?campaign_id=' + campaignId.toString())
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.reload) {
            window.location.reload();
          }
        });
    }
  } else {
    // The brand ID was found in the hardcoded map above, but was not found by PHP
    // This is only expected on a local environment that isn't running PHP
  }
} else if (/\/[0-9]+\/?$/.test(window.location.pathname)) {
  setTimeout(() => {
    fetch('/fetch.php');
  }, 5000);
}
if (
  typeof window.loadedBrandId !== 'undefined' &&
  Object.keys(brandCampaignMapping).indexOf(window.loadedBrandId.toString()) !==
    -1
) {
  selectedBrandId = window.loadedBrandId;
}

// const brandId = parseInt(staticBrandId || selectedBrandId || 5);
const brandId = selectedBrandId;

const brand = brands.find(brand => brand.id === brandId);
if (brand.css !== undefined) {
  import('../../scss/' + brand.css + '.scss').catch(error => {
    if (isDev) {
      console.log('Brand styling could not be imported', error);
    }
  });
}

// setTimeout(() => {
//   var beforeinitial = document.getElementById('beforeinitial');
//   beforeinitial.parentNode.removeChild(beforeinitial);
// }, 500);

// setTimeout(() => {
//   var beforeinitial2 = document.getElementById('beforeinitial2');
//   beforeinitial2.parentNode.removeChild(beforeinitial2);
// }, 2500);

export const initialState = {
  name: brandId,
  modes,
  brands: brands.map(brand =>
    deepmerge(brandData, brand, {
      arrayMerge,
    })
  ),
};
