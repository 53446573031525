const url = `${process.env.PUBLIC_URL}/brands/byronBay/`;

const FaxfontWoff = `${url}fonts/FaxFont/FaxfontStandard.woff`;
const FaxfontWoff2 = `${url}fonts/FaxFont/FaxfontStandard.woff2`;
const FaxfontTtf = `${url}fonts/FaxFont/FaxfontStandard.ttf`;

const FaxfontToneWoff = `${url}fonts/FaxFont/FaxfontTone.woff`;
const FaxfontToneWoff2 = `${url}fonts/FaxFont/FaxfontTone.woff2`;
const FaxfontToneTtf = `${url}fonts/FaxFont/FaxfontTone.ttf`;

const TradeGothicLTWoff = `${url}fonts/TradeGothic/TradeGothicLT.woff`;
const TradeGothicLTWoff2 = `${url}fonts/TradeGothic/TradeGothicLT.woff2`;
const TradeGothicLTTtf = `${url}fonts/TradeGothic/TradeGothicLT.ttf`;

const faxFont = {
  fontFamily: 'FaxFont',
  src: `
    url('${FaxfontWoff2}') format('woff2'),
    url('${FaxfontWoff}') format('woff'),
    url('${FaxfontTtf}') format('truetype');
  `,
};

const faxFontTone = {
  fontFamily: 'FaxFont Tone',
  src: `
    url('${FaxfontToneWoff2}') format('woff2'),
    url('${FaxfontToneWoff}') format('woff'),
    url('${FaxfontToneTtf}') format('truetype');
  `,
};

const tradeGothicTone = {
  fontFamily: 'Trade Gothic LT',
  src: `
    url('${TradeGothicLTWoff2}') format('woff2'),
    url('${TradeGothicLTWoff}') format('woff'),
    url('${TradeGothicLTTtf}') format('truetype');
  `,
};

const headerFont = {
  fontFamily: [
    'FaxFont Tone',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  textTransform: 'uppercase',
  letterSpacing: '2.29px',
  lineHeight: '0.75',
};

const bodyFont = {
  fontFamily: [
    'Trade Gothic LT',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
};

export const byronBay = {
  name: 'Byron Bay Brewery',
  id: 3,
  campaigns: ['4'],
  mode: 'dark',
  userTheme: {
    default: {
      mui: {
        mixins: {
          toolbar: {
            minHeight: 70,
          },
        },
        typography: {
          h1: {
            ...headerFont,
            fontSize: '3.5rem',
          },
          h2: { ...headerFont, fontSize: '2.5rem' },
          h3: headerFont,
          h4: headerFont,
          h5: headerFont,
          h6: headerFont,
          body1: bodyFont,
          body2: bodyFont,
          button: {
            fontFamily: [
              'FaxFont',
              '-apple-system',
              'BlinkMacSystemFont',
              '"Segoe UI"',
              'Roboto',
              '"Helvetica Neue"',
              'Arial',
              'sans-serif',
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
            ].join(','),
          },
          subtitle1: bodyFont,
          subtitle2: {
            fontFamily: [
              'FaxFont',
              '-apple-system',
              'BlinkMacSystemFont',
              '"Segoe UI"',
              'Roboto',
              '"Helvetica Neue"',
              'Arial',
              'sans-serif',
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
            ].join(','),
          },
        },
        palette: {
          background: {
            default: '#243746',
          },
          primary: {
            main: '#243746',
            dark: '#1D1D1D',
            contrastText: '#fff',
          },
          secondary: {
            main: '#0075c9',
          },
        },
        overrides: {
          MuiCssBaseline: {
            '@global': {
              // '@font-face': [microbrewSoftFour, oswald],
              '@font-face': [faxFont, faxFontTone, tradeGothicTone],
            },
          },
          MuiButton: {
            root: {
              borderRadius: 0,
              lineHeight: '1',
            },
            contained: {
              boxShadow: 'none',
            },
            sizeLarge: {
              fontSize: '1.5rem',
            },
          },
        },
      },
      brand: {
        paper: false,
        vendor: {
          name: 'Star Liquor',
          image: {
            url: `${url}vendor.svg`,
            alt: 'Star Liquor',
          },
        },
        home: {
          order: {
            direction: 'row',
            sections: [
              {
                id: 'first',
                responsive: {
                  sizes: {
                    xs: 12,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['landing_title'],
              },
              {
                id: 'second',
                responsive: {
                  sizes: {
                    xs: 12,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['hero'],
              },
              {
                id: 'third',
                responsive: {
                  sizes: {
                    xs: 12,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['cta'],
              },
              {
                id: 'forth',
                responsive: {
                  sizes: {
                    xs: 12,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['landing_description'],
              },
              {
                id: 'fith',
                responsive: {
                  sizes: {
                    xs: 12,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['description'],
              },
              {
                id: 'sixth',
                responsive: {
                  sizes: {
                    xs: 12,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['secondary'],
              },
            ],
          },
          howTo: {
            active: true,
            inverse: false,
          },
          images: {
            hero: {
              maxWidth: { mobile: '145px', desktop: '65%' },
              maxHeight: { mobile: '178px', desktop: '435px' },
            },
          },
        },
        lists: {
          point: `${url}number-point.svg`,
        },
        quiz: {
          layout: {
            naked: true,
          },
        },
        logo: {
          url: `${url}logo-bbb.svg`,
          alt: 'Byron Bay Brewery',
        },
        header: {
          height: '78px',
          border: false,
          logo: {
            url: `${url}logo-bbb.svg`,
            alt: 'Byron Bay Brewery',
            height: '54px',
            width: '54px',
            position: 'left',
          },
        },
        nav: {
          side: 'right',
        },
        hero: {
          divider: `${url}divider.svg`,
        },
        footer: {
          background: {
            image: `${url}footer-bg.png`,
          },
          logo: {
            url: `${url}logo-bbb.svg`,
            alt: 'Byron Bay Brewery',
          },
        },
        map: {
          onPrem: {
            inactive: `${url}on-prem-marker.svg`,
            active: `${url}on-prem-marker-active.svg`,
          },
          offPrem: {
            inactive: `${url}off-prem-marker.svg`,
            active: `${url}off-prem-marker-active.svg`,
          },
        },
      },
    },
    light: {
      mui: {
        palette: {
          background: {
            default: '#fff',
          },
        },
      },
      brand: {},
    },
    dark: {
      mui: {
        palette: {
          background: {
            paper: '#000',
          },
        },
      },
      brand: {},
    },
  },
};
