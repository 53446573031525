import React from 'react';
import styled from 'styled-components/macro';
import { useTheme } from '@material-ui/styles';
import get from 'lodash/get';

const Image = styled.img`
  width: 100%;
  height: 100%;
  max-width: 200px;
`;

const LogoIcon = ({ className, ...props }) => {
  const { brand, ...theme } = useTheme();

  const url = get(props, 'url', brand.logo.url);
  const alt = get(props, 'alt', brand.logo.alt);

  return <Image src={url} alt={alt} className={className} theme={theme} />;
};

export default LogoIcon;
