const MicrobrewSoftFourWoff = `${process.env.PUBLIC_URL}/brands/ironJack/fonts/MicrobrewSoftFour.woff`;
const MicrobrewSoftFourWoff2 = `${process.env.PUBLIC_URL}/brands/ironJack/fonts/MicrobrewSoftFour.woff2`;
const MicrobrewSoftFourTtf = `${process.env.PUBLIC_URL}/brands/ironJack/fonts/MicrobrewSoftFour.ttf`;

/*const OswaldWoff = '/brands/ironJack/fonts/Oswald.woff';
const OswaldWoff2 = '/brands/ironJack/fonts/Oswald.woff2';
const OswaldTtf = '/brands/ironJack/fonts/Oswald.ttf';*/

const microbrewSoftFour = {
  fontFamily: 'Microbrew Soft Four',
  src: `
    url('${MicrobrewSoftFourWoff2}') format('woff2'),
    url('${MicrobrewSoftFourWoff}') format('woff'),
    url('${MicrobrewSoftFourTtf}') format('truetype');
  `,
};

/*const oswald = {
  fontFamily: 'Oswald',
  src: `
    url('${OswaldWoff2}') format('woff2'),
    url('${OswaldWoff}') format('woff'),
    url('${OswaldTtf}') format('truetype');
  `,
};*/

const headerFont = {
  fontFamily: [
    'Microbrew Soft Four',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
};

export const ironJack = {
  id: 1,
  name: 'IRON JACK',
  campaigns: ['3'],
  mode: 'light',
  logo: {
    url: `${process.env.PUBLIC_URL}/brands/ironJack/logo.svg`,
    alt: 'Iron Jack - full strength lager',
  },
  userTheme: {
    default: {
      mui: {
        mixins: {
          toolbar: {
            minHeight: 70,
          },
        },
        typography: {
          h1: { ...headerFont, fontSize: '3.5rem' },
          h2: headerFont,
          h3: headerFont,
          // body1: {
          //   fontFamily: [
          //     'Oswald',
          //     '-apple-system',
          //     'BlinkMacSystemFont',
          //     '"Segoe UI"',
          //     'Roboto',
          //     '"Helvetica Neue"',
          //     'Arial',
          //     'sans-serif',
          //     '"Apple Color Emoji"',
          //     '"Segoe UI Emoji"',
          //     '"Segoe UI Symbol"',
          //   ].join(','),
          // },
          // body2: {
          //   fontFamily: [
          //     'Oswald',
          //     '-apple-system',
          //     'BlinkMacSystemFont',
          //     '"Segoe UI"',
          //     'Roboto',
          //     '"Helvetica Neue"',
          //     'Arial',
          //     'sans-serif',
          //     '"Apple Color Emoji"',
          //     '"Segoe UI Emoji"',
          //     '"Segoe UI Symbol"',
          //   ].join(','),
          // },
          // subtitle1: {
          //   fontFamily: [
          //     'Oswald',
          //     '-apple-system',
          //     'BlinkMacSystemFont',
          //     '"Segoe UI"',
          //     'Roboto',
          //     '"Helvetica Neue"',
          //     'Arial',
          //     'sans-serif',
          //     '"Apple Color Emoji"',
          //     '"Segoe UI Emoji"',
          //     '"Segoe UI Symbol"',
          //   ].join(','),
          // },
          // subtitle2: {
          //   fontFamily: [
          //     'Oswald',
          //     '-apple-system',
          //     'BlinkMacSystemFont',
          //     '"Segoe UI"',
          //     'Roboto',
          //     '"Helvetica Neue"',
          //     'Arial',
          //     'sans-serif',
          //     '"Apple Color Emoji"',
          //     '"Segoe UI Emoji"',
          //     '"Segoe UI Symbol"',
          //   ].join(','),
          // },
        },
        palette: {
          background: {
            default: '#deded6',
          },
          primary: {
            main: '#fff',
            dark: '#1D1D1D',
            contrastText: '#000',
          },
          secondary: {
            main: '#dc2b1d',
          },
        },
        overrides: {
          MuiCssBaseline: {
            '@global': {
              // '@font-face': [microbrewSoftFour, oswald],
              '@font-face': [microbrewSoftFour],
            },
          },
        },
      },
      brand: {
        paper: false,
        vendor: {
          name: 'Star Liquor',
          image: {
            url: `${process.env.PUBLIC_URL}/brands/ironJack/vendor.svg`,
            alt: 'Star Liquor',
          },
        },
        lists: {
          point: `${process.env.PUBLIC_URL}/brands/ironJack/number-point.svg`,
        },
        quiz: {
          layout: {
            naked: true,
          },
        },
        home: {
          howTo: {
            active: true,
            inverse: true,
          },
          images: {
            hero: {
              maxWidth: { mobile: '145px', desktop: '65%' },
              maxHeight: { mobile: '178px', desktop: '435px' },
            },
          },
        },
        hero: {
          divider: `${process.env.PUBLIC_URL}/brands/ironJack/divider.svg`,
        },
        header: {
          height: '78px',
          border: 'none',
          //backgroundColor: '#1b2d37', // uncomment to use a custom colour to match textured background
          logo: {
            url: `${process.env.PUBLIC_URL}/brands/ironJack/logo.svg`,
            alt: 'Iron Jack',
            height: '40px',
            width: '150px',
            position: 'center',
          },
        },
        nav: {
          side: 'left',
        },
        footer: {
          inverse: true,
          background: {
            image: '',
          },
        },
      },
    },
    light: {
      mui: {},
      brand: {
        body: {
          background: {
            image: `${process.env.PUBLIC_URL}/brands/ironJack/background-light.svg`,
          },
        },
      },
    },
    dark: {
      mui: {
        palette: {
          background: {
            paper: '#000',
          },
        },
      },
      brand: {
        body: {
          background: {
            image: `${process.env.PUBLIC_URL}/brands/ironJack/background-dark.svg`,
          },
        },
      },
    },
  },
};
