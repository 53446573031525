// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { useTheme } from '@material-ui/styles';
import PageContext, { pageInitalConfig } from '../../../contexts/page';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import Footer from '../../Footer';
import Header from '../../Header';
import PageHero from '../../PageHero';
import HeroImage from '../../HeroImage';
import Theme from '../../../containers/Theme';
import deepmerge from 'deepmerge';
import Div100vh from 'react-div-100vh';

const IE11Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e6e6e6;
`;

const Wrapper = styled(Div100vh)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  align-self: center;
  box-shadow: 0px -6px 50px 0px rgba(0, 0, 0, 0.5);

  ${({ theme }) =>
    css`
      background-color: ${theme.palette.background.default};
    `};
`;

const Content = styled.main`
  width: 100%;
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  will-change: transform;

  ${props =>
    css`
      transition: transform ${
        props['data-theme'].transitions.duration.standard
      }ms ${props['data-theme'].transitions.easing.easeOut};
      transform: ${
        props['data-menu-open'] ? `translateX(-280px)` : `translateX(0px)`
      };
      ${props['data-header'] && `margin-top: ${props['data-height']};`}
      ${props['data-background'] &&
        `background: ${props['data-background'].xs};`}

      ${props['data-theme'].breakpoints.down('md')} {
        padding-top: 72px;
      }

      ${props['data-theme'].breakpoints.up('lg')} {
        transform: translateX(0px);
        margin-top: 0;
        ${props['data-background'] &&
          css`
            background: ${props['data-background'].lg};
          `};
      }
    `}
`;

function reducer(state, action) {
  switch (action.type) {
    case 'reset':
      return pageInitalConfig;
    case 'reset-blacklist':
      return deepmerge(pageInitalConfig, action);
    case 'change':
      return deepmerge(state, action);
    default:
      throw new Error();
  }
}

const CompetitionTemplate = ({ head, children, ...props }) => {
  const { brand, ...theme } = useTheme();
  const [pageConfig, dispatchPageConfig] = useReducer(
    reducer,
    pageInitalConfig
  );

  const { hero, header, pageSlug } = pageConfig;

  const [menuOpen, setMenuOpen] = useState(false);

  const history = useHistory();
  history.listen(() => window.scrollTo(0, 0));

  const displayHeroImage = !history.location.pathname.match(
    /(faq|privacy|terms|participating-venues)/
  );

  let background;
  if (brand.background && brand.background.value) {
    const path = props.location.pathname;
    if (
      path !== '/' &&
      ((brand.background.exclude &&
        brand.background.exclude.indexOf(path) === -1) ||
        (brand.background.include &&
          brand.background.include.indexOf(path) !== -1))
    ) {
      background = brand.background.value;
    }
  }

  return (
    <PageContext.Provider value={{ pageConfig, dispatchPageConfig }}>
      <IE11Wrapper>
        <Wrapper
          theme={theme}
          style={{ minHeight: '100rvh' }}
          className={'page ' + (pageSlug ? 'page_' + pageSlug : null)}
        >
          {header.present && (
            <Header
              data-menu-open={menuOpen}
              data-set-menu-open={setMenuOpen}
            />
          )}
          <Content
            data-header={header.present}
            data-menu-open={menuOpen}
            data-theme={theme}
            data-height={brand.header.height}
            data-background={background}
            className="body"
          >
            {displayHeroImage && <HeroImage />}
            {hero.present && (
              <Theme>
                <PageHero {...hero} />
              </Theme>
            )}
            {children}
          </Content>
          <Theme>
            <Footer data-menu-open={menuOpen} />
          </Theme>
        </Wrapper>
      </IE11Wrapper>
    </PageContext.Provider>
  );
};

CompetitionTemplate.propTypes = {
  children: PropTypes.any.isRequired,
};

export default withRouter(CompetitionTemplate);
