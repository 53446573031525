import React, { useContext, useEffect, createRef } from 'react';
import styled, { css } from 'styled-components/macro';
import { useSelector } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
// import VenuesListItem from './../VenuesListItem';
import VenueListMessage from './../VenueListMessage';
import { getDistance } from 'geolib';
// import AutoSizer from 'react-virtualized-auto-sizer';
// import { FixedSizeList } from 'react-window';
// import { getVenuesByDistanceSelector } from './../../store/resource/selectors';
import Typography from '@material-ui/core/Typography';
import VenueContext from '../../contexts/venue';
import { useTheme } from '@material-ui/styles';
import { getResourceSelector } from './../../store/resource/selectors';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import findIndex from 'lodash/findIndex';

// const PositionedList = styled.div`
//   grid-row: 1;
//   grid-column: 1;
//   pointer-events: all;
//   position: relative;
//   overflow: hidden;
//   display: flex;
//   flex-direction: column;
// `;

const PositionedLoadingBar = styled(LinearProgress)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`;

const VenueListItem = styled.li`
  ${({ icon }) => css`
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 25px;
    background-image: url(${icon});
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 24px 29px;
    padding-left: 35px;

    &:last-child {
      margin-bottom: 0;
    }
  `}
`;

function formatDistance(rawDistance) {
  if (rawDistance >= 1000) {
    return `${Math.round((rawDistance / 1000) * 10) / 10}km`;
  }
  return Math.round(rawDistance) + 'm';
}

const VenuesList = ({ className }) => {
  const venuesStatus = useSelector(getResourceSelector)([
    'campaign',
    'venuesNearMe',
  ]);
  const { venueConfig } = useContext(VenueContext);

  const selectedVenue = useSelector(state => state.location.venue.selected);

  const userLocationDenied = useSelector(state => state.location.user.denied);

  const userCoords = useSelector(state => state.location.user.coords);

  const venuesWithDistance = venueConfig.venueData.map(venue => ({
    ...venue,
    ...(userCoords.latitude &&
      userCoords.longitude && {
        distance: getDistance(
          { latitude: venue.latitude, longitude: venue.longitude },
          userCoords
        ),
      }),
  }));

  const orderedVenues = venuesWithDistance.sort((prev, next) =>
    prev.distance > next.distance ? 1 : -1
  );

  const theme = useTheme();

  const selectedRef = createRef();

  const { inactive, active, listInactive, listActive } = theme.brand.map[
    'onPrem'
  ];

  const desktop = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedRef]);
  return (
    <>
      {venuesStatus.fetching && <PositionedLoadingBar />}
      {venuesStatus.data.length === 0 && venuesStatus.success && (
        <VenueListMessage className={className} noResults />
      )}
      {(desktop || (!desktop && selectedVenue)) && (
        <ul
          className="venuesList"
          style={{ listStyle: 'none', padding: '24px 24px 4px', margin: '0' }}
        >
          {orderedVenues.map(
            venue =>
              ((!desktop && selectedVenue === venue.id) || desktop) && (
                <VenueListItem
                  key={venue.id}
                  data-venue-id={venue.id}
                  icon={
                    selectedVenue === venue.id
                      ? listActive || active
                      : listInactive || inactive
                  }
                  className={
                    selectedVenue === venue.id ? 'venue selected' : 'venue'
                  }
                  ref={selectedVenue === venue.id ? selectedRef : null}
                >
                  <strong style={{ fontWeight: 800 }}>
                    {venue.name}
                    {!userLocationDenied && venue.distance && (
                      <span style={{ float: 'right', fontWeight: 600 }}>
                        <Typography
                          component="span"
                          style={{ fontSize: '16px', fontWeight: '600' }}
                        >
                          {formatDistance(venue.distance)}
                        </Typography>{' '}
                        away
                      </span>
                    )}
                  </strong>
                  <br />
                  {venue.street_address}
                  <br />
                  {venue.suburb}, {venue.state}, {venue.postcode}
                </VenueListItem>
              )
          )}
        </ul>
      )}
    </>
  );
};

export default VenuesList;
