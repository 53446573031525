// https://github.com/diegohaz/arc/wiki/Actions
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
export const USER_BRAND_CHANGE = 'USER_BRAND_CHANGE';
export const USER_SWITCH_THEME = 'USER_SWITCH_THEME';

export const switchBrand = payload => ({
  type: USER_BRAND_CHANGE,
  payload,
});

export const switchUserTheme = payload => ({
  type: USER_SWITCH_THEME,
  payload,
});
