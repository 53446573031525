import React from 'react';
import { Route } from 'react-router-dom';
import SaveForLaterRedirect from '../SaveForLaterRedirect';

const HomeRoute = ({ component: Component, ...props }) => (
  <SaveForLaterRedirect {...props} component={Component}>
    <Route {...props} component={Component} />
  </SaveForLaterRedirect>
);

export default HomeRoute;
