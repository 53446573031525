import React from 'react';
import styled from 'styled-components/macro';
import { Field } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import {
  getResourcesDataSelector,
  getResourceSelector,
} from '../../store/resource/selectors';
//import Link from '@material-ui/core/Link';
import MarkDown from './../../containers/Markdown';
//import CollisionLink from '../CollisionLink';
import { resourceUpdate } from '../../store/resource/actions';

const renderCheckbox = ({
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <FormControlLabel
    {...input}
    {...custom}
    required
    control={<Checkbox value="terms" />}
  />
);

const required = value => (value ? undefined : 'Required');

const SignUpGrid = styled(Grid)`
  label {
    align-items: flex-start;

    .MuiIconButton-root {
      margin-top: -7px;
    }
  }
`;

// eslint-disable-next-line
const SignUpConsentGrid = styled(SignUpGrid)`
  margin-top: 15px;
`;

const WhereToClaimConsent = () => {
  const dispatch = useDispatch();

  const {
    subscription_checkbox_1_label,
    subscription_checkbox_1_required,
    subscription_checkbox_2_label,
    subscription_checkbox_2_required,
  } = useSelector(getResourcesDataSelector)('campaign');

  const { data: codeData } = useSelector(getResourceSelector)('code');
  const customer = useSelector(getResourceSelector)('customer');

  const emailOptIn =
    codeData && 'opt_in_email' in codeData
      ? codeData.opt_in_email
      : customer.opt_in_email;
  const smsOptIn =
    codeData && 'opt_in_sms' in codeData
      ? codeData.opt_in_sms
      : customer.opt_in_sms;

  const handleEmailChange = () => {
    dispatch(
      resourceUpdate('code', 'data', {
        ...codeData,
        opt_in_email: emailOptIn ? false : true,
      })
    );
  };

  const handleSMSChange = () => {
    dispatch(
      resourceUpdate('code', 'data', {
        ...codeData,
        opt_in_sms: smsOptIn ? false : true,
      })
    );
  };

  return (
    <>
      {subscription_checkbox_1_label && (
        <SignUpGrid item xs={12} className="checkboxField">
          <Field
            required={subscription_checkbox_1_required}
            name="opt_in_email"
            id="opt_in_1"
            checked={emailOptIn ? true : false}
            onChange={handleEmailChange}
            label={<MarkDown>{subscription_checkbox_1_label}</MarkDown>}
            validate={subscription_checkbox_1_required ? required : null}
            component={renderCheckbox}
          />
        </SignUpGrid>
      )}
      {subscription_checkbox_2_label && (
        <SignUpGrid item xs={12} className="checkboxField">
          <Field
            required={subscription_checkbox_2_required}
            name="opt_in_2"
            id="opt_in_2"
            checked={smsOptIn ? true : false}
            onChange={handleSMSChange}
            label={<MarkDown>{subscription_checkbox_2_label}</MarkDown>}
            validate={subscription_checkbox_2_required ? required : null}
            component={renderCheckbox}
          />
        </SignUpGrid>
      )}
      <Grid item xs={12}>
        <Typography variant="body2" align="left" className="requiredText">
          *Required
        </Typography>
      </Grid>
    </>
  );
};

export default WhereToClaimConsent;
