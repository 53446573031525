import React from 'react';
import styled from 'styled-components/macro';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/styles';
import Image from '../Image';

const MainBox = styled(Box)`
  pointer-events: all;
  z-index: 1;
  position: relative;

  a {
    display: inline-block;
  }
`;

const WalletBadge = styled(Image)`
  height: 72px;
  width: auto;
`;

const Wallet = ({ device, code }) => {
  const { brand, ...theme } = useTheme();
  const walletUrl = `https://savannah.akcelo-dev.com/api/pass/${device}/${code}`;
  const badges = {
    apple: 'Add_to_Apple_Wallet_rgb_US-UK.svg',
    google: 'google-pay-mark_800_gray.svg',
  };

  return (
    <MainBox mb={2}>
      <a href={walletUrl}>
        <WalletBadge
          theme={theme}
          src={`/${badges[device]}`}
        />
      </a>
    </MainBox>
  );
};

export default Wallet;
