import React, { createRef, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinearProgress from '@material-ui/core/LinearProgress';
import { resourceListReadRequest } from '../../store/resource/actions';
import { useTheme } from '@material-ui/styles';
import MarkDown from '../../containers/Markdown';
import Box from '@material-ui/core/Box';

const ResponsiveBox = styled(Box)`
  ${({ theme }) => css`
    padding-top: ${theme.spacing(2)}px;
    padding-bottom: ${theme.spacing(2)}px;

    ${theme.breakpoints.up('lg')} {
      padding-top: ${theme.spacing(1)}px;
      padding-bottom: ${theme.spacing(2)}px;
    }
  `}
`;

const PositionedContainer = styled(Container)`
  position: relative;
  overflow: hidden;
`;

const MuiExpansionPanel = withStyles({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(ExpansionPanel);

const MuiExpansionPanelSummary = withStyles(theme => ({
  root: {
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    padding: 0,
  },
  expanded: {},
  content: {
    margin: `${theme.spacing(2)}px 0`,
    '&$expanded': {
      margin: `${theme.spacing(2)}px 0`,
    },
  },
}))(ExpansionPanelSummary);

const MuiExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0,
    paddingBottom: theme.spacing(2),
  },
}))(ExpansionPanelDetails);

const Faqs = () => {
  const faqs = useSelector(state => state.resource.campaign.faqs);
  const dispatch = useDispatch();
  const { brand, ...theme } = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    if (!faqs.success && !faqs.fetching) {
      // This will need to contain the id
      dispatch(resourceListReadRequest('campaign', 'faqs'));
    }
  }, [faqs.success, faqs.fetching, dispatch]);

  const [expandedQuestion, setExpandedQuestion] = useState(false);

  const panelRef = createRef();
  return (
    <ResponsiveBox theme={theme}>
      <PositionedContainer maxWidth="md" ref={panelRef}>
        {faqs.fetching && <LinearProgress />}
        {faqs.data.description && <Box p={3}>{faqs.data.description}</Box>}
        {faqs.data.faqs.map(({ question, answer }, index) => (
          <MuiExpansionPanel
            elevation={0}
            key={index}
            expanded={expandedQuestion === question}
            onChange={(event, expanded) => {
              /* If the heading position is before the page offset
              after the animation has finished, scroll to it */
              const panelRefCurrent = panelRef.current;
              setTimeout(function () {
                let headingPosition = panelRefCurrent.children[index].offsetTop;
                let parentItem = panelRefCurrent;
                while (parentItem) {
                  headingPosition += parentItem.offsetTop;
                  parentItem = parentItem.offsetParent;
                }
                if (!desktop) {
                  headingPosition -= document.getElementsByClassName(
                    'header'
                  )[0].clientHeight;
                }
                if (headingPosition < window.pageYOffset) {
                  window.scrollTo(0, headingPosition);
                }
              }, 500);

              setExpandedQuestion(expanded ? question : null);
            }}
            className="faq_row"
          >
            <MuiExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              className="faq_header"
            >
              <Typography component="h2" variant="h6">
                <MarkDown>
                  <strong>{question}</strong>
                </MarkDown>
              </Typography>
            </MuiExpansionPanelSummary>
            <MuiExpansionPanelDetails className="faq_content">
              <Typography gutterBottom component="div">
                <MarkDown>{answer}</MarkDown>
              </Typography>
            </MuiExpansionPanelDetails>
          </MuiExpansionPanel>
        ))}
      </PositionedContainer>
    </ResponsiveBox>
  );
};

export default Faqs;
