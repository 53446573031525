// https://github.com/diegohaz/arc/wiki/Reducers
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import { initialState } from './selectors';
import { USER_SWITCH_API } from './actions';

export default (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case USER_SWITCH_API:
      return {
        ...state,
        api: {
          ...state.api,
          current: payload,
        },
      };

    default:
      return state;
  }
};
