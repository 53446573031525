import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import Head from '../../Head';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import FourOFourPage from '../FourOFourPage';
import PageHero from '../../PageHero';
import PageContext from '../../../contexts/page';

const MenuPage = () => {
  const { dispatchPageConfig } = useContext(PageContext);

  const menu_pages = useSelector(
    state => state.resource.campaign.data.additional_menu_pages
  );

  let menu_page;
  const parts = window.location.pathname.replace(/\/$/, '').split('/');
  if (parts.length === 3) {
    const slug = parts[2];
    for (let i = 0; i < menu_pages.length; i++) {
      const loop_menu_page = menu_pages[i];
      if (loop_menu_page.slug === slug) {
        menu_page = loop_menu_page;
        break;
      }
    }
  }

  useEffect(() => {
    dispatchPageConfig({
      type: 'reset-blacklist',
      pageSlug: menu_page ? 'menu' : 'fourOFour',
    });
  }, [dispatchPageConfig, menu_page]);

  if (!menu_page) {
    return <FourOFourPage homepageLink />;
  }

  return (
    <>
      <Head pageTitle={menu_page.name} />
      <PageHero title={menu_page.name} />
      <Container component="section" maxWidth="md">
        <Box p={4} dangerouslySetInnerHTML={{ __html: menu_page.content }} />
      </Container>
    </>
  );
};

export default MenuPage;
