import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Marker } from '@googlemap-react/core';
import { useTheme } from '@material-ui/styles';
// import VenueContext from '../../contexts/venue';
import { userVenueSelected } from '../../store/location/actions';
// import { GoogleMapContext } from '@googlemap-react/core';
// import get from 'lodash/get';
// const markerVector = (type, palette, active) => {
//   const pinBg = `<path fill="${
//     active ? palette.secondary.main : palette.primary.main
//   }" d="M9 0a9 9 0 0 0-9 9c0 6.3 8 15.4 8.3 15.7a.8.8 0 0 0 1.2 0c.4-.3 8.4-9.4 8.4-15.6a9 9 0 0 0-9-9.1" />`;
//   const fillColour = active
//     ? palette.primary.contrastText
//     : palette.secondary.contrastText;

//   if (type === 'on-prem') {
//     return `${pinBg}<path fill="${fillColour}" d="M4.75 10.08a.26.26 0 0 1-.27.15H1.8a.34.34 0 0 1-.27-.15c-.09-.17 0-.25.06-.47h3.1c.08.23.15.31.06.47zM1.03 2.9l.01-.14h1.8v.96c0 .5.39.92.87.92a.9.9 0 0 0 .88-.92v-.96h.62l.01.14a9.36 9.36 0 0 1-.5 4.24l-.05.1a3.35 3.35 0 0 0-.16 1.75H1.73c.1-.6.04-1.2-.16-1.76l-.05-.07a9.88 9.88 0 0 1-.5-4.26zm.63-1.38c.09 0 .18.01.26.06.07.04.15.05.23.01a.28.28 0 0 0 .16-.15.91.91 0 0 1 .8-.55.9.9 0 0 1 .8.55c.05.08.09.14.16.15.08.02.16.02.23-.01a.5.5 0 0 1 .26-.06.6.6 0 0 1 .6.6h-.87c-.17 0-.3.15-.3.33v1.27a.3.3 0 0 1-.3.31.3.3 0 0 1-.3-.3v-1.3a.3.3 0 0 0-.29-.3H1.08c0-.34.26-.6.58-.61zm3.56 7.8c-.19-.6-.17-1.26.04-1.85l.04-.1c.84-2.3.6-4.67.5-5a1 1 0 0 0 0-.22A1.2 1.2 0 0 0 4.62.91a.9.9 0 0 0-.28.05 1.42 1.42 0 0 0-2.4-.02L1.66.89C1 .9.47 1.45.47 2.14l.01.21a10.4 10.4 0 0 0 .5 5.01l.04.1c.2.6.22 1.24.04 1.85L1 9.52a.97.97 0 0 0 .02.9c.15.3.45.48.77.47h2.7a.9.9 0 0 0 .77-.46.97.97 0 0 0 .02-.9l-.06-.21z" transform="translate(6 4)"/><path fill="${fillColour}" d="M6 6.08v.35l3.04.13.24 2.35.81.09.37-.7.18-1.59.22-.37L12 6.32a3.04 3.04 0 0 1 0-.42 6.54 6.54 0 0 0-.2-.81l-1-.29h-.34l-.67-.54L9.14 4l-.97.44-.9.46-.62.05L6 6.08z"/>`;
//   }

//   return `${pinBg}<path fill="${fillColour}" d="M5.5 12h3v-2h-3zm7.5-2V9l-.5-2.5h-8L4 9v1h.5v3h5v-3h2v3h1v-3zm-.5-5h-8v1h8V5z" />`;
// };

// const offPremMarker = (palette, active) =>
//   `<path fill="${palette.secondary.contrastText}" d="M5.5 12h3v-2h-3zm7.5-2V9l-.5-2.5h-8L4 9v1h.5v3h5v-3h2v3h1v-3zm-.5-5h-8v1h8V5z" />`;

// TODO: SB: This renders everytime a marker is clicked, fix it.

const MapMarker = ({ id, name, latitude, longitude, type, ...props }) => {
  const dispatch = useDispatch();
  // const { DispatchVenueConfig } = useContext(VenueContext);
  const selectedVenue = useSelector(state => state.location.venue.selected);
  const theme = useTheme();
  // const { state: googleState } = useContext(GoogleMapContext);
  const { inactive, active } = theme.brand.map[
    ['on-prem', 'event'].indexOf(type) !== -1 ? 'onPrem' : 'offPrem'
  ];

  function venueClicked(name, id, latitude, longitude) {
    dispatch(userVenueSelected({ id }));
    // const map = get(googleState, 'map', false);

    // if (map) {
    //   googleState.map.setCenter({
    //     lat: Number(latitude),
    //     lng: Number(longitude),
    //   });
    // }
  }

  if (window.google) {
    return (
      <Marker
        key={id}
        id={id}
        onClick={e => venueClicked(name, id, latitude, longitude)}
        opts={{
          icon: {
            url: selectedVenue === id ? active : inactive,
            // url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
            //   `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 25">

            //   ${markerVector(type, theme.palette, id === venueData.id)}
            //   </svg>`
            // )}`,
            // anchor: new window.google.maps.Point(0, 0),
            size: selectedVenue === id ? new window.google.maps.Size(32, 38) : new window.google.maps.Size(24, 29),
            scaledSize: selectedVenue === id ? new window.google.maps.Size(32, 38) : new window.google.maps.Size(24, 29),
          },
          position: {
            lat: Number(latitude),
            lng: Number(longitude),
          },
        }}
        {...props}
      />
    );
  }

  return null;
};

export default MapMarker;
