import React, { useEffect, useState, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import styled, { css } from 'styled-components/macro';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { countdownStart } from '../../store/premise/actions';
import { resourceUpdateRequest } from '../../store/resource/actions';
import {
  getResourceSelector,
  onPremDataSelector,
} from '../../store/resource/selectors';
// import { getSelectedVenue } from '../../store/premise/selectors';

const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-top: ${theme.spacing(7)}px;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      margin-top: ${theme.spacing(4)}px;
    }
  `}
`;

const StartTimerButton = ({ started, expired, ...props }) => {
  const dispatch = useDispatch();
  const { brand, ...theme } = useTheme();
  const [text, setText] = useState();
  // const selectedVenueId = useSelector(getSelectedVenue).id;
  const selectedVenueId = useSelector(state => state.location.venue.selected);
  const { code } = useSelector(getResourceSelector)('customer');
  const customer = useSelector(getResourceSelector)('customer');
  const { data: codeData } = useSelector(getResourceSelector)('code');
  const timerStarted = useSelector(getResourceSelector)('timerStarted');
  const timer_cta_text = useSelector(onPremDataSelector).timer_cta_text;
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const campaignId = useSelector(state => state.campaign.id);

  const emailOptIn =
    codeData && 'opt_in_email' in codeData
      ? codeData.opt_in_email
      : customer.opt_in_email;
  const smsOptIn =
    codeData && 'opt_in_sms' in codeData
      ? codeData.opt_in_sms
      : customer.opt_in_sms;

  const buttonClick = useCallback(() => {
    if (started) {
      dispatch(
        resourceUpdateRequest(
          'redemption',
          `/code/${code}/venue/${selectedVenueId}/redeem`,
          {
            opt_in_email: emailOptIn,
            opt_in_sms: smsOptIn,
          }
        )
      );
    } else {
      setButtonDisabled(true);
      if (!timerStarted.success && !timerStarted.fetching) {
        dispatch(
          resourceUpdateRequest(
            'timerStarted',
            `/code/${code}/venue/${selectedVenueId}/timerStarted`
          )
        );
      }
    }
    // eslint-disable-next-line
  }, [code, dispatch, selectedVenueId, started]);

  useEffect(() => {
    if (started) {
      setButtonDisabled(false);

      setText(
        (brand.timer && brand.timer.during && brand.timer.during.button) ||
          'Validate (Bar staff only)'
      );
    } else {
      setText(
        campaignId === 37
          ? 'Claim your FREE Furphy Crisp'
          : timer_cta_text || 'Claim your free pint'
      );
    }
  }, [code, dispatch, started, brand.timer, timer_cta_text, campaignId]);

  useEffect(() => {
    if (timerStarted.success && !started) {
      dispatch(
        countdownStart({ time: timerStarted.start_time * 1000 || Date.now() })
      );
    }
  }, [dispatch, timerStarted.success, timerStarted.start_time, started]);

  return (
    <Box align="center">
      {desktop && (
        <Box mb={2}>
          <Typography>
            To activate the timer, please switch to a mobile device.
            <br />
            <br />
            You can get back to this page by
            <br />
            simply tapping the unique link sent to your email.
          </Typography>
        </Box>
      )}
      {!desktop && (
        <>
          <ButtonWrapper theme={theme}>
            <Button
              className="button"
              variant="contained"
              size="large"
              disabled={buttonDisabled || !selectedVenueId || !code || expired}
              onClick={buttonClick}
              {...props}
            >
              {text}
            </Button>
          </ButtonWrapper>
          {!started && (
            <Typography style={{ marginTop: '16px', fontSize: '12px' }}>
              *When you’re ready at the bar, press the button above, then show
              the countdown timer to the bartender to validate your free Furphy.
              This offer is valid for 15 minutes.
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default StartTimerButton;
