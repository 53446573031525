import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { savedForLaterSelector } from '../../../store/premise/selectors';

const SaveForLaterRedirect = ({
  component: Component,
  path,
  children,
  ...props
}) => {
  const savedOffer = useSelector(savedForLaterSelector);
  if (path === '*/:campaign/save-for-later' && !savedOffer) {
    return <Redirect {...props} to="*/:campaign" />;
  }

  if (
    savedOffer && path === '*/:campaign'
  ) {
    return <Redirect {...props} to="*/:campaign/save-for-later" />;
  }
  return children;
};

export default SaveForLaterRedirect;
