import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/styles';

export default function Head({ pageTitle }) {
  const campaign = useSelector(state => state.resource.campaign);
  const campaignID = useSelector(state => state.campaign.id);
  let campaignName = campaign.data.name;
  const loaded = campaign.success || campaign.error;

  if (campaignID === 21) {
    campaignName = 'XXXX GOLD State of Origin Promotion';
  }
  if (campaignID === 22) {
    campaignName = 'XXXX DRY State of Origin Promotion';
  }
  if (campaignID === 23) {
    campaignName = 'Tooheys State of Origin Promotion';
  }

  const title = `${pageTitle ? `${pageTitle} - ` : ''}${campaignName}`;
  const theme = useTheme();

  useEffect(() => {
    if (loaded) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'Pageview',
        pagePath: document.URL,
        pageTitle: title,
      });
    }
  }, [loaded, title]);

  useEffect(() => {
    if (campaign.data.facebook_pixel) {
      // Facebook Pixel Code
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      );
      window.fbq('init', campaign.data.facebook_pixel);
      window.fbq('track', 'PageView');
    }
  }, [campaign.data.facebook_pixel]);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={title} />
      <meta name="theme-color" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={
          process.env.PUBLIC_URL +
          '/brands/lion/beer-giveaway/apple-touch-icon.png'
        }
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href={
          process.env.PUBLIC_URL +
          '/brands/lion/beer-giveaway/favicon-192x192.png'
        }
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={
          process.env.PUBLIC_URL +
          '/brands/lion/beer-giveaway/favicon-32x32.png'
        }
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={
          process.env.PUBLIC_URL +
          '/brands/lion/beer-giveaway/favicon-16x16.png'
        }
      />
      {/* <link rel="manifest" href="/lion/site.webmanifest" /> */}
      <link
        rel="mask-icon"
        href="/lion/safari-pinned-tab.svg"
        color={theme.palette.background.default}
      />
      <meta
        name="msapplication-TileColor"
        content={theme.palette.secondary.main}
      />

      {/* <!-- Facebook Meta Tags --> */}
      {/* <meta property="og:url" content="https://www.thesocial.design/" /> */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
      // content="Find out how search engines and social medias such as  Google, Facebook, Twitter display your website"
      />
      {/* 1200px minmium and 8mb max: https://developers.facebook.com/docs/sharing/webmasters/images/ */}
      {/* <meta
        property="og:image:secure_url"
        content="https://www.thesocial.design/open-graph.png"
      /> */}
      <meta property="og:image:width" content="" />
      <meta property="og:image:height" content="" />

      {/* <!-- Twitter Meta Tags --> */}
      <meta property="twitter:url" content={title} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta
        name="twitter:description"
      // content="Find out how search engines and social medias such as  Google, Facebook, Twitter display your website"
      />
      <meta
        name="twitter:image"
      // content="https://www.thesocial.design/open-graph.png"
      />
      <link
        rel="stylesheet"
        href={`${window.location.protocol}//${window.location.hostname}/campaign_css/${campaignID}.css`}
      />
    </Helmet>
  );
}
