// https://github.com/diegohaz/arc/wiki/Reducers
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import { initialState } from './selectors';
import { USER_CAMPAIGN_CHANGE } from './actions';
//import { PURGE } from 'redux-persist';

export default (state = initialState, { type, payload, meta }) => {
  switch (type) {
    // case PURGE:
    //   return initialState;
    case USER_CAMPAIGN_CHANGE:
      return {
        ...state,
        id: payload,
      };

    default:
      return state;
  }
};
