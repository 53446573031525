import React from 'react';
import { useSelector } from 'react-redux';
import { GoogleMapProvider } from '@googlemap-react/core';
import Map from '../Map';
import VenuesList from '../VenuesList';
import styled, { css } from 'styled-components/macro';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import CollisionLink from '../CollisionLink';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/styles';
import Box from '@material-ui/core/Box';

const ResponsiveBox = styled(Box)`
  ${({ theme }) => css`
    padding-top: ${theme.spacing(2)}px;
    padding-bottom: ${theme.spacing(2)}px;

    ${theme.breakpoints.up('lg')} {
      padding-top: ${theme.spacing(1)}px;
      padding-bottom: ${theme.spacing(2)}px;
    }
  `}
`;

const PositionedContainer = styled(Box)`
  position: relative;
  overflow: hidden;
  max-height: 600px;
`;

const CTAContainer = styled(Container)`
  position: relative;
  padding: 20px 20px 40px;
  text-align: center;

  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      padding-top: 0;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 40px;
        top: -40px;
        left: 0;
        background: linear-gradient(
          180deg,
          rgba(0, 60, 121, 0) 0%,
          rgba(0, 60, 121, 1) 100%
        );
      }
    }
  `}
`;

const CTAIntro = styled(Typography)`
  font-size: 18px;
  margin: 0 16px;
  padding-top: 24px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      border-top: 1px solid ${theme.palette.primary.contrastText};
    }
  `}
`;

const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-top: ${theme.spacing(2)}px;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      margin-top: ${theme.spacing(4)}px;
    }
  `}
`;

const WrapperGrid = styled(Grid)`
  position: relative;
  height: calc(100vh - 174px);
  overflow: hidden;
`;

const WrapperGridItem = styled(Grid)`
  position: relative;
  height: 100%;
`;

const VenuesListGrid = styled(Grid)`
  position: relative;
  height: 100%;
`;

const VenuesListWrapper = styled.div`
  flex: 1;
  overflow: scroll;

  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      padding-bottom: 40px;
    }
  `}
`;

const DesktopHero = styled(Box)`
  margin: 40px 0;
`;

const ParticipatingVenues = venues => {
  const { brand, ...theme } = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));
  const ctaText = useSelector(
    state => state.resource.campaign.data.landing_cta_text
  );

  return (
    <>
      {!desktop && (
        <ResponsiveBox theme={theme} className="body">
          <PositionedContainer maxWidth="md" height="100vw">
            <GoogleMapProvider>
              <Map />
            </GoogleMapProvider>
          </PositionedContainer>
          <VenuesList />
          <CTAContainer theme={theme}>
            <CTAIntro theme={theme}>
              Enter now to redeem a FREE Furphy Crisp
            </CTAIntro>
            <ButtonWrapper theme={theme}>
              <Button
                variant="contained"
                size="large"
                component={CollisionLink(`/application`)}
              >
                {ctaText || 'Enter now'}
              </Button>
            </ButtonWrapper>
          </CTAContainer>
        </ResponsiveBox>
      )}
      {desktop && (
        <WrapperGrid container direction="row" className="body">
          <WrapperGridItem item xs={6}>
            <VenuesListGrid
              container
              direction="column"
              justify="flex-end"
              alignItems="stretch"
            >
              <DesktopHero>
                <Typography
                  gutterBottom
                  variant="h2"
                  component="h1"
                  className="title"
                  align="center"
                >
                  Participating Venues
                </Typography>
                <Typography gutterBottom align="center" variant="subtitle1">
                  See your local participating venues below:
                </Typography>
              </DesktopHero>
              <VenuesListWrapper theme={theme} className="">
                <VenuesList />
              </VenuesListWrapper>
              <Grid item>
                <CTAContainer theme={theme} className="container--cta">
                  <CTAIntro theme={theme}>
                    Enter now to redeem a FREE Furphy Crisp
                  </CTAIntro>
                  <ButtonWrapper theme={theme}>
                    <Button
                      variant="contained"
                      size="large"
                      component={CollisionLink(`/application`)}
                    >
                      {ctaText || 'Enter now'}
                    </Button>
                  </ButtonWrapper>
                </CTAContainer>
              </Grid>
            </VenuesListGrid>
          </WrapperGridItem>
          <WrapperGridItem item xs={6}>
            <GoogleMapProvider>
              <Map />
            </GoogleMapProvider>
          </WrapperGridItem>
        </WrapperGrid>
      )}
    </>
  );
};

export default ParticipatingVenues;
