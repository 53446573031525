import React, { useEffect, useContext, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import Container from '@material-ui/core/Container';
import { Redirect } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import Head from '../../Head';
import Barcode from '../../Barcode';
import SignUpBtn from '../../SignUpBtn';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';
import PageContext from '../../../contexts/page';
import qs from 'qs';
import Wallet from '../../Wallet';
import {
  resourceListReadRequest,
  resourceUpdate,
} from '../../../store/resource/actions';
import { getResourceSelector } from '../../../store/resource/selectors';

const Wrapper = styled(Box)`
  width: 100%;

  ${({ theme }) => css`
    background-color: ${theme.palette.background.default};
  `};
`;

const SaveForLaterPage = ({ ...props }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const onPrem = useSelector(getResourceSelector)(['campaign', 'on-prem']);
  const customer = useSelector(getResourceSelector)('customer');
  const campaignDetails = useSelector(getResourceSelector)('campaign');
  const campaignType = campaignDetails.data.type;
  const { dispatchPageConfig } = useContext(PageContext);
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [pageConfigDispatched, setPageConfigDispatched] = useState(false);
  const campaign = useSelector(state => state.campaign.id);
  const userAgent = navigator.userAgent;
  const mobileDevice =
    /iPhone/.test(userAgent) && !/iPad/.test(userAgent)
      ? 'apple'
      : /Android/.test(userAgent)
      ? 'google'
      : false;
  const qsParamCode = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  }).code;

  const codeSources = {
    path:
      (props.match.params.code && props.match.params.code.replace(/,/g, '')) ||
      false,
    param: (qsParamCode && qsParamCode.replace(/,/g, '')) || false,
    customer: customer.code || false,
  };
  const [currentCode, setCurrentCode] = useState(
    codeSources.path || codeSources.param
  );

  useEffect(() => {
    if (!currentCode) {
      if (!customer.fetching) {
        setCurrentCode(customer.success && customer.code);
      }
    } else if (!customer.code) {
      dispatch(resourceUpdate('customer', 'code', currentCode));
    }
  }, [
    dispatch,
    customer.code,
    customer.fetching,
    customer.success,
    codeSources,
    setCurrentCode,
    currentCode,
  ]);

  useEffect(() => {
    if (!pageConfigDispatched && onPrem.data && onPrem.success) {
      dispatchPageConfig({
        type: 'reset-blacklist',
        hero: {
          title:
            onPrem.data.savedForLater_title ||
            onPrem.data.timer_success_title ||
            'Success!',
          blerb:
            (!desktop && onPrem.data.savedForLater_description_mobile) ||
            onPrem.data.savedForLater_description ||
            onPrem.data.timer_success_message ||
            'Take the below barcode to a BWS store to redeem a 6-pack of Furphy Lager or Ale for $10.',
          present: true,
        },
        pageSlug: 'saveForLater',
      });

      setPageConfigDispatched(true);
    }
  }, [
    dispatchPageConfig,
    desktop,
    onPrem,
    pageConfigDispatched,
    setPageConfigDispatched,
  ]);

  useEffect(() => {
    if (!onPrem.success && !onPrem.fetching) {
      dispatch(resourceListReadRequest('campaign', 'on-prem'));
    }
  }, [dispatch, onPrem.fetching, onPrem.success]);

  //timer_success_title and timer_success_message
  //Success! Your beer is waiting
  //Redeem by coming back to this page on the same device, tap continue and select the venue where you would like to drink your cold beer
  if (!customer.fetching && !customer.success && !currentCode) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <Head pageTitle="Success" />
        <Wrapper theme={theme} textAlign="center" className="body">
          {!!currentCode && (
            <>
              {campaignType === 'on-prem' && (
                <>
                  {desktop && <Container maxWidth="sm" children={false} />}
                  {!desktop && (
                    <Container maxWidth="sm" className="saveForLater_container">
                      <SignUpBtn className="cta-signup" redeemSaved />
                    </Container>
                  )}
                </>
              )}
              {campaignType === 'off-prem' &&
                campaign !== 27 &&
                campaign !== 33 &&
                campaign !== 35 && (
                  <Container>
                    {campaign !== 34 && (
                      <Typography
                        theme={theme}
                        variant="subtitle1"
                        component="p"
                        style={{ marginTop: '-1.5rem', marginBottom: '2.5rem' }}
                      >
                        Your unique code has also been emailed to you.
                      </Typography>
                    )}
                    {currentCode && (
                      <>
                        {campaign !== 34 && (
                          <>
                            <Barcode code={currentCode} campaign={campaign} />
                            {mobileDevice && mobileDevice !== 'google' && (
                              <>
                                <Typography
                                  variant="h6"
                                  style={{
                                    textTransform: 'uppercase',
                                    margin: '20px 0',
                                  }}
                                >
                                  or
                                </Typography>
                                <Wallet
                                  device={mobileDevice}
                                  code={currentCode}
                                />
                              </>
                            )}
                          </>
                        )}
                        {campaign === 34 && (
                          <>
                            <Typography
                              variant="h4"
                              style={{
                                textTransform: 'uppercase',
                                marginBottom: '2.5rem',
                              }}
                            >
                              {currentCode}
                            </Typography>
                          </>
                        )}
                      </>
                    )}
                    <Typography
                      theme={theme}
                      variant="subtitle1"
                      component="p"
                      style={{ marginTop: '2.5rem' }}
                    >
                      {/*To find your nearest BWS store,{' '}
                      <Link
                        color="inherit"
                        href={
                          campaign === 19 || campaign === 21 || campaign === 22
                            ? 'https://bws.com.au/stores/QLD'
                            : campaign === 23
                            ? 'https://bws.com.au/stores/NSW'
                            : 'https://bws.com.au/stores/VIC'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        click here
                      </Link>
                      .*/}
                    </Typography>
                  </Container>
                )}
            </>
          )}
        </Wrapper>
      </>
    );
  }
};

export default SaveForLaterPage;
