import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import styled, { css } from 'styled-components/macro';
import get from 'lodash/get';
import Container from '@material-ui/core/Container';
import PageContext from '../../../contexts/page';
import PageHeroTitle from '../../PageHeroTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { resourceUpdate } from '../../../store/resource/actions';
// import BeginButton from '../../BeginButton';
import Head from '../../Head';
import CollisionLink from '../../CollisionLink';
import { useTheme } from '@material-ui/styles';
import {
  getResourceSelector,
  getResourcesDataSelector,
} from '../../../store/resource/selectors';
import { resourceListReadRequest } from '../../../store/resource/actions';

const Background = styled.div`
  ${({ image, theme, ...props }) => css`
    ${image &&
      `background-image: url(${image});
    background-size: cover;
    background-position: center;`}
    ${props['data-border'] &&
      `border-bottom: ${props['data-border']} solid ${theme.palette.primary.main};`}'
  `}
`;

const ContainerWrapper = styled(Container)`
  ${({ theme }) => css`
    text-align: center;
    padding-top: ${theme.spacing(2)}px;
    padding-bottom: ${theme.spacing(2)}px;

    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(5)}px;
    }
  `}
`;

const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-top: ${theme.spacing(7)}px;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      margin-top: ${theme.spacing(4)}px;
    }
  `}
`;

const QuizOptionWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 24px;
    justify-content: center;
    margin-top: ${theme.spacing(14)}px;

    ${theme.breakpoints.down('sm')} {
      margin-top: ${theme.spacing(8)}px;
    }
  `}
`;

const QuizOption = styled(Button)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    max-width: 437px;
    background: #fff;
    color: #003c79;
    margin-top: 0px;
    padding: 60px 60px 98px;
    border-radius: 8px;

    &:hover,
    &:focus,
    &:active {
      background: #fff;
    }

    ${theme.breakpoints.down('sm')} {
      padding: 60px 10px 86px;
    }
  `}
`;

const QuizOptionButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 38px;
  bottom: 30px;
  left: 0;
`;

const QuizOptionImageWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 100%;
    top: -115px;
    left: 0;
    text-align: center;

    ${theme.breakpoints.down('sm')} {
      top: -43px;
    }
  `}
`;

const QuizOptionImage = styled.img`
  ${({ theme }) => css`
    width: auto;
    height: 156px;

    ${theme.breakpoints.down('sm')} {
      height: 86px;
    }
  `}
`;

const QuizOptionButton = styled(Button)`
  position: relative;
  height: 38px;
  padding: 0 45px;
  background: #003c79;
  border: none;
  color: #fefce1;
  border: 4px solid #003c79;

  &:hover,
  &:focus,
  &:active {
    color: #fefce1;
    background: #003c79;
  }
`;

const HomePage = () => {
  const campaignData = useSelector(getResourcesDataSelector)('campaign');
  const { brand, ...theme } = useTheme();
  const campaignType = campaignData.type;
  const { dispatchPageConfig } = useContext(PageContext);
  const campaignLocation = useSelector(getResourceSelector)([
    'campaign',
    'location',
  ]);
  const backgroundImageUrl = get(campaignData, 'background_image.url', '');
  const title = campaignData.landing_title || null;
  const subtitle = campaignData.landing_subtitle || null;
  const ctaText = useSelector(
    state => state.resource.campaign.data.landing_cta_text
  );
  const error = useSelector(state => state.resource.campaign.error);
  const quizData = campaignData.quiz_data || null;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatchPageConfig({
      type: 'reset-blacklist',
      pageSlug: 'home',
    });
  }, [dispatchPageConfig]);

  useEffect(() => {
    if (!campaignLocation.success && !campaignLocation.fetching) {
      dispatch(resourceListReadRequest('campaign', 'location'));
    }
  }, [campaignLocation.success, campaignLocation.fetching, dispatch]);

  if (campaignType === '') {
    return true;
  } else {
    if (campaignType !== 'on-prem') {
      return <Redirect to="/application" />;
    } else {
      return (
        <>
          <Head />
          <Background theme={theme} image={backgroundImageUrl}>
            <ContainerWrapper
              theme={theme}
              component="section"
              maxWidth={false}
            >
              {error ? (
                <h1>An error occurred in loading the campaign data</h1>
              ) : (
                <>
                  {title && <PageHeroTitle title={title} />}
                  {subtitle && (
                    <Typography
                      color="textPrimary"
                      variant="subtitle1"
                      component="p"
                      dangerouslySetInnerHTML={{ __html: subtitle }}
                    />
                  )}
                  {!quizData && (
                    <ButtonWrapper theme={theme}>
                      <Button
                        variant="contained"
                        size="large"
                        component={CollisionLink(`/application`)}
                      >
                        {ctaText || 'Enter now'}
                      </Button>
                      <Button
                        variant="outlined"
                        size="large"
                        component={CollisionLink(`/participating-venues`)}
                      >
                        Participating venues
                      </Button>
                    </ButtonWrapper>
                  )}
                  {quizData && (
                    <>
                      <svg
                        width="276"
                        height="3"
                        viewBox="0 0 276 3"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M276 1.30279C242.5 2.56008 175.833 3.18873 138 2.8744C100.167 3.18873 33.5 2.9545 0 1.69721C33.5 0.439918 100.167 -0.188732 138 0.125595C175.833 -0.188733 242.5 0.0454934 276 1.30279Z"
                          fill="#FEFCE1"
                        />
                      </svg>
                      <QuizOptionWrapper theme={theme}>
                        {quizData[0].answers.map((answer, i) => (
                          <QuizOption
                            variant="contained"
                            size="small"
                            theme={theme}
                            key={i}
                            component={CollisionLink(
                              answer.text === 'Dob' ? `/application` : `/rob`
                            )}
                            onClick={() => {
                              dispatch(
                                resourceUpdate(
                                  'quiz',
                                  'selected_answer',
                                  answer
                                )
                              );
                            }}
                          >
                            {answer.image_url && (
                              <QuizOptionImageWrapper theme={theme}>
                                <QuizOptionImage
                                  src={`https://xxxx-savannah.s3.ap-southeast-2.amazonaws.com/${answer.image_url}`}
                                  theme={theme}
                                />
                              </QuizOptionImageWrapper>
                            )}
                            <Typography
                              variant="h2"
                              component="h2"
                              style={{
                                display: 'block',
                                width: '100%',
                                fontSize: 34,
                                marginBottom: 10,
                              }}
                            >
                              {answer.text}
                            </Typography>
                            <Typography component="p" style={{ fontSize: 16 }}>
                              {answer.terminal_markdown}
                            </Typography>
                            <QuizOptionButtonWrapper>
                              <QuizOptionButton
                                variant="outlined"
                                size="medium"
                              >
                                <span style={{ fontFamily: 'MADEDillan' }}>
                                  Select
                                </span>
                              </QuizOptionButton>
                            </QuizOptionButtonWrapper>
                          </QuizOption>
                        ))}
                      </QuizOptionWrapper>
                    </>
                  )}
                </>
              )}
            </ContainerWrapper>
          </Background>
        </>
      );
    }
  }
};

export default HomePage;
