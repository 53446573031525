const url = `${process.env.PUBLIC_URL}/brands/xxxx/`;

const OswaldMediumWoff2 = `${url}fonts/Oswald/Oswald-Medium.woff2`;
const OswaldMediumWoff = `${url}fonts/Oswald/Oswald-Medium.woff`;
const OswaldMediumTtf = `${url}fonts/Oswald/Oswald-Medium.ttf`;

const OswaldBoldWoff2 = `${url}fonts/Oswald/Oswald-Bold.woff2`;
const OswaldBoldWoff = `${url}fonts/Oswald/Oswald-Bold.woff`;
const OswaldBoldTtf = `${url}fonts/Oswald/Oswald-Bold.ttf`;

const oswald = {
  fontFamily: 'Oswald',
  src: `
    url('${OswaldMediumWoff2}') format('woff2'),
    url('${OswaldMediumWoff}') format('woff'),
    url('${OswaldMediumTtf}') format('truetype');
  `,
};

const oswaldBold = {
  fontFamily: 'Oswald',
  fontWeight: 'bold',
  src: `
    url('${OswaldBoldWoff2}') format('woff2'),
    url('${OswaldBoldWoff}') format('woff'),
    url('${OswaldBoldTtf}') format('truetype');
  `,
};

const headerFont = {
  fontFamily: [
    oswald.fontFamily,
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  textTransform: 'uppercase',
};

const headerBoldFont = {
  fontFamily: [
    oswaldBold.fontFamily,
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  textTransform: 'uppercase',
};

const palette = {
  background: {
    default: '#FFE102',
    paper: '#fff',
  },
  primary: {
    main: '#C80D0F',
    contrastText: '#fff',
    // dark: '#1D1D1D',
  },
  secondary: {
    main: '#FFE102',
    contrastText: '#000',
  },
  text: {
    primary: '#272828',
    secondary: '#272828',
  },
  action: {
    active: '#272828',
  },
};

const typography = {
  h1: {
    ...headerBoldFont,
    fontSize: '7rem',
    fontWeight: 'bold',
  },
  h2: {
    ...headerBoldFont,
    fontSize: '3.75rem',
    fontWeight: 'bold',
  },
  h3: { ...headerBoldFont, fontWeight: 'bold' },
  h4: { ...headerBoldFont, fontWeight: 'bold' },
  h5: { ...headerFont, textTransform: 'normal' },
  h6: { ...headerFont, textTransform: 'normal' },
  button: { ...headerFont, fontSize: '1rem' },
};

export const xxxx = {
  name: 'XXXX',
  id: 2,
  mode: 'light',
  campaigns: ['10'],
  userTheme: {
    default: {
      mui: {
        mixins: {
          toolbar: {
            minHeight: 70,
          },
        },
        typography,
        palette,
        props: {
          MuiButton: {
            color: 'primary',
            variant: 'contained',
            size: 'large',
          },
          MuiLink: {
            underline: 'always',
          },
          MuiCheckbox: {
            color: 'primary',
          },
          MuiRadio: {
            color: 'primary',
          },
          MuiExpansionPanelSummary: {
            root: {
              padding: '0',
            },
          },
        },
        overrides: {
          MuiCssBaseline: {
            '@global': {
              // '@font-face': [microbrewSoftFour, oswald],
              '@font-face': [oswaldBold, oswald],
            },
          },
          MuiButton: {
            root: {
              borderRadius: '2rem',
              lineHeight: '1',
            },
            contained: {
              boxShadow: 'none',
            },
            containedSizeLarge: {
              // fontSize: '1.25rem',
              minWidth: '200px',
              padding: '1rem 2rem',
            },
            label: typography.button,
          },
          MuiInputLabel: {
            root: {
              color: palette.text.primary,
              '&$focused': {
                color: palette.text.primary,
              },
            },
          },
          MuiExpansionPanelSummary: {
            root: {
              padding: '0 1rem',
            },
            content: {
              margin: '1.5rem 0',
              '&$expanded': {
                margin: '1.5rem 0',
              },
            },
          },
        },
      },
      brand: {
        paper: false,
        vendor: {
          name: 'Star Liquor',
          image: {
            url: `${url}vendor.svg`,
            alt: 'Star Liquor',
          },
        },
        home: {
          layout: {
            direction: 'row',
            border: '4px',
            sections: [
              {
                id: 'first',
                responsive: {
                  sizes: {
                    xs: 12,
                    sm: 8,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['hero'],
              },
              {
                id: 'second',
                responsive: {
                  sizes: {
                    xs: 12,
                    sm: 4,
                  },
                  order: {
                    xs: 2,
                    lg: 2,
                  },
                },
                components: ['secondary', 'vendor', 'cta'],
              },
            ],
          },
          howTo: {
            active: true,
            inverse: false,
            icon: {
              color: palette.primary.main,
              marginTop: '5px',
            },
          },
          images: {
            secondary: {
              maxWidth: { mobile: '230px', desktop: '235px' },
            },
          },
        },
        lists: {
          point: `${url}number-point.svg`,
          fontColor: palette.secondary.contrastText,
        },
        quiz: {
          layout: {
            naked: true,
          },
        },
        logo: {
          url: `${url}logo.svg`,
          alt: 'XXXX',
        },
        header: {
          height: '78px',
          border: false,
          shadow: true,
          color: 'secondary',
          logo: {
            url: `${url}logo.svg`,
            alt: 'XXXX',
            height: '40px',
            position: 'left',
          },
        },
        nav: {
          side: 'right',
          color: palette.background.default,
        },
        hero: { border: '4px', background: { color: 'transparent' } },
        footer: {
          border: '4px',
          logo: {
            url: `${url}stamp.png`,
          },
        },
        heroImage: {
          maxWidth: '230px',
        },
        map: {
          inverse: false,
          onPrem: {
            inactive: `${url}on-prem-marker.svg`,
            active: `${url}on-prem-marker-active.svg`,
          },
          offPrem: {
            inactive: `${url}off-prem-marker.svg`,
            active: `${url}off-prem-marker-active.svg`,
          },
        },
        finished: {
          logo: {
            url: `${url}finished_stamp.png`,
            alt: 'XXXX',
          },
        },
        background: {
          value: {
            lg: `url(${url}finished.png) center / contain no-repeat`,
          },
          include: ['/campaign-finished'],
        },
        typography: {
          h1: { color: 'textSecondary' },
          h2: { color: 'textSecondary' },
          h3: { color: 'textSecondary' },
          h4: { color: 'textSecondary' },
          h5: { color: 'textSecondary' },
          h6: { color: 'textSecondary' },
        },
      },
    },
    light: {
      mui: {},
      brand: {},
    },
    dark: {
      mui: {},
      brand: {},
    },
  },
};
