// https://github.com/diegohaz/arc/wiki/Reducers
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import { initialState } from './selectors';
import { USER_SWITCH_THEME } from './actions';

export default (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case USER_SWITCH_THEME:
      return {
        ...state,
        modes: {
          ...state.modes,
          current: payload,
        },
      };
    default:
      return state;
  }
};
