import { createContext } from 'react';

export const venueInitialConfig = {
  venueData: [],
  success: false
};

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
const VenueContext = createContext({
  venueConfig: venueInitialConfig,
  dispatchVenueConfig: () => { },
});

export default VenueContext;
