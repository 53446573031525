import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Head from '../../Head';
import HowTo from '../../HowTo';
import PageContext from '../../../contexts/page';

const HowToEnterPage = () => {
  const howToTitle = useSelector(
    state => state.resource.campaign.data.howto_title
  );
  const { dispatchPageConfig } = useContext(PageContext);

  useEffect(() => {
    dispatchPageConfig({
      type: 'reset-blacklist',
      hero: {
        title: (howToTitle || '').replace('\n', '<br />'),
        present: true,
      },
      pageSlug: 'howToEnter',
    });
  }, [dispatchPageConfig, howToTitle]);

  return (
    <>
      <Head pageTitle="How to Enter" />
      <Box flex="1 1 auto" className="body">
        <HowTo />
      </Box>
    </>
  );
};

export default HowToEnterPage;
