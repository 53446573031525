import isEmail from 'validator/lib/isEmail';

const validate = ({ email }) => {
  const errors = {};

  if (email) {
    if (!isEmail(email)) {
      errors.email = 'Invalid email address';
    }
  } else {
    errors.email = 'Required';
  }
  return errors;
};

export default validate;
