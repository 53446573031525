import React, { useEffect, useContext, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import Container from '@material-ui/core/Container';
import { useSelector } from 'react-redux';
import Head from '../../Head';
import { useTheme } from '@material-ui/styles';
import { Redirect } from 'react-router';
import WhereToClaim from '../../WhereToClaim';
import { getResourceSelector } from './../../../store/resource/selectors';
import {
  resourceClearResource,
  resourceListReadRequest,
  resourceUpdate,
} from '../../../store/resource/actions';
import { clearPremise } from '../../../store/premise/actions';
import PageContext from '../../../contexts/page';
import VenueContext, { venueInitialConfig } from '../../../contexts/venue';
import deepmerge from 'deepmerge';

function reducer(state, action) {
  switch (action.type) {
    case 'reset':
      return venueInitialConfig;
    case 'reset-blacklist':
      return deepmerge(venueInitialConfig, action);
    case 'change':
      return deepmerge(state, action);
    default:
      throw new Error();
  }
}

const WhereToClaimPage = ({ match, ...props }) => {
  const dispatch = useDispatch();
  const customer = useSelector(getResourceSelector)('customer');
  const { data: codeData } = useSelector(getResourceSelector)('code');
  const { data: campaignData } = useSelector(getResourceSelector)('campaign');

  const { brand } = useTheme();
  const { dispatchPageConfig } = useContext(PageContext);

  let paramCode = match.params.code;
  if (paramCode) {
    paramCode = paramCode.replace(/,/g, '');
  }

  const allVenues = useSelector(state => state.resource.campaign.all_venues);

  const [venueConfig, dispatchVenueConfig] = useReducer(
    reducer,
    venueInitialConfig
  );

  useEffect(() => {
    if (!allVenues.success && !allVenues.fetching && !allVenues.fetched) {
      // This will need to contain the id
      dispatch(resourceListReadRequest('campaign', 'all_venues'));
    }
  }, [allVenues.success, allVenues.fetching, allVenues.fetched, dispatch]);

  useEffect(() => {
    if (allVenues.fetched) {
      let venueArray = [];
      // TO DO: Update Savannah response format
      Object.entries(allVenues.data.data).forEach(stateVenues => {
        stateVenues[1].forEach(currentVenue => {
          venueArray.push({
            id: currentVenue.id,
            google_place_id: currentVenue.google_place_id,
            latitude: Number(currentVenue.lat),
            longitude: Number(currentVenue.lng),
            name: currentVenue.name,
            postcode: currentVenue.postcode,
            state: currentVenue.state,
            street_address: currentVenue.street_address,
            suburb: currentVenue.suburb,
            type: "on-prem",
          });
        });
      });

      dispatchVenueConfig({
        type: 'change',
        venueData: venueArray,
        success: true
      });
    }
  }, [allVenues.data, allVenues.fetched, dispatchVenueConfig]);

  useEffect(() => {
    dispatchPageConfig({
      type: 'reset-blacklist',
      hero: {
        title: 'Where to claim.',
        blerb:
          (brand.wheretoclaim && brand.wheretoclaim.blerb) ||
          'Select or search a local participating venue below',
        present: true,
        indicator: brand.wheretoclaim && brand.wheretoclaim.indicator,
      },
      pageSlug: 'whereToClaim',
    });
  }, [dispatchPageConfig, brand.wheretoclaim]);

  useEffect(() => {
    if (paramCode) {
      dispatch(resourceUpdate('customer', 'code', paramCode));
      dispatch(resourceListReadRequest('code', paramCode));
    }
  }, [dispatch, paramCode]);

  useEffect(() => {
    dispatch(resourceClearResource('redemption'));
    dispatch(resourceClearResource('timerStarted'));
    dispatch(clearPremise());
  }, [dispatch]);

  const redeemed = codeData && codeData.redeemed;
  if (redeemed) {
    // return <Redirect to="/application/order?redeemed=already" />;
  } else if (
    campaignData.type &&
    ['on-prem', 'event'].indexOf(campaignData.type) === -1
  ) {
    return <Redirect to="/" />;
  } else if (!paramCode) {
    if (!customer.success) {
      // return <Redirect to="/" />;
    }
  }

  return (
    <>
      <Head pageTitle="Where to claim." />
      <Container maxWidth="sm">
        <VenueContext.Provider value={{ venueConfig, dispatchVenueConfig }}>
          <WhereToClaim className="whereToClaim" />
        </VenueContext.Provider>
      </Container>
    </>
  );
};

export default WhereToClaimPage;
