import React, { useEffect, useContext } from 'react';
import styled, { css } from 'styled-components/macro';
import Head from '../../Head';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import PageContext from '../../../contexts/page';
import MarkDown from '../../../containers/Markdown';
import { useTheme } from '@material-ui/styles';

const ResponsiveBox = styled(Box)`
  ${({ theme }) => css`
    padding-top: ${theme.spacing(2)}px;
    padding-bottom: ${theme.spacing(2)}px;

    ${theme.breakpoints.up('lg')} {
      padding-top: ${theme.spacing(8)}px;
      padding-bottom: ${theme.spacing(8)}px;
    }
  `}
`;

const PrivacyPage = () => {
  const theme = useTheme();

  const { dispatchPageConfig } = useContext(PageContext);

  const privacy = useSelector(state => state.resource.campaign.data.privacy);
  const privacy_url = useSelector(
    state => state.resource.campaign.data.privacy_url
  );

  useEffect(() => {
    dispatchPageConfig({
      type: 'reset-blacklist',
      hero: { title: 'Privacy Policy', present: true },
      pageSlug: 'privacy',
    });
  }, [dispatchPageConfig]);

  if (privacy_url) {
    window.location.href = privacy_url;
    return null;
  } else {
    return (
      <>
        <Head pageTitle="Privacy Policy" />
        <ResponsiveBox theme={theme} flex="1 1 auto" className="body">
          <Container component="section" maxWidth="md" className="privacy">
            <Typography component="div" variant="body1">
              <MarkDown>{privacy}</MarkDown>
            </Typography>
          </Container>
        </ResponsiveBox>
      </>
    );
  }
};

export default PrivacyPage;
