import React from 'react';
import styled, { css } from 'styled-components/macro';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import MarkDown from './../../containers/Markdown';
import get from 'lodash/get';
import PageHeroTitle from '../PageHeroTitle';
import LogoIcon from '../LogoIcon';
import {
  getResourceSelector,
  onPremDataSelector,
} from '../../store/resource/selectors';
import { getCountdownFinished } from '../../store/premise/selectors';

const Wrapper = styled(Box)`
  width: 100%;

  ${({ theme, background, ...props }) => css`
    padding: ${theme.spacing(5, 0)};
    background: ${background.color && background.color} ${background.image &&
    ` url(${background.image}) ${background.position} / ${background.size} ${background.repeat}`}

    ${theme.breakpoints.up('lg')} {
      padding: ${theme.spacing(8, 0)}};
    }

    ${props['data-border'] &&
      `border-bottom: ${props['data-border']} solid ${theme.palette.primary.main};`}
  `};
`;

const LogoIconSpaced = styled(LogoIcon)`
  ${({ theme }) => css`
    margin: ${theme.spacing(0, 4)};
    margin-bottom: ${theme.spacing(4)}px;
    ${theme.breakpoints.up('lg')} {
      margin: ${theme.spacing(0, 4)};
      margin-bottom: ${theme.spacing(8)}px;
    }
  `}
`;

const Arrow = styled.div`
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  border-right: 2px solid;
  border-bottom: 2px solid;
`;

// Put this back to just gutterBottom after Heineken
const Subtitle = styled(Typography)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing(3)}px;
  `}
`;

const Image = styled.img`
  margin-bottom: 2em;
`;

const PageHero = ({
  children,
  title,
  titleColour,
  subtitle,
  blerb,
  indicator,
  upperFeature,
  lowerFeature,
  logo = false,
  border = true,
  className,
}) => {
  const { brand, ...theme } = useTheme();

  const heroConfig = brand.hero;

  const location = useLocation();

  const isSubTitleIncluded =
    heroConfig.subtitle &&
    !heroConfig.subtitle.exclude.find(path => location.pathname.includes(path));
  const redemption = useSelector(getResourceSelector)('redemption');
  const timer_cta_subtext = useSelector(onPremDataSelector).timer_cta_subtext;
  const expired = useSelector(getCountdownFinished);
  const isOrderSubTitleIncluded =
    location.pathname.includes('/order') && !redemption.success && !expired;

  const titleUrl = get(title, 'url', '');
  const titleAlt = get(title, 'alt', '');

  return (
    <Wrapper
      data-border={border && heroConfig.border}
      background={heroConfig.background}
      theme={theme}
      textAlign="center"
      className={className}
    >
      <Container maxWidth="sm">
        {logo && (
          <LogoIconSpaced
            url={logo !== true ? logo.url : undefined}
            alt={logo !== true ? logo.alt : undefined}
            theme={theme}
          />
        )}
        {upperFeature && upperFeature}
        {titleUrl && (
          <Image src={titleUrl} alt={titleAlt} aria-label={titleAlt} />
        )}
        {/* TODO: Remove title colour option */}
        {title && (
          <PageHeroTitle
            color={titleColour ? titleColour : 'textPrimary'}
            title={title}
          />
        )}
        {(subtitle || isSubTitleIncluded) && (
          <Subtitle
            theme={theme}
            color={titleColour ? titleColour : 'textPrimary'}
            // gutterBottom
            variant="h5"
            component="h2"
          >
            {subtitle ? (
              <MarkDown>{subtitle}</MarkDown>
            ) : (
              <MarkDown>{heroConfig.subtitle.text}</MarkDown>
            )}
          </Subtitle>
        )}
        {isOrderSubTitleIncluded ? (
          <Typography
            className="description"
            theme={theme}
            variant="caption"
            component="p"
          >
            {timer_cta_subtext}
          </Typography>
        ) : (
          ''
        )}
        {blerb && (
          <Typography
            gutterBottom
            color="textPrimary"
            variant="subtitle1"
            className="blerb body__text--secondary"
            component="span"
          >
            <MarkDown style={{ whiteSpace: 'pre-line' }}>{blerb}</MarkDown>
            {indicator && <Arrow />}
          </Typography>
        )}
        {lowerFeature && lowerFeature}
        {children}
      </Container>
    </Wrapper>
  );
};

export default PageHero;
