import React, { useContext, useEffect } from 'react';
import VenueFinder from '../../VenueFinder';
import Head from '../../Head';
import PageContext from '../../../contexts/page';


const VenuesPage = props => {
  const { dispatchPageConfig } = useContext(PageContext);

  useEffect(() => {
    dispatchPageConfig({
      type: 'reset-blacklist',
      footer: { stamp: false },
      pageSlug: 'venues',
    });
  }, [dispatchPageConfig]);

  return (
    <>
      <Head pageTitle="Venues" />
      <VenueFinder {...props} urlRespect />
    </>
  );
};

export default VenuesPage;
