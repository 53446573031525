import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import styled, { css } from 'styled-components/macro';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/styles';

const ButtonStyles = styled(Button)`
  margin: 0 auto;
  display: inline-block;
  position: relative;
  ${props =>
    props['data-loading'] &&
    css`
      cursor: progress;
    `}
`;

const LoadingProgress = styled(CircularProgress)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

const BtnContents = styled(Box)`
  opacity: 1;
  transform: scale(1);
  transition: ${({ theme }) =>
    `opacity ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeOut}, transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeOut}`};

  ${props =>
    props['data-loading'] &&
    css`
      /* transform: scale(0.75); */
      opacity: 0.5;
    `}
`;

const ButtonStates = ({
  className,
  loading,
  disabled,
  disabledTip = 'Disabled',
  loadingTip = 'Loading',
  tip = '',
  children,
  ...props
}) => {
  const theme = useTheme();

  const core = (
    <span className={className}>
      <ButtonStyles
        color="primary"
        className="button"
        {...props}
        data-loading={loading}
        disabled={loading || disabled}
      >
        <BtnContents data-loading={loading} theme={theme}>
          {children}
        </BtnContents>
        {loading && <LoadingProgress color="primary" size={24} />}
      </ButtonStyles>
    </span>
  );

  if (disabled) {
    return <Tooltip title={disabledTip}>{core}</Tooltip>;
  }

  if (tip) {
    return <Tooltip title={tip}>{core}</Tooltip>;
  }

  return core;
};

export default ButtonStates;
