// https://github.com/diegohaz/arc/wiki/Reducers
import camelCase from 'lodash/camelCase';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { PURGE } from 'redux-persist';
import { USER_CAMPAIGN_CHANGE } from './campaign/actions';
import { USER_BRAND_CHANGE } from './brand/actions';
import omitDeep from 'deepdash/omitDeep';
import resourceReducer from './resource/reducer.js';
import brandReducer from './brand/reducer.js';
import { campaignId } from '../config';

const version = 5;

const appReducers = { form };

const req = require.context('.', true, /\.\/.+\/reducer\.js$/);

const rootPersistConfig = key => ({
  key: `${campaignId}-${key}`,
  version,
  storage,
  stateReconciler: autoMergeLevel2,
});

req.keys().forEach(key => {
  const storeName = camelCase(key.replace(/\.\/(.+)\/.+$/, '$1'));
  if (key !== './brand/reducer.js' && key !== './resource/reducer.js') {
    appReducers[storeName] = persistReducer(
      rootPersistConfig(key),
      req(key).default
    );
  }
});

let blacklistTransform = createTransform((inboundState, key) => {
  if (key === 'campaign') {
    return omitDeep(inboundState, [
      'success',
      'error',
      'fetching',
      'access_token_data',
    ]);
  } else {
    return inboundState;
  }
});

const resourcePersistConfig = {
  key: `${campaignId}-resource-persist`,
  version,
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['code', 'timerStarted'],
  transforms: [blacklistTransform],
  // transforms: [removeApiStates],
};

const appReducer = combineReducers({
  // brand: persistReducer(persistBrandConfig, brandReducer),
  brand: brandReducer,
  resource: persistReducer(resourcePersistConfig, resourceReducer),
  ...appReducers,
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case USER_CAMPAIGN_CHANGE:
      state = {
        brand: state.brand,
        config: state.config,
        campaign: {
          ...state.campaign,
          id: action.payload,
        },
      };
      break;
    case USER_BRAND_CHANGE:
      state = {
        campaign: state.campaign,
        config: state.config,
        brand: {
          ...state.brand,
          name: action.payload,
        },
      };
      break;
    case PURGE:
      state = undefined;
      break;
    default:
  }

  return appReducer(state, action);
};

export default rootReducer;
