import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTheme } from '@material-ui/styles';
import objectFitImages from 'object-fit-images';
import get from 'lodash/get';

const Img = styled.img`
  max-width: 100%;
  pointer-events: none;
  opacity: ${({ imgLoaded }) => (imgLoaded ? 1 : 0)};
  transition: ${({ theme }) =>
    `opacity ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeOut}`};
`;

const Image = ({
  objectFit,
  src,
  className,
  width = '',
  height = '',
  alt = '',
  title = '',
}) => {
  const theme = useTheme();
  const [imgLoaded, setImgLoaded] = useState(false);
  const [imgThemeSource, setImgThemeSource] = useState('');

  const sizedImg = useCallback(
    node => {
      if (node !== null) {
        node.onload = () => {
          setImgLoaded(true);
          objectFit && objectFitImages(node);
        };
      }
    },
    [objectFit]
  );

  useEffect(() => {
    setImgThemeSource(get(src, theme.palette.type, src));
  }, [src, theme.palette.type]);

  return (
    <Img
      ref={sizedImg}
      imgLoaded={imgLoaded}
      theme={theme}
      className={className}
      src={imgThemeSource}
      width={width}
      height={height}
      alt={alt}
      title={title}
    />
  );
};

export default Image;
