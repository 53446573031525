import React, { useState, createRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { getResourceSelector } from './../../store/resource/selectors';
import { useSelector } from 'react-redux';
import { dobFormat } from '../SignUp';

const SignUpDateField = props => {
  const {
    input,
    meta: { touched, invalid, error },
    ...custom
  } = props;

  const customer = useSelector(getResourceSelector)('customer');

  const [value, setValue] = useState('');

  const ref = createRef();
  // TODO: (sb): This is hacky and silly, fix the store

  const errormessagePresent =
    customer.errors.dob !== '' && customer.errors.dob !== undefined;
  return (
    <TextField
      {...input}
      {...custom}
      value={value}
      variant="outlined"
      onChange={e => {
        const originalValue = e.target.value;
        const formatted_date = dobFormat(originalValue);
        setValue(formatted_date);

        const input = ref.current.querySelector('input');
        let selectionStart = input.selectionStart;

        if (
          originalValue.length < formatted_date.length && // A character was added
          originalValue.substring(0, selectionStart) !==
          formatted_date.substring(0, selectionStart)
        ) {
          // It wasn't after the selection
          selectionStart += formatted_date.length - originalValue.length;
        }
        setTimeout(() => {
          input.selectionStart = selectionStart;
          input.selectionEnd = selectionStart;
        }, 0);
      }}
      innerRef={ref}
      error={(touched && invalid) || errormessagePresent}
      helperText={
        (touched && error) ||
        (errormessagePresent && customer.errors.dob.join(' '))
      }
    />
  );
};

export default SignUpDateField;
