const brandUrl = `${process.env.PUBLIC_URL}/brands/furphy/`;
const campaignUrl = `${process.env.PUBLIC_URL}/campaign/furphy/`;

const MADEDillanWoff2 = `${brandUrl}fonts/MADEDillan/MADEDillan.woff2`;
const MADEDillanWoff = `${brandUrl}fonts/MADEDillan/MADEDillan.woff`;

//Gotham

const GothamBoldWoff2 = `${brandUrl}fonts/Gotham/Gotham-Bold.woff2`;
const GothamBoldWoff = `${brandUrl}fonts/Gotham/Gotham-Bold.woff`;

const GothamWoff2 = `${brandUrl}fonts/Gotham/Gotham-Medium.woff2`;
const GothamWoff = `${brandUrl}fonts/Gotham/Gotham-Medium.woff`;

const MADEDillan = {
  fontFamily: 'MADEDillan',
  src: `
    url('${MADEDillanWoff2}') format('woff2'),
    url('${MADEDillanWoff}') format('woff');
  `,
};

const GothamMedium = {
  fontFamily: 'Gotham',
  fontWeight: 500,
  src: `
    url('${GothamWoff2}') format('woff2'),
    url('${GothamWoff}') format('woff');
  `,
};

const GothamBold = {
  fontFamily: 'Gotham',
  fontWeight: 'bold',
  src: `
    url('${GothamBoldWoff2}') format('woff2'),
    url('${GothamBoldWoff}') format('woff');
  `,
};

const headerFont = {
  fontFamily: [
    MADEDillan.fontFamily,
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
};

const bodyFont = {
  fontFamily: [
    GothamMedium.fontFamily,
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  letterSpacing: 0,
};

const palette = {
  background: {
    default: '#FFFFFF',
    paper: '#FFFFFF',
  },
  primary: {
    main: '#212529',
    contrastText: '#FEFCE1',
  },
  secondary: {
    main: '#212529',
    contrastText: '#FFFFFF',
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#000000',
  },
  button: {
    primary: '#FEFCE1',
    secondary: '#003C79',
  },
};

const typography = {
  h1: {
    ...headerFont,
    fontSize: '7rem',
  },
  h2: {
    ...headerFont,
    fontSize: '2.75rem',
  },
  h3: headerFont,
  h4: headerFont,
  h5: { ...bodyFont, fontWeight: 'bold', textTransform: 'uppercase' },
  h6: { ...bodyFont, fontWeight: 'bold' },
  body1: bodyFont,
  body2: bodyFont,
  button: {
    ...headerFont,
    fontSize: '1rem',
    letterSpacing: 0,
  },
  subtitle1: {
    ...bodyFont,
    fontSize: '1.125rem',
    letterSpacing: 0,
  },
  subtitle2: {
    ...bodyFont,
    letterSpacing: 0,
  },
};

export const furphyQuiz = {
  name: 'Furphy Quiz',
  id: 22,
  campaigns: ['37'],
  userTheme: {
    default: {
      mui: {
        breakpoints: {
          values: {
            xs: 0,
            sm: 716,
            md: 960,
            lg: 1280,
            xl: 1920,
          },
        },
        mixins: {
          toolbar: {
            minHeight: 70,
          },
        },
        typography,
        palette,
        props: {
          MuiLink: {
            underline: 'always',
          },
          MuiButtonBase: {
            disableRipple: true,
          },
          MuiCheckbox: {
            color: 'primary',
          },
          MuiRadio: {
            color: 'primary',
          },
          MuiButton: {
            size: 'large',
          },
        },
        overrides: {
          MuiCssBaseline: {
            '@global': {
              '@font-face': [GothamMedium, GothamBold, MADEDillan],
            },
          },
          MuiButton: {
            root: {
              ...headerFont,
              borderRadius: '2rem',
              borderColor: palette.button.primary,
              lineHeight: '1',
              textTransform: 'initial',
              fontWeight: 'normal',
              backgroundColor: palette.button.primary,
            },
            contained: {
              boxShadow: 'none',
              backgroundColor: palette.button.primary,
              color: palette.button.secondary,
              '&:hover': {
                backgroundColor: palette.text.primary,
              },
            },
            outlined: {
              color: palette.button.primary,
              borderColor: palette.button.primary,
              backgroundColor: 'transparent',
            },
            containedPrimary: {
              backgroundColor: palette.button.primary,
              color: palette.button.secondary,
              '&:hover': {
                backgroundColor: palette.button.primary,
              },
            },
            containedSizeLarge: {
              minWidth: '200px',
              padding: '1.438rem 1.5rem',
            },
            outlinedSizeLarge: {
              minWidth: '200px',
              padding: '1.438rem 1.5rem',
              borderWidth: '2px',
            },
            label: typography.button,
          },
          MuiLink: {
            underlineAlways: {
              color: '#FFFFFF',
            },
          },
          MuiSelect: {
            select: {
              '&:focus': {
                background: 'none !important',
              },
            },
            icon: {
              color: palette.text.primary,
            },
          },
          MuiCheckbox: {
            colorPrimary: {
              color: palette.text.primary,
              '&.Mui-checked': {
                color: palette.text.primary,
              },
            },
          },
          MuiRadio: {
            colorPrimary: {
              color: palette.text.primary,
              '&.Mui-checked': {
                color: palette.text.primary,
              },
            },
          },
          MuiFormControlLabel: {
            label: {
              fontSize: '0.875rem',
              paddingTop: '0.2rem',
            },
          },
          MuiFormHelperText: {
            contained: {
              marginLeft: 0,
              marginRight: 0,
            },
          },
          MuiFormLabel: {
            root: {
              '&$focused&$error': {
                color: '#f44336',
              },
              fontSize: '.875rem',
              fontWeight: 700,
              marginBottom: '.25rem',
            },
          },
          MuiInputLabel: {
            root: {
              '&$focused': {
                color: palette.text.primary,
              },
              color: palette.text.primary,
              transformOrigin: 'initial',
            },
            outlined: {
              '&$shrink': {
                transform: 'initial',
              },
              transform: 'initial',
            },
            formControl: {
              top: 'initial',
              left: 'initial',
              position: 'relative',
              transform: 'initial',
            },
          },
          MuiInput: {
            underline: {
              '&:before, &:after': {
                borderBottom: '1px solid rgba(255, 255, 255, 0.42)',
              },
              '&.Mui-focused': {
                borderBottom: '1px solid rgba(255, 255, 255, 1)',
              },
            },
          },
          MuiOutlinedInput: {
            root: {
              backgroundColor: '#e9eaea',
              '&$focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: 1,
              },
            },
            input: {
              color: palette.text.secondary,
              padding: 14,
              '&::placeholder': {
                opacity: 0.2,
              },
            },
          },
          MuiExpansionPanelSummary: {
            root: {
              padding: '0 1rem',
            },
            content: {
              margin: '1.5rem 0',
              '&$expanded': {
                margin: '1.5rem 0',
              },
            },
          },
          MuiMenuItem: {
            root: {
              color: palette.text.secondary,
            },
          },
          PrivateNotchedOutline: {
            legendNotched: {
              maxWidth: 0,
            },
          },
        },
      },
      brand: {
        paper: false,
        vendor: {
          name: 'Star Liquor',
          image: {
            url: `${brandUrl}vendor.svg`,
            alt: 'Star Liquor',
          },
        },
        home: {
          layout: {
            direction: 'row',
            sections: [
              {
                id: 'first',
                responsive: {
                  sizes: {
                    xs: 12,
                    sm: 6,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['hero', 'landing_title', 'landing_subtitle'],
              },
              {
                id: 'second',
                responsive: {
                  sizes: {
                    xs: 12,
                    sm: 6,
                  },
                  order: {
                    xs: 2,
                    lg: 2,
                  },
                },
                components: ['secondary', 'cta'],
              },
            ],
          },
          howTo: {
            active: false,
            inverse: false,
            icon: {
              fontWeight: 'normal',
              color: palette.primary.main,
              marginTop: '5px',
            },
          },
          images: {
            hero: {
              fullSize: true,
            },
            secondary: {
              maxWidth: { mobile: '150px', desktop: '235px' },
            },
            background: {
              type: 'full',
            },
          },
        },
        lists: {
          point: `${brandUrl}number-point.svg`,
        },
        quiz: {
          layout: {
            naked: true,
          },
        },
        logo: {
          url: `${brandUrl}logo-light.svg`,
          alt: 'Furphy',
        },
        header: {
          color: 'inherit',
          logo: {
            url: `${brandUrl}logo-light.svg`,
            alt: 'Furphy',
            height: '64px',
            width: '57px',
            position: 'left',
          },
        },
        saveForLater: {
          button: 'Redeem now',
        },
        nav: {
          desktopColor: '#FFFFFF' /**/,
          burgerColor: '#FFFFFF' /**/,
        },
        hero: {
          divider: {
            image: `${brandUrl}afl/divider.svg`,
            size: '100% 100%',
            position: 'center center',
            repeat: 'no-repeat',
            color: false,
          },
        },
        footer: {
          inverse: true,
          logo: {
            url: `${brandUrl}logo-light.svg`,
            alt: 'Furphy',
            width: '54px',
            height: '54px',
          },
          partner: {
            url: `${campaignUrl}bws.png`,
            alt: 'BWS',
            width: '106px',
            height: '40px',
          },
        },
        heroImage: {
          onPrem: {
            url: `${campaignUrl}beer-mug.png`,
            maxWidth: '597px',
          },
          offPrem: {
            url: `${campaignUrl}hero-bottles.png`,
            maxWidth: '812px',
          },
        },
        faq: {
          title: 'FAQs',
        },
        timer: {
          countDown: {
            ...headerFont,
            fontWeight: 'normal',
          },
          subtitle: {
            ...bodyFont,
          },
          image: {
            url: `${brandUrl}afl/home-background.png`,
            alt: 'Furphy',
          },
          socialStickers: {
            variant: 'basic',
            instagram: {
              url: `${brandUrl}instagram-icon.png`,
              width: 36,
              height: 36,
            },
            facebook: {
              url: `${brandUrl}facebook-icon.png`,
              width: 36,
              height: 36,
            },
          },
        },
        map: {
          inverse: true,
          onPrem: {
            search: `${brandUrl}on-prem-marker-search.svg`,
            inactive: `${brandUrl}on-prem-marker.svg`,
            active: `${brandUrl}on-prem-marker-active.svg`,
            listInactive: `${brandUrl}on-prem-list-marker.svg`,
            listActive: `${brandUrl}on-prem-list-marker-active.svg`,
          },
          offPrem: {
            inactive: `${brandUrl}off-prem-marker.svg`,
            active: `${brandUrl}off-prem-marker-active.svg`,
          },
        },
        typography: {
          h1: { color: 'textSecondary' },
          h2: { color: 'textSecondary' },
          h3: { color: 'textSecondary' },
          h4: { color: 'textSecondary' },
          h5: { color: 'textSecondary' },
          h6: { color: 'textSecondary' },
          a: { color: 'textSecondary' },
        },
      },
    },
  },
};
