import React, { useEffect, useContext } from 'react';
import styled, { css } from 'styled-components/macro';
import Head from '../../Head';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { useSelector } from 'react-redux';
import PageContext from '../../../contexts/page';
import { useTheme } from '@material-ui/styles';

const ResponsiveBox = styled(Box)`
  ${({ theme }) => css`
    padding-top: ${theme.spacing(2)}px;
    padding-bottom: ${theme.spacing(2)}px;

    ${theme.breakpoints.up('lg')} {
      padding-top: ${theme.spacing(8)}px;
      padding-bottom: ${theme.spacing(8)}px;
    }
  `}
`;

const TermsPage = () => {
  const theme = useTheme();

  const { dispatchPageConfig } = useContext(PageContext);

  const terms = useSelector(state => state.resource.campaign.data.terms);
  const terms_url = useSelector(
    state => state.resource.campaign.data.terms_url
  );

  useEffect(() => {
    dispatchPageConfig({
      type: 'reset-blacklist',
      hero: { title: 'Terms & Conditions', present: true },
      pageSlug: 'terms',
    });
  }, [dispatchPageConfig]);

  if (terms_url) {
    window.location.href = terms_url;
    return null;
  } else {
    return (
      <>
        <Head pageTitle="Terms &amp; Conditions" />
        <ResponsiveBox theme={theme} flex="1 1 auto" className="body">
          <Container component="section" maxWidth="md">
            <Box
              dangerouslySetInnerHTML={{ __html: terms }}
              className="termsContent"
            />
          </Container>
        </ResponsiveBox>
      </>
    );
  }
};

export default TermsPage;
