import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { Link as MuiLink, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useTheme } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import DrinkWise from '../DrinkWise';
import Image from '../Image';
import PageContext from '../../contexts/page';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getResourcesDataSelector } from '../../store/resource/selectors';

const Nav = styled.nav`
  text-align: center;
`;

const ListDivider = styled(List)`
  list-style: none;
`;

const ListItemInline = styled(ListItem)`
  display: inline-block;
  width: auto;
  padding: 0 ${({ theme }) => theme.spacing(4)}px 0 0;
  text-align: center;

  a,
  p {
    font-size: 0.6875rem;
    line-height: 0.6875rem;
  }

  a {
    font-weight: 700;
  }

  &:last-child {
    padding: 0 0 0 ${({ theme }) => theme.spacing(4)}px;

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 1.5rem;
      margin-left: -${({ theme }) => theme.spacing(4)}px;

      ${({ theme }) => theme.breakpoints.down('md')} {
        height: 1rem;
        margin-top: ${({ theme }) => theme.spacing(0.5)}px;
      }
    }
  }

  ${({ theme, ...props }) => css`
    ${props['data-block-mobile'] && (css`
      ${({ theme }) => theme.breakpoints.down('md')} {
        display: block;
        padding: 0;
        margin-bottom: ${({ theme }) => theme.spacing(2)}px;

        &:last-child {
          padding: 0;
          margin-bottom: 0;

          &:before {
            display: none;
          }
        }
      }
    `)}
  `}
`;

const StampContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme, ...props }) => {
    return css`
      ${props['data-border'] &&
      `border-top: ${props['data-border']} solid
          ${theme.palette.primary.main};
      `}
    `;
  }};
`;

const FooterWrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
  flex: 0 0 auto;
  z-index: 0;
  
  ${({ theme, ...props }) =>
    css`
      transition: transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeOut};
      transform: ${props['data-menu-open'] ? `translateX(-280px)` : `translateX(0px)`};
      background-color: ${theme.palette.background.default};
      ${props['data-background-image'] &&
      `background: url(${props['data-background-image']}) center top;
      `} ${props['data-border'] &&
      `border-top: ${props['data-border']} solid
          ${theme.palette.primary.main};
      `}
    `};
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

const FullHeightContainer = styled(Container)`
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const FooterStamp = styled(Image)`
  ${({ theme, ...props }) =>
    css`
      width: ${props.width};
      height: ${props.height};
  `};
`;

const FooterPanel = styled(Box)`
  position: relative;
  box-shadow: 0px -6px 25px 0px rgba(0, 0, 0, .25);

  ${({ theme, ...props }) =>
    css`
      padding: ${theme.spacing(3)}px ${theme.spacing(2)}px;
  `};
`;

const Footer = ({ menuOpen }) => {
  const { brand, ...theme } = useTheme();
  const { pageConfig } = useContext(PageContext);
  const { ...campaignData } = useSelector(
    getResourcesDataSelector
  )('campaign');
  const campaignType = campaignData.type;
  const brandFooterConfig = brand.footer;
  const hideFooterLogos = campaignData.footer_hide_logos;

  const copyright = useSelector(
    state => state.resource.campaign.data.copyright
  );

  const error = useSelector(state => state.resource.campaign.error);
  const terms_url = useSelector(
    state => state.resource.campaign.data.terms_url
  );
  const privacy_url = useSelector(
    state => state.resource.campaign.data.privacy_url
  );

  const pages = error
    ? []
    : [
      {
        title: 'Privacy Policy',
        link: '/privacy',
        internal: !privacy_url,
      },
      {
        title: 'Terms & Conditions',
        link: '/terms',
        internal: !terms_url,
      },
    ];

  const stampPresent = brandFooterConfig.logo.url && pageConfig.footer.stamp;
  const vendorData = campaignType === 'off-prem' && campaignData.vendor;
  const drinkwisePresent = brandFooterConfig.drinkwise;
  const stampHiddenOnPage = ['participating-venues'].includes(pageConfig.pageSlug);

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const logoImage = (
    isDesktop ? {
      url: campaignData.logo_desktop_url,
      meta: campaignData.logo_desktop_meta
    } : {
      url: campaignData.logo_mobile_url || campaignData.logo_desktop_url,
      meta: campaignData.logo_mobile_meta || campaignData.logo_desktop_meta
    }
  );

  return (
    <FooterWrapper
      component="footer"
      data-border={brandFooterConfig.border}
      data-background-image={brandFooterConfig.background.image}
      theme={theme}
      data-menu-open={menuOpen}
      className="body"
    >
      {stampPresent && !stampHiddenOnPage && !hideFooterLogos && (
        <StampContainer theme={theme} mx="auto" textAlign="center" my={6}>
          {vendorData && (
            <Box style={{ flexGrow: '0', paddingRight: theme.spacing(2), marginRight: theme.spacing(2), borderRight: '1px solid black' }}>
              <FooterStamp
                src={`https://xxxx-savannah.s3.ap-southeast-2.amazonaws.com/${vendorData.logo_url}`}
                alt={(vendorData.logo_meta && vendorData.logo_meta.alt_text) || ''}
                width="auto"
                height="40"
              />
            </Box>

          )}
          <FooterStamp
            src={`https://xxxx-savannah.s3.ap-southeast-2.amazonaws.com/${logoImage.url}`}
            alt=""
            width="auto"
            height="40"
          />
        </StampContainer>
      )}
      <FooterPanel theme={theme} className="footer">
        <FullHeightContainer>
          <Nav>
            <ListDivider theme={theme}>
              {drinkwisePresent && (
                <ListItemInline data-block-mobile="true" theme={theme} style={{ fontSize: '1px' }}>
                  <DrinkWise />
                </ListItemInline>
              )}
              {copyright && (
                <ListItemInline data-block-mobile="true" theme={theme}>
                  <Typography variant="body2">
                    {/* Copyright {new Date().getFullYear()} © {copyright} */}
                    {copyright}
                  </Typography>
                </ListItemInline>
              )}
              {pages
                .filter(page => page.link)
                .map((page, i) => (
                  <ListItemInline theme={theme} className="footer__item--link" key={i}>
                    {page.internal ? (
                      <MuiLink
                        component={Link}
                        to={page.link}
                      >
                        {page.title}
                      </MuiLink>
                    ) : (
                      <MuiLink
                        component={Link}
                        to={page.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {page.title}
                      </MuiLink>
                    )}
                  </ListItemInline>
                ))}
            </ListDivider>
          </Nav>
        </FullHeightContainer>
      </FooterPanel>
    </FooterWrapper>
  );
};

export default Footer;
