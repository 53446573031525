import { useSelector } from 'react-redux';
import { brandDataSelector } from '../store/brand/selectors';

function useBrandTheme() {
  const { userTheme: userThemeConfig } = useSelector(
    brandDataSelector
  );

  const { mui, brand } = userThemeConfig.default;

  return { muiTheme: mui, brand };
}

export default useBrandTheme;
