import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useTheme } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import MarkDown from '../../containers/Markdown';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

const TypographyFade = styled(Typography)`
  color: #212529;

  &.enter {
    opacity: 0;
    transform: scale(1);
  }
  &.enter-active {
    opacity: 1;
    transform: scale(1);
    // ${({ theme }) => css`
    //   transition: transform ${theme.transitions.duration.standard}ms
    //       ${theme.transitions.easing.easeIn},
    //     opacity ${theme.transitions.duration.shortest}ms
    //       ${theme.transitions.easing.easeIn};
    // `};
  }
  &.exit {
    opacity: 1;
  }
  &.exit-active {
    transform: scale(0.9);
    opacity: 0;
    // ${({ theme }) => css`
    //   transition: transform ${theme.transitions.duration.standard}ms
    //       ${theme.transitions.easing.easeIn},
    //     opacity ${theme.transitions.duration.shortest}ms
    //       ${theme.transitions.easing.easeIn};
    // `};
  }
`;

const PageHeroTitle = ({ title }) => {
  const theme = useTheme();

  return (
    <SwitchTransition component={null}>
      <CSSTransition
        addEndListener={(node, done) =>
          node.addEventListener('transitionend', done, false)
        }
        key={title}
        timeout={theme.transitions.duration.standard}
      >
        <TypographyFade
          theme={theme}
          gutterBottom
          variant="h2"
          component="h1"
          className="title body__text--primary"
        >
          <MarkDown>{title}</MarkDown>
        </TypographyFade>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default PageHeroTitle;
