import React from 'react';
import { useSelector } from 'react-redux';
import { getDistance } from 'geolib';
import Typography from '@material-ui/core/Typography';

function formatDistance(rawDistance) {
  if (rawDistance >= 1000) {
    return `${Math.round((rawDistance / 1000) * 10) / 10}km`;
  }
  return Math.round(rawDistance) + 'm';
}

const Distance = ({ destination, ...props }) => {
  const userLocationSuccess = useSelector(state => state.location.highAccuracy);
  const userCoords = useSelector(state => state.location.user.coords);

  if (userLocationSuccess && destination.latitude && destination.longitude) {
    return (
      <Typography {...props}>
        {formatDistance(getDistance(destination, userCoords))}
      </Typography>
    );
  }

  return null;
};

export default Distance;
