import { createSelector } from 'reselect';

const initalResourceState = {
  fetching: false,
  error: '',
};

const userInitalState = {
  denied: false,
  fetching: false,
  searched: false,
  asked: false,
  usingDefault: false,
  error: '',
  coords: {
    longitude: 0,
    latitude: 0,
  },
};

export const initialResourceState = {
  user: userInitalState,
  query: {
    description: '',
    placeId: '',
  },
  highAccuracy: false,
  ip: {
    ...initalResourceState,
    longitude: '134.489563',
    city: '',
    timezone: '',
    accuracy: 0,
    asn: 0,
    region: '',
    organization_name: '',
    organization: '',
    country_code: '',
    ip: '',
    latitude: '-25.734968',
    area_code: '',
    continent_code: '',
    country: '',
    country_code3: '',
  },
  venue: {
    selected: false,
  }
};

export const initialState = { ...initialResourceState };

export const userLocationFetchingSelector = createSelector(
  state => state.location.user.fetching
);

export const userHighAccuracyCoordsSelector = createSelector(
  state => state.location.user.coords
);

export const userLocationSuccessSelector = createSelector(
  state => state.location.highAccuracy
);

export const userLocationDeniedSelector = createSelector(
  state => state.location.user.denied
);

export const userLocationSelector = createSelector(
  userLocationSuccessSelector,
  userHighAccuracyCoordsSelector,
  state => state.location.ip.latitude,
  state => state.location.ip.longitude,
  (userLocationSuccessSelector, userCoords, ipLatitude, ipLongitude) => {
    if (userLocationSuccessSelector) {
      return userCoords;
    }
    return { longitude: ipLongitude, latitude: ipLatitude };
  }
);

// export const venueByIdSelector = id =>
//   createSelector(
//     state => state.resource.campaign.venues.data,
//     venues => {
//       return {
//         ...venueInitalState,
//         ...venues.filter(venue => venue.id === id)[0],
//       };
//     }
//   );

export const getResourceState = (state = initialState, resource) =>
  state[resource] || initialResourceState;

// export const getList = (state = initialState, resource) =>
//   getResourceState(state, resource).list;

// export const getDetail = (state = initialState, resource) =>
//   getResourceState(state, resource).detail;
