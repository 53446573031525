import React from 'react';
import styled from 'styled-components/macro';
import { Field } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { getResourcesDataSelector } from '../../store/resource/selectors';
import Link from '@material-ui/core/Link';
import MarkDown from './../../containers/Markdown';
import CollisionLink from '../CollisionLink';

const renderCheckbox = ({
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <FormControlLabel
    {...input}
    {...custom}
    required
    control={<Checkbox className="body__text--secondary" value="terms" />}
  />
);

const required = value => (value ? undefined : 'Required');

const SignUpGrid = styled(Grid)`
  label {
    align-items: flex-start;

    .MuiIconButton-root {
      margin-top: -7px;
    }
  }
`;

const SignUpConsentGrid = styled(SignUpGrid)`
  margin-top: 15px;
`;

const SignUpConsent = () => {
  const termsURL = CollisionLink('/terms');
  const privacyURL = CollisionLink('/privacy');

  const {
    subscription_checkbox_1_label,
    subscription_checkbox_1_required,
    subscription_checkbox_2_label,
    subscription_checkbox_2_required,
    lion_partner_subscription_intro,
    subscription_checkbox_3_label,
    subscription_checkbox_3_required,
    lion_partner_subscription_outro,
  } = useSelector(getResourcesDataSelector)('campaign');

  const start = (
    <span className="body__text--secondary">
      I have read, understood and agree to the{' '}
    </span>
  );
  const terms = termsURL ? (
    <Link
      color="textPrimary"
      className="body__text--secondary"
      component={termsURL}
      target="_blank"
      rel="noopener noreferrer"
    >
      Terms & Conditions
    </Link>
  ) : null;
  const privacy = privacyURL ? (
    <Link
      color="textPrimary"
      className="body__text--secondary"
      component={privacyURL}
      target="_blank"
      rel="noopener noreferrer"
    >
      Privacy Policy
    </Link>
  ) : null;
  return (
    <>
      <SignUpConsentGrid item xs={12} className="checkboxField">
        <Field
          required
          name="terms"
          id="terms"
          className="body__text--secondary"
          label={
            <>
              {start}
              {privacy}
              {privacy && terms ? ' and ' : ''}
              {terms}.*
            </>
          }
          validate={required}
          component={renderCheckbox}
        />
      </SignUpConsentGrid>
      {subscription_checkbox_1_label && (
        <SignUpGrid item xs={12} className="checkboxField">
          <Field
            required={subscription_checkbox_1_required}
            name="opt_in_1"
            id="opt_in_1"
            className="body__text--secondary"
            label={<MarkDown>{subscription_checkbox_1_label}</MarkDown>}
            validate={subscription_checkbox_1_required ? required : null}
            component={renderCheckbox}
          />
        </SignUpGrid>
      )}
      {lion_partner_subscription_intro && (
        <Grid item xs={12}>
          <Typography
            component="p"
            variant="body2"
            className="body__text--secondary"
            style={{ margin: '1rem 0' }}
          >
            <MarkDown>{lion_partner_subscription_intro}</MarkDown>
          </Typography>
        </Grid>
      )}
      {subscription_checkbox_2_label && (
        <SignUpGrid
          item
          xs={12}
          className="checkboxField body__text--secondary"
        >
          <Field
            required={subscription_checkbox_2_required}
            name="opt_in_2"
            id="opt_in_2"
            label={<MarkDown>{subscription_checkbox_2_label}</MarkDown>}
            validate={subscription_checkbox_2_required ? required : null}
            component={renderCheckbox}
          />
        </SignUpGrid>
      )}
      {subscription_checkbox_3_label && (
        <SignUpGrid
          item
          xs={12}
          className="checkboxField body__text--secondary"
        >
          <Field
            required={subscription_checkbox_3_required}
            name="opt_in_3"
            id="opt_in_3"
            label={<MarkDown>{subscription_checkbox_3_label}</MarkDown>}
            validate={subscription_checkbox_3_required ? required : null}
            component={renderCheckbox}
          />
        </SignUpGrid>
      )}
      {lion_partner_subscription_outro && (
        <Grid item xs={12}>
          <Typography
            className="body__text--secondary"
            component="p"
            variant="body2"
            style={{ margin: '1rem 0' }}
          >
            <MarkDown>{lion_partner_subscription_outro}</MarkDown>
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography
          variant="body2"
          align="left"
          className="requiredText body__text--secondary"
        >
          Required*
        </Typography>
      </Grid>
    </>
  );
};

export default SignUpConsent;
