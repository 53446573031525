import { useRef, useEffect, useContext } from 'react';
// import styled from 'styled-components/macro';
// import { keyframes } from 'styled-components/macro';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import NearMe from '@material-ui/icons/NearMe';
import { useDispatch, useSelector } from 'react-redux';
import { userLocationRequest } from '../../store/location/actions';
import { GoogleMapContext } from '@googlemap-react/core';

// const blink = keyframes`
//   to {
//     visibility: hidden;
//   }
// `;

// const NearMeFetching = styled(NearMe)`
//   animation: ${blink} 1s steps(5, start) infinite;
// `;

// const NearMeDisabled = styled(NearMe)`
//   color: rgba(0, 0, 0, 0.25);
// `;

const UserLocation = ({ className, map, color = 'secondary', ...props }) => {
  const dispatch = useDispatch();
  const { state: googleState } = useContext(GoogleMapContext);

  // const defaultZoom = useSelector(state => state.resource.campaign.location.data.map_zoom_level);

  const userLocationSuccess = useSelector(state => state.location.highAccuracy);
  const userLocationSuccessRef = useRef(userLocationSuccess);

  // const userLocationFetching = useSelector(
  //   state => state.location.user.fetching
  // );

  const userCoords = useSelector(state => state.location.user.coords);
  // const userLocationDenied = useSelector(state => state.location.user.denied);
  const userLocationAsked = useSelector(state => state.location.user.asked);

  // function getUserLocation() {
  //   dispatch(userLocationRequest());
  //   if (userLocationSuccess && map && googleState.map) {
  //     googleState.map.setCenter({
  //       lat: Number(userCoords.latitude),
  //       lng: Number(userCoords.longitude),
  //     });
  //     googleState.map.setZoom(defaultZoom);
  //   }
  // }

  useEffect(() => {
    if (!userLocationAsked) {
      dispatch(userLocationRequest());
    }
  }, [dispatch, userLocationAsked]);

  useEffect(() => {
    userLocationSuccessRef.current = userLocationSuccess;
  });

  const userLocationSuccessRefPrev = userLocationSuccessRef.current;

  if (!userLocationSuccessRefPrev && userLocationSuccess) {
    if (map && googleState.map) {
      googleState.map.setCenter({
        lat: Number(userCoords.latitude),
        lng: Number(userCoords.longitude),
      });
      googleState.map.setZoom(16);
    }
  }

  // const LocationIndicator = () => {
  //   if (userLocationSuccess) {
  //     return {
  //       icon: <NearMe color={color} />,
  //       text: 'Using your location',
  //     };
  //   } else if (userLocationFetching) {
  //     return {
  //       icon: <NearMeFetching color={color} />,
  //       text: 'Fetching your location',
  //     };
  //   } else if (userLocationDenied) {
  //     return {
  //       icon: <NearMeDisabled />,
  //       text: 'Permission to use your location denied',
  //     };
  //   }
  //   return {
  //     icon: <NearMe color={color} />,
  //     text: 'Use your location',
  //   };
  // };

  // return LocationIndicator;
  return true;
};

export default UserLocation;
