const url = `${process.env.PUBLIC_URL}/brands/furphy/`;

const AIOzPosterWoff2 = `${url}fonts/AIOzPoster/AIOzPoster-Regular.woff2`;
const AIOzPosterWoff = `${url}fonts/AIOzPoster/AIOzPoster-Regular.woff`;
const AIOzPosterTtf = `${url}fonts/AIOzPoster/AIOzPoster-Regular.ttf`;

const AIOzPosterCondensedWoff2 = `${url}fonts/AIOzPoster/AIOzPoster-Condensed.woff2`;
const AIOzPosterCondensedWoff = `${url}fonts/AIOzPoster/AIOzPoster-Condensed.woff`;
const AIOzPosterCondensedTtf = `${url}fonts/AIOzPoster/AIOzPoster-Condensed.ttf`;

const UniversLTStdBoldCnWoff2 = `${url}fonts/UniversLTStd/UniversLTStd-BoldCn.woff2`;
const UniversLTStdBoldCnWoff = `${url}fonts/UniversLTStd/UniversLTStd-BoldCn.woff`;
const UniversLTStdBoldCnTtf = `${url}fonts/UniversLTStd/UniversLTStd-BoldCn.ttf`;

const UniversLTStdCnWoff2 = `${url}fonts/UniversLTStd/UniversLTStd-Cn.woff2`;
const UniversLTStdCnWoff = `${url}fonts/UniversLTStd/UniversLTStd-Cn.woff`;
const UniversLTStdCnTtf = `${url}fonts/UniversLTStd/UniversLTStd-Cn.ttf`;

const aIOzPoster = {
  fontFamily: 'AIOzPoster',
  src: `
    url('${AIOzPosterWoff2}') format('woff2'),
    url('${AIOzPosterWoff}') format('woff'),
    url('${AIOzPosterTtf}') format('truetype');
  `,
};

const aIOzPosterCondensed = {
  fontFamily: 'AIOzPoster Condensed',
  src: `
    url('${AIOzPosterCondensedWoff2}') format('woff2'),
    url('${AIOzPosterCondensedWoff}') format('woff'),
    url('${AIOzPosterCondensedTtf}') format('truetype');
  `,
};

const universLTStdCn = {
  fontFamily: 'UniversLTStd Condensed',
  src: `
    url('${UniversLTStdCnWoff2}') format('woff2'),
    url('${UniversLTStdCnWoff}') format('woff'),
    url('${UniversLTStdCnTtf}') format('truetype');
  `,
};

const universLTStdBoldCn = {
  fontFamily: universLTStdCn.fontFamily,
  fontWeight: 'bold',
  src: `
    url('${UniversLTStdBoldCnWoff2}') format('woff2'),
    url('${UniversLTStdBoldCnWoff}') format('woff'),
    url('${UniversLTStdBoldCnTtf}') format('truetype');
  `,
};

const headerFont = {
  fontFamily: [
    aIOzPoster.fontFamily,
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  textTransform: 'uppercase',
};

const headerFontCon = {
  fontFamily: [
    aIOzPosterCondensed.fontFamily,
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  textTransform: 'uppercase',
};

const bodyFont = {
  fontFamily: [
    universLTStdCn.fontFamily,
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
};

const palette = {
  background: {
    default: '#003C79',
    paper: '#003C79',
  },
  primary: {
    main: '#FEFCE1',
    contrastText: '#003C79',
  },
  secondary: {
    main: '#003C79',
    contrastText: '#FEFCE1',
  },
  text: {
    primary: '#fff',
    // secondary: '#003C79',
  },
  // divider: '#FEFCE1',
};

const typography = {
  h1: {
    ...headerFont,
    fontSize: '7rem',
  },
  h2: {
    ...headerFontCon,
    fontSize: '3.75rem',
  },
  h3: headerFontCon,
  h4: headerFontCon,
  h5: { ...bodyFont, fontWeight: 'bold', textTransform: 'uppercase' },
  h6: { ...bodyFont, fontWeight: 'bold' },
  body1: bodyFont,
  body2: bodyFont,
  button: { ...bodyFont, fontSize: '1rem' },
  subtitle1: bodyFont,
  subtitle2: bodyFont,
};

export const furphy = {
  name: 'Furphy',
  id: 4,
  campaigns: ['2'],
  mode: 'dark',
  userTheme: {
    default: {
      mui: {
        mixins: {
          toolbar: {
            minHeight: 70,
          },
        },
        typography,
        palette,
        props: {
          MuiLink: {
            underline: 'always',
          },
          MuiButtonBase: {
            disableRipple: true,
          },
          MuiCheckbox: {
            color: 'primary',
          },
          MuiRadio: {
            color: 'primary',
          },
          MuiButton: {
            color: 'primary',
            variant: 'contained',
            size: 'large',
          },
        },
        overrides: {
          MuiCssBaseline: {
            '@global': {
              // '@font-face': [microbrewSoftFour, oswald],
              '@font-face': [
                aIOzPoster,
                aIOzPosterCondensed,
                universLTStdCn,
                universLTStdBoldCn,
              ],
            },
          },
          MuiButton: {
            root: {
              borderRadius: '2rem',
              lineHeight: '1',
            },
            contained: {
              boxShadow: 'none',
            },
            containedSizeLarge: {
              minWidth: '200px',
              padding: '1rem 2rem',
            },
            label: typography.button,
          },
          MuiInputLabel: {
            root: {
              color: palette.text.primary,
              '&$focused': {
                color: palette.text.primary,
              },
            },
          },
          MuiExpansionPanelSummary: {
            root: {
              padding: '0 1rem',
            },
            content: {
              margin: '1.5rem 0',
              '&$expanded': {
                margin: '1.5rem 0',
              },
            },
          },
        },
      },
      brand: {
        paper: false,
        vendor: {
          name: 'Star Liquor',
          image: {
            url: `${url}vendor.svg`,
            alt: 'Star Liquor',
          },
        },
        home: {
          layout: {
            direction: 'row',
            sections: [
              {
                id: 'first',
                responsive: {
                  sizes: {
                    xs: 12,
                    sm: 6,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['hero'],
              },
              {
                id: 'second',
                responsive: {
                  sizes: {
                    xs: 12,
                    sm: 6,
                  },
                  order: {
                    xs: 2,
                    lg: 2,
                  },
                },
                components: ['secondary', 'cta'],
              },
            ],
          },
          howTo: {
            active: true,
            inverse: false,
            icon: {
              color: palette.primary.main,
              marginTop: '5px',
            },
          },
          images: {
            secondary: {
              maxWidth: { mobile: '150px', desktop: '235px' },
            },
            background: {
              type: 'full',
            },
          },
        },
        lists: {
          point: `${url}number-point.svg`,
        },
        quiz: {
          layout: {
            naked: true,
          },
        },
        logo: {
          url: `${url}logo.svg`,
          alt: 'Furphy',
        },
        header: {
          height: '78px',
          border: false,
          color: 'inherit',
          logo: {
            url: `${url}logo.svg`,
            alt: 'Furphy',
            height: '50px',
            width: '50px',
            position: 'left',
          },
        },
        nav: {
          side: 'right',
        },
        hero: {
          divider: {
            image: `${url}divider.png`,
            size: '100% 100%',
            position: 'center center',
            repeat: 'no-repeat',
            color: false,
          },
        },
        footer: {
          color: 'inherit',
          logo: {
            url: `${url}logo.svg`,
          },
        },
        heroImage: {
          maxWidth: '230px',
        },
        map: {
          inverse: true,
          onPrem: {
            inactive: `${url}on-prem-marker.svg`,
            active: `${url}on-prem-marker-active.svg`,
          },
          offPrem: {
            inactive: `${url}off-prem-marker.svg`,
            active: `${url}off-prem-marker-active.svg`,
          },
        },
        typography: {
          h1: { color: 'textSecondary' },
          h2: { color: 'textSecondary' },
          h3: { color: 'textSecondary' },
          h4: { color: 'textSecondary' },
          h5: { color: 'textSecondary' },
          h6: { color: 'textSecondary' },
        },
      },
    },
    light: {
      mui: {
        palette: {
          background: {
            default: '#fff',
            paper: '#fff',
          },
          text: {
            primary: '#000',
            secondary: '#003C79',
          },
        },
      },
      brand: {},
    },
    dark: {
      mui: {},
      brand: {},
    },
  },
};
