// https://github.com/diegohaz/arc/wiki/Reducers
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import { initialState } from './selectors';
import {
  PREMISE_COUNTDOWN_STARTED,
  PREMISE_COUNTDOWN_FINISHED,
  PREMISE_SELECTED,
  PREMISE_SAVE_FOR_LATER,
  PREMISE_CLEAR,
} from './actions';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PREMISE_COUNTDOWN_STARTED:
      return {
        ...state,
        countdown: payload.time,
        saved: false,
      };
    case PREMISE_COUNTDOWN_FINISHED:
      return {
        ...state,
        finished: payload,
      };
    case PREMISE_SELECTED:
      return {
        ...state,
        venue: payload,
      };

    case PREMISE_SAVE_FOR_LATER:
      return {
        ...state,
        saved: true,
      };

    case PREMISE_CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
};
