const brandUrl = `${process.env.PUBLIC_URL}/brands/furphy/`;
const campaignUrl = `${process.env.PUBLIC_URL}/campaign/furphy/`;

const LatoRegularWoff2 = `${process.env.PUBLIC_URL}/fonts/lato-v20-latin-regular.woff2`;
const LatoRegularWoff = `${process.env.PUBLIC_URL}/fonts/lato-v20-latin-regular.woff`;

const LatoBoldWoff2 = `${process.env.PUBLIC_URL}/fonts/lato-v20-latin-700.woff2`;
const LatoBoldWoff = `${process.env.PUBLIC_URL}/fonts/lato-v20-latin-700.woff`;

const LatoHeavyWoff2 = `${process.env.PUBLIC_URL}/fonts/lato-v20-latin-900.woff2`;
const LatoHeavyWoff = `${process.env.PUBLIC_URL}/fonts/lato-v20-latin-900.woff`;

const Lato = {
  fontFamily: 'Lato',
  src: `
    url('${LatoRegularWoff2}') format('woff2'),
    url('${LatoRegularWoff}') format('woff');
  `,
};

const LatoBold = {
  fontFamily: 'Lato',
  fontWeight: 700,
  src: `
    url('${LatoBoldWoff2}') format('woff2'),
    url('${LatoBoldWoff}') format('woff');
  `,
};

const LatoHeavy = {
  fontFamily: 'Lato',
  fontWeight: 900,
  src: `
    url('${LatoHeavyWoff2}') format('woff2'),
    url('${LatoHeavyWoff}') format('woff');
  `,
};

const headerFont = {
  fontFamily: [
    Lato.fontFamily,
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  fontWeight: 900,
};

const bodyFont = {
  fontFamily: [
    Lato.fontFamily,
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
};

const palette = {
  background: {
    default: '#FFFFFF',
    paper: '#FFFFFF',
  },
  primary: {
    main: '#212529',
    contrastText: '#F3FCE1',
  },
  secondary: {
    main: '#FFFFFF',
    contrastText: '#212529',
  },
  text: {
    primary: '#212529',
    secondary: '#FFFFFF',
  },
};

const typography = {
  h1: {
    ...headerFont,
    fontSize: '7rem',
  },
  h2: {
    ...headerFont,
    fontSize: '2.75rem',
  },
  h3: headerFont,
  h4: headerFont,
  h5: { ...bodyFont, fontWeight: 'bold', textTransform: 'uppercase' },
  h6: { ...bodyFont, fontWeight: 'bold' },
  body1: bodyFont,
  body2: bodyFont,
  button: {
    ...bodyFont,
    fontSize: '1rem',
    letterSpacing: 0,
  },
  subtitle1: {
    ...bodyFont,
    fontSize: '1.125rem',
    letterSpacing: 0,
  },
  subtitle2: {
    ...bodyFont,
    letterSpacing: 0,
  },
};

export const savannahLite = {
  name: 'Savannah Lite',
  id: 0,
  campaigns: ['16', '18', '27', '28', '29', '30', '31', '32', '35'],
  css: 'savannahLite',
  userTheme: {
    default: {
      mui: {
        breakpoints: {
          values: {
            xs: 0,
            sm: 716,
            md: 960,
            lg: 1280,
            xl: 1920,
          },
        },
        mixins: {
          toolbar: {
            minHeight: 70,
          },
        },
        typography,
        palette,
        props: {
          MuiLink: {
            underline: 'always',
          },
          MuiButtonBase: {
            disableRipple: true,
          },
          MuiCheckbox: {
            color: 'primary',
          },
          MuiRadio: {
            color: 'primary',
          },
          MuiButton: {
            size: 'large',
          },
        },
        overrides: {
          MuiCssBaseline: {
            '@global': {
              '@font-face': [Lato, LatoBold, LatoHeavy],
            },
          },
          MuiButton: {
            root: {
              borderRadius: '2rem',
              borderColor: palette.text.primary,
              lineHeight: '1',
              textTransform: 'initial',
              fontWeight: '900',
              backgroundColor: palette.background.default,
            },
            contained: {
              boxShadow: 'none',
              backgroundColor: palette.text.primary,
              color: palette.text.secondary,
              '&:hover': {
                backgroundColor: palette.text.primary,
              },
            },
            outlined: {
              borderColor: palette.text.primary,
            },
            containedPrimary: {
              backgroundColor: palette.text.primary,
              color: palette.text.secondary,
              '&:hover': {
                backgroundColor: palette.text.primary,
              },
            },
            containedSizeLarge: {
              minWidth: '200px',
              padding: '1.438rem 1.5rem',
            },
            outlinedSizeLarge: {
              minWidth: '200px',
              padding: '1.438rem 1.5rem',
              borderWidth: '2px',
            },
            label: typography.button,
          },
          MuiCheckbox: {
            colorPrimary: {
              color: palette.text.primary,
              '&.Mui-checked': {
                color: palette.text.primary,
              },
            },
          },
          MuiRadio: {
            colorPrimary: {
              color: palette.text.primary,
              '&.Mui-checked': {
                color: palette.text.primary,
              },
            },
          },
          MuiFormControlLabel: {
            label: {
              fontSize: '0.875rem',
              paddingTop: '0.2rem',
            },
          },
          MuiFormHelperText: {
            contained: {
              marginLeft: 0,
              marginRight: 0,
            },
          },
          MuiFormLabel: {
            root: {
              '&$focused&$error': {
                color: '#f44336',
              },
              fontSize: '.875rem',
              fontWeight: 700,
              marginBottom: '.25rem',
            },
          },
          MuiInputLabel: {
            root: {
              '&$focused': {
                color: palette.text.primary,
              },
              color: palette.text.primary,
              transformOrigin: 'initial',
            },
            outlined: {
              '&$shrink': {
                transform: 'initial',
              },
              transform: 'initial',
            },
            formControl: {
              top: 'initial',
              left: 'initial',
              position: 'relative',
              transform: 'initial',
            },
          },
          MuiOutlinedInput: {
            root: {
              backgroundColor: '#e9eaea',
              '&$focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: 1,
              },
            },
            input: {
              padding: 14,
              '&::placeholder': {
                opacity: 0.2,
              },
            },
          },
          MuiExpansionPanelSummary: {
            root: {
              padding: '0 1rem',
            },
            content: {
              margin: '1.5rem 0',
              '&$expanded': {
                margin: '1.5rem 0',
              },
            },
          },
          PrivateNotchedOutline: {
            legendNotched: {
              maxWidth: 0,
            },
          },
        },
      },
      brand: {
        paper: false,
        vendor: {
          name: 'Star Liquor',
          image: {
            url: `${brandUrl}vendor.svg`,
            alt: 'Star Liquor',
          },
        },
        home: {
          layout: {
            direction: 'row',
            sections: [
              {
                id: 'first',
                responsive: {
                  sizes: {
                    xs: 12,
                    sm: 6,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['hero', 'landing_title', 'landing_subtitle'],
              },
              {
                id: 'second',
                responsive: {
                  sizes: {
                    xs: 12,
                    sm: 6,
                  },
                  order: {
                    xs: 2,
                    lg: 2,
                  },
                },
                components: ['secondary', 'cta'],
              },
            ],
          },
          howTo: {
            active: false,
            inverse: false,
            icon: {
              color: palette.primary.main,
              marginTop: '5px',
            },
          },
          images: {
            hero: {
              fullSize: true,
            },
            secondary: {
              maxWidth: { mobile: '150px', desktop: '235px' },
            },
            background: {
              type: 'full',
            },
          },
        },
        lists: {
          point: `${brandUrl}number-point.svg`,
        },
        quiz: {
          layout: {
            naked: true,
          },
        },
        logo: {
          url: `${brandUrl}logo-light.svg`,
          alt: 'Furphy',
        },
        header: {
          color: 'inherit',
          logo: {
            url: `${brandUrl}logo-light.svg`,
            alt: 'Furphy',
            height: '64px',
            width: '57px',
            position: 'left',
          },
        },
        nav: {
          desktopColor: '#FFFFFF' /**/,
          burgerColor: '#FFFFFF' /**/,
        },
        hero: {
          divider: {
            image: `${brandUrl}afl/divider.svg`,
            size: '100% 100%',
            position: 'center center',
            repeat: 'no-repeat',
            color: false,
          },
        },
        footer: {
          inverse: true,
          logo: {
            url: `${brandUrl}logo-light.svg`,
            alt: 'Furphy',
            width: '54px',
            height: '54px',
          },
          partner: {
            url: `${campaignUrl}bws.png`,
            alt: 'BWS',
            width: '106px',
            height: '40px',
          },
        },
        heroImage: {
          onPrem: {
            url: `${campaignUrl}beer-mug.png`,
            maxWidth: '597px',
          },
          offPrem: {
            url: `${campaignUrl}hero-bottles.png`,
            maxWidth: '812px',
          },
        },
        faq: {
          title: 'FAQs',
        },
        timer: {
          image: {
            url: `${brandUrl}afl/home-background.png`,
            alt: 'Furphy',
          },
        },
        map: {
          inverse: true,
          onPrem: {
            search: `${brandUrl}on-prem-marker-search.svg`,
            inactive: `${brandUrl}on-prem-marker.svg`,
            active: `${brandUrl}on-prem-marker-active.svg`,
          },
          offPrem: {
            inactive: `${brandUrl}off-prem-marker.svg`,
            active: `${brandUrl}off-prem-marker-active.svg`,
          },
        },
        typography: {
          h1: { color: 'textSecondary' },
          h2: { color: 'textSecondary' },
          h3: { color: 'textSecondary' },
          h4: { color: 'textSecondary' },
          h5: { color: 'textSecondary' },
          h6: { color: 'textSecondary' },
        },
      },
    },
  },
};
