import React from 'react';
import styled from 'styled-components/macro';
import { useTheme } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const ResultsContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  flex: 1 1 0%;
  background: ${({ theme }) => theme.palette.background.paper};
  opacity: ${({ ...props }) => (props['data-hide'] ? 0 : 1)};
  pointer-events: ${({ ...props }) => (props['data-hide'] ? 'none' : 'auto')};
`;

const VenueListMessage = ({ hide, locationDenied, noResults, className }) => {
  const theme = useTheme();

  if (!locationDenied && !noResults) {
    return null;
  }

  return (
    <ResultsContainer
      className={className}
      data-hide={hide}
      theme={theme}
      minHeight={332}
      p={2}
      pt={4}
    >
      <Typography
        gutterBottom
        color="textPrimary"
        component="span"
        display="block"
        variant="h6"
      >
        {locationDenied && `We don't know where you are.`}
        {noResults && `Please zoom in to find venues in this area.`}
      </Typography>
      <Typography color="textPrimary" component="span" variant="body1">
        {locationDenied &&
          `Your browser settings is blocking us from finding your location, please
        enable location search in your browser settings. Otherwise you can use
        the search box above to find your nearest vendor.`}
        {noResults &&
          `Try searching for a location as well as panning and zooming for results.`}
      </Typography>
    </ResultsContainer>
  );
};

export default VenueListMessage;
