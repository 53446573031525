import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import Container from '@material-ui/core/Container';
import SignUp from '../../SignUp';
import Box from '@material-ui/core/Box';
import Head from '../../Head';
import {
  getResourcesDataSelector,
  getResourceSelector,
} from '../../../store/resource/selectors';
import { useTheme } from '@material-ui/styles';
import PageContext from '../../../contexts/page';

const SignUpPage = ({ ...props }) => {
  const campaignData = useSelector(getResourcesDataSelector)('campaign');
  const quizData = useSelector(getResourceSelector)('quiz');
  const selectedAnswer = quizData.selected_answer || {};
  const formTitle =
    selectedAnswer.override_form_title || campaignData.form_title;
  const formBlerb =
    selectedAnswer.override_form_description || campaignData.form_description;
  const { brand } = useTheme();
  const { dispatchPageConfig } = useContext(PageContext);

  useEffect(() => {
    dispatchPageConfig({
      type: 'reset-blacklist',
      hero: {
        title: formTitle,
        blerb: formBlerb,
        divider: 'before',
        present: true,
        indicator: brand.signup && brand.signup.indicator,
      },
      pageSlug: 'signUp',
    });
  }, [formTitle, formBlerb, brand.signup, dispatchPageConfig]);

  if (
    campaignData.quiz_question_count > 0 &&
    Object.keys(selectedAnswer).length === 0
  ) {
    return <Redirect to={'/'} />;
  } else {
    return (
      <>
        <Head pageTitle="Sign up" />
        <Box flex="1 1 auto" className="body">
          <Container maxWidth="sm">
            <SignUp {...props} />
          </Container>
        </Box>
      </>
    );
  }
};

export default SignUpPage;
