import { takeLeading, put, call, takeEvery } from 'redux-saga/effects';
import actions from '../actions';

const getUserLocation = () =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      location => resolve(location),
      error => reject(error)
    );
  });

export function* requestCurrentPosition() {
  try {
    const location = yield call(getUserLocation);
    yield put(actions.userLocationRequestSuccess(location));
  } catch ({ code, message: payload }) {
    switch (code) {
      // PERMISSION_DENIED
      case 1:
        yield put(actions.userLocationRequestDenied(payload));
        break;
      // POSITION_UNAVAILABLE
      case 2:
        yield put(actions.userLocationRequestUnavailable(payload));
        break;
      // TIMEOUT
      default:
      case 3:
        yield put(actions.userLocationRequestTimeout(payload));
    }
  }
}
export function* readResourceList(api) {
  try {
    const list = yield call(
      [api, api.get],
      `https://get.geojs.io/v1/ip/geo.json`
    );
    yield put(actions.ipLocationReadSuccess(list));
  } catch (e) {
    yield put(actions.ipLocationReadFailure(e));
  }
}

export function* updateResource(api, { needle, data }, { resource }) {
  try {
    const detail = yield call([api, api.put], `/${resource}/${needle}`, data);
    yield put(
      actions.resourceUpdateSuccess(resource, detail, { needle, data })
    );
  } catch (e) {
    yield put(actions.resourceUpdateFailure(resource, e, { needle, data }));
  }
}

export function* watchResourceListReadRequest(api, { payload, meta }) {
  yield call(readResourceList, api, payload, meta);
}

export function* watchResourceUpdateRequest(api, { payload, meta }) {
  yield call(updateResource, api, payload, meta);
}

export default function*({ api }) {
  yield takeLeading(actions.USER_LOCATION_REQUEST, requestCurrentPosition);

  yield takeEvery(
    actions.IP_LOCATION_READ_REQUEST,
    watchResourceListReadRequest,
    api
  );
}
