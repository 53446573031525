import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Burger from '../Burger';
import Nav from '../Nav';
import Theme from '../../containers/Theme';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import { useTheme } from '@material-ui/styles';
import Image from '../Image';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getResourcesDataSelector } from '../../store/resource/selectors';
import useIsCampaignExpired from '../../hooks/useIsCampaignExpired';

const StyledBox = styled(Box)`
  overflow: hidden;
  display: flex;
  height: 100%;
  flex: 1 1 auto;
  align-items: center;

  ${props => css`
    ${props['data-theme'].breakpoints.down('md')} {
      &:after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #000000;
        z-index: 10;
        height: ${props['data-menu-open'] ? '100%' : '0'};
        opacity: ${props['data-menu-open'] ? '0.5' : '0'};
        transition: opacity
          ${props['data-theme'].transitions.duration.standard}ms
          ${props['data-theme'].transitions.easing.easeOut};
      }
    }
  `}
`;

const PositionedBurger = styled(Burger)`
  position: relative;
  flex: 0 0 26px;
  z-index: 20;
  outline: none;
  order: 1;

  ${({ theme }) => css`
    ${theme.breakpoints.up('lg')} {
      display: none;
    }
  `}
`;

const FixedHeightAppBar = styled(AppBar)`
  display: flex;
  height: 90px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      position: fixed;
      height: 72px;
    }
  `}
`;

const ContainerFill = styled(Container)`
  flex: 1 1 100%;
  display: flex;
  height: 100%;
  align-items: center;
  z-index: -1;
  max-width: 100%;
`;

const LogoContainer = styled(Box)`
  display: flex;
  height: 100%;
  align-items: center;
  margin: 0;
  z-index: 1;

  ${({ theme, config }) => css`
    img {
      display: block;
      width: ${config.width};
      height: auto;
      padding: 0;
      max-width: 250px;
      transform: scale(0.86);

      ${theme.breakpoints.up('lg')} {
        max-width: none;
        transform: none;
      }
    }
  `}
`;

// TODO: (sb) Fix this mess
const Header = ({ ...props }) => {
  const { brand, ...theme } = useTheme();
  const { venues_page, ...campaignData } = useSelector(
    getResourcesDataSelector
  )('campaign');

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const logoImage = isDesktop
    ? {
      url: campaignData.logo_desktop_url,
      meta: campaignData.logo_desktop_meta,
    }
    : {
      url: campaignData.logo_mobile_url || campaignData.logo_desktop_url,
      meta: campaignData.logo_mobile_meta || campaignData.logo_desktop_meta,
    };
  const isCampaignExpired = useIsCampaignExpired();
  const hasHowTo = !!campaignData.howto_step1;
  const titleCTA = campaignData.landing_cta_text;
  const error = useSelector(state => state.resource.campaign.error);
  let navItemsOg = [];
  let navItems = [];
  let applicationLabel = 'Enter Now';
  const menuOpen = props['data-menu-open'];
  const setMenuOpen = props['data-set-menu-open'];

  // Add event listeners
  useEffect(() => {
    const escHandler = ({ keyCode }) => {
      if (keyCode === 27) {
        setMenuOpen(false);
      }
    };
    window.addEventListener('keyup', escHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keyup', escHandler);
    };
  }, [setMenuOpen]);

  if (hasHowTo) {
    navItemsOg.push({
      name: 'How To Enter',
      link: { url: '/how-to-enter', internal: true },
    });
  }

  if (useSelector(state => state.resource.campaign.data.has_faqs)) {
    navItemsOg.push({
      name: 'FAQs',
      link: { url: '/faq', internal: true },
    });
  }

  if (campaignData.type === 'on-prem') {
    navItemsOg.push({
      name: 'Participating Venues',
      link: { url: '/participating-venues', internal: true },
    });
  }

  if (titleCTA !== undefined) {
    applicationLabel = titleCTA;
  }

  navItemsOg.push({
    name: applicationLabel,
    link: { url: '/application', internal: true },
  });

  if (!error) {
    if (!isCampaignExpired) {
      navItems = navItems.concat(navItemsOg);
    }

    navItems = navItems.concat([
      {
        name: 'Privacy Policy',
        link: { url: '/privacy', internal: true },
        mobileOnly: true,
      },
      {
        name: 'Terms & Conditions',
        link: { url: '/terms', internal: true },
        mobileOnly: true,
      },
    ]);

    if (!isCampaignExpired) {
      if (venues_page) {
        navItems.push({
          name: 'Venues',
          link: { url: '/venues', internal: true },
        });
      }
    }
  }

  return (
    <FixedHeightAppBar
      theme={theme}
      position="relative"
      className="header"
      elevation={0}
    >
      <ContainerFill>
        {navItems.length ? (
          <PositionedBurger
            className="hamburger-btn-icon"
            theme={theme}
            data-menu-open={menuOpen}
            data-set-menu-open={setMenuOpen}
          />
        ) : null}
        <StyledBox data-theme={theme} data-menu-open={menuOpen}>
          <LogoContainer theme={theme} config={logoImage}>
            <Link component={RouterLink} to="/">
              {logoImage.url && (
                <Image
                  className="header-logo"
                  src={`https://xxxx-savannah.s3.ap-southeast-2.amazonaws.com/${logoImage.url}`}
                  width={logoImage.meta.width || ''}
                  height={logoImage.meta.height || ''}
                  alt={logoImage.meta.alt || ''}
                  title={logoImage.meta.title || ''}
                />
              )}
            </Link>
          </LogoContainer>
        </StyledBox>
        {navItems.length ? (
          <Theme>
            <Nav
              className="primaryNav header__nav"
              theme={theme}
              navItems={navItems}
              data-menu-open={menuOpen}
              data-set-menu-open={setMenuOpen}
            />
          </Theme>
        ) : null}
      </ContainerFill>
    </FixedHeightAppBar>
  );
};

export default memo(Header);
