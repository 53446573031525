import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import isPast from 'date-fns/isPast';
import parseISO from 'date-fns/parseISO';

function useIsCampaignExpired() {
  const [isExpired, setIsExpired] = useState(false);
  const endDate = useSelector(state => state.resource.campaign.data.finish);
  const activeFromLastCall = useSelector(
    state => state.resource.campaign.data.active
  );

  useEffect(() => {
    const expired =
      !activeFromLastCall || (endDate && isPast(parseISO(endDate)));

    setIsExpired(expired);
  }, [endDate, activeFromLastCall]);

  return isExpired;
}

export default useIsCampaignExpired;
