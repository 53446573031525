import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CollisionLink from '../CollisionLink';
import MarkDown from './../../containers/Markdown';

const Icon = styled(Box)`
  width: 42px;
  height: 42px;
  border-radius: 42px;
  flex: 0 0 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) =>
    css`
      background-color: ${theme.palette.text.primary};
      margin-bottom: 0;
      margin-right: ${theme.spacing(2)}px;

      ${theme.breakpoints.up('md')} {
        margin-bottom: ${theme.spacing(2)}px;
        margin-right: 0;

        width: 72px;
        height: 72px;
        flex: 0 0 72px;
      }
    `}
`;

const ListItemBorder = styled(ListItem)`
  ${({ theme, ...props }) =>
    css`
      border-bottom: ${props['data-last'] ? 0 : `1px`} solid
        ${theme.palette.divider};
      padding: ${theme.spacing(2)}px 0;

      ${theme.breakpoints.up('md')} {
        padding: 0 ${theme.spacing(4)}px;
        border-top: 0;
        border-bottom: 0;
        border-right: ${props['data-last'] ? 0 : `1px`} solid
          ${theme.palette.divider};
      }
    `}
`;

const PositionedPaper = styled(Paper)`
  /* display: flex; */
  align-items: center;
  pointer-events: all;
  z-index: 1;
  position: relative;
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
`;

const MainList = styled(List)`
  ${({ theme }) => css`
    display: grid;
    grid-auto-rows: 1fr;
    margin-bottom: ${theme.spacing(8)}px;

    ${theme.breakpoints.up('md')} {
      display: flex;
      .MuiListItem-root {
        flex-direction: column;
        text-align: center;
      }
    }
  `}
`;

const HowToListItemText = styled(ListItemText)`
  width: 100%;
`;

const InnerNumber = styled(Typography)`
  color: ${({ theme, ...props }) =>
    props['data-color']
      ? props['data-color']
      : theme.palette.primary.contrastText};
`;

const TitleWrapper = styled(Box)`
  width: 100%;
  ${({ theme, background, ...props }) => css`
    padding: ${theme.spacing(5, 0)};
    ${theme.breakpoints.up('lg')} {
      padding: ${theme.spacing(8, 0)}
    };}
  `};
`;

const HowTo = ({ className, title = false, background }) => {
  const howToTitle = useSelector(
    state => state.resource.campaign.data.howto_title
  );
  const ctaText = useSelector(
    state => state.resource.campaign.data.howto_cta_text
  );

  const steps = [
    useSelector(state => state.resource.campaign.data.howto_step1),
    useSelector(state => state.resource.campaign.data.howto_step2),
    useSelector(state => state.resource.campaign.data.howto_step3),
    useSelector(state => state.resource.campaign.data.howto_step4),
  ].filter(step => step);

  const { brand, ...theme } = useTheme();

  if (!howToTitle && steps.length < 1) {
    return null;
  }

  const paperWrapped = brand.paper;
  const howToConfig = brand.home.howTo;

  const mainList = (
    <MainList theme={theme} className="howTo">
      {steps.map((step, i) => (
        <ListItemBorder
          disableGutters
          data-last={i === steps.length - 1}
          theme={theme}
          key={i}
        >
          <Icon theme={theme} point={brand.lists.point}>
            <InnerNumber
              className="number"
              data-color={theme.palette.text.secondary}
              theme={theme}
              variant="h4"
              component="span"
              style={{ fontWeight: brand.home.howTo.icon.fontWeight || 'bold' }}
            >
              {i + 1}
            </InnerNumber>
          </Icon>
          <HowToListItemText
            primaryTypographyProps={{ color: 'textPrimary' }}
            primary={step}
            className="howToText"
          />
        </ListItemBorder>
      ))}
    </MainList>
  );

  const paperWrapper = (
    <PositionedPaper theme={theme}>{mainList}</PositionedPaper>
  );

  const content = (
    <Container maxWidth="md" component="section" className={className}>
      <Box p={2} mb={2} textAlign="center">
        {title && howToTitle && (
          <TitleWrapper
            theme={theme}
            textAlign="center"
            mx="auto"
            style={{ maxWidth: '600px' }}
          >
            <Typography color="textSecondary" variant="h2" component="h1">
              <MarkDown>{howToTitle}</MarkDown>
            </Typography>
          </TitleWrapper>
        )}
        {paperWrapped ? paperWrapper : mainList}
        <Box mb={2} textAlign="center">
          <Button
            variant="contained"
            size="large"
            component={CollisionLink(`/application`)}
          >
            {ctaText || 'Enter now'}
          </Button>
        </Box>
        <Box mb={2} textAlign="center">
          {howToConfig.disclaimer ? (
            <Typography
              color={howToConfig.disclaimer.color}
              variant="caption"
              component="h1"
            >
              <MarkDown>{howToConfig.disclaimer.text}</MarkDown>
            </Typography>
          ) : (
            ''
          )}
        </Box>
      </Box>
    </Container>
  );

  if (background) {
    return (
      <Box flex="1 0 auto" bgcolor="background.paper">
        {content}
      </Box>
    );
  }

  return content;
};

export default HowTo;
