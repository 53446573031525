// https://github.com/diegohaz/arc/wiki/Actions
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
export const USER_LOCATION_UPDATE_REQUEST = 'USER_LOCATION_UPDATE_REQUEST';
export const USER_LOCATION_UPDATE_SUCCESS = 'USER_LOCATION_UPDATE_SUCCESS';
export const USER_LOCATION_UPDATE_FAILURE = 'USER_LOCATION_UPDATE_FAILURE';

export const LOCATION_QUERY_READ_REQUEST = 'LOCATION_QUERY_READ_REQUEST';
export const LOCATION_QUERY_READ_SUCCESS = 'LOCATION_QUERY_READ_SUCCESS';
export const LOCATION_QUERY_READ_FAILURE = 'LOCATION_QUERY_READ_FAILURE';

export const USER_VENUE_SELECTED = 'USER_VENUE_SELECTED';

export const USER_LOCATION_REQUEST = 'USER_LOCATION_REQUEST';
export const USER_LOCATION_SUCCESS = 'USER_LOCATION_SUCCESS';
export const USER_LOCATION_PERMISSION_DENIED =
  'USER_LOCATION_PERMISSION_DENIED';
export const USER_LOCATION_POSITION_UNAVAILABLE =
  'USER_LOCATION_POSITION_UNAVAILABLE';
export const USER_LOCATION_TIMEOUT = 'USER_LOCATION_TIMEOUT';

export const IP_LOCATION_READ_REQUEST = 'IP_LOCATION_READ_REQUEST';
export const IP_LOCATION_READ_SUCCESS = 'IP_LOCATION_READ_SUCCESS';
export const IP_LOCATION_READ_FAILURE = 'IP_LOCATION_READ_FAILURE';

export const UPDATE_MAP_CENTER = 'UPDATE_MAP_CENTER';
export const DEFAULT_MAP_CENTER = 'DEFAULT_MAP_CENTER';

export const ipLocationReadRequest = (resource, detail, params) => ({
  type: IP_LOCATION_READ_REQUEST,
  payload: { params },
  meta: {
    resource,
    detail,
  },
});

export const ipLocationReadSuccess = list => ({
  type: IP_LOCATION_READ_SUCCESS,
  payload: list,
});

export const ipLocationReadFailure = error => ({
  type: IP_LOCATION_READ_FAILURE,
  error,
});

export const userLocationRequest = () => ({
  type: USER_LOCATION_REQUEST,
});

export const userLocationRequestSuccess = payload => ({
  type: USER_LOCATION_SUCCESS,
  payload,
});

export const userLocationRequestDenied = payload => ({
  type: USER_LOCATION_PERMISSION_DENIED,
  payload,
});

export const userLocationRequestUnavailable = payload => ({
  type: USER_LOCATION_POSITION_UNAVAILABLE,
  payload,
});

export const userLocationRequestTimeout = payload => ({
  type: USER_LOCATION_TIMEOUT,
  payload,
});

export const locationQueryReadRequest = payload => ({
  type: LOCATION_QUERY_READ_REQUEST,
  payload,
});

export const locationQueryReadSuccess = payload => ({
  type: LOCATION_QUERY_READ_SUCCESS,
  payload,
});

export const locationQueryReadFailure = () => ({
  type: LOCATION_QUERY_READ_FAILURE,
});

export const userVenueSelected = payload => ({
  type: USER_VENUE_SELECTED,
  payload,
});

export const updateMapCenter = payload => ({
  type: UPDATE_MAP_CENTER,
  payload,
});

export const defaultMapCenter = payload => ({
  type: DEFAULT_MAP_CENTER,
  payload,
});
