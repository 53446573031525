import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { resourceListReadRequest } from '../../store/resource/actions';
import LinearProgress from '@material-ui/core/LinearProgress';
import FourOFourPage from './../../components/pages/FourOFourPage';
import { BrowserRouter } from 'react-router-dom';
import { basename } from './../../config';

const PositionedLoadingBar = styled(LinearProgress)`
  position: absolute !important;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
`;

function Campaign({ children }) {
  const campaignId = Number(useSelector(state => state.campaign.id));
  const campaign = useSelector(state => state.resource.campaign);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!campaign.success && !campaign.fetching && campaignId !== 0) {
      // This will need to contain the id
      dispatch(resourceListReadRequest('campaign'));
    }
  }, [campaign, dispatch, campaignId]);
  if (campaignId === 0 || campaign.message === '404') {
    return <FourOFourPage />;
  }

  // For some reason, campaign.fetching is deliberately not set to false in store/resource/reducer.js
  // Check campaign.error to work around this
  return (
    <BrowserRouter basename={`${basename ? `${basename}/` : ''}${campaignId}`}>
      {campaign.fetching && !campaign.error && <PositionedLoadingBar />}
      {children}
    </BrowserRouter>
  );
}

export default Campaign;
