import React from 'react';

import configureStore, { persistor } from './store/configure';
import Campaign from './containers/Campaign';
import CompetitionTemplate from './components/templates/CompetitionTemplate';
import { HelmetProvider } from 'react-helmet-async';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import { Router as ReactRouter } from 'react-router-dom';
import Routes from './containers/Routes';
import Theme from './containers/Theme';
import api from './services/api';
import history from './history';

const store = configureStore({}, { api: api.create() });

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor(store)}>
        <HelmetProvider>
          <Theme parentTheme>
            <ReactRouter history={history}>
              <Campaign>
                <CompetitionTemplate store={store}>
                  <Routes persistor={persistor(store)} />
                </CompetitionTemplate>
              </Campaign>
            </ReactRouter>
          </Theme>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
