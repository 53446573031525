import React from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
// import history from '../../history';
import { useHistory } from 'react-router-dom';
import { selectedPremises } from '../../store/premise/actions';
import CollisionLink from '../CollisionLink';

const RedeemButton = ({ venue, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Button
      className="button"
      variant="contained"
      size="large"

      onClick={() => {
        dispatch(selectedPremises(venue));
      }}
      component={CollisionLink(`/application/order${history.location.search}`)}
      {...props}
    >
      Next
    </Button>
  );
};

export default RedeemButton;
