const url = `${process.env.PUBLIC_URL}/brands/heinekenZero/`;

const FuturaCondensedBoldWoff2 = `${url}fonts/Futura/Futura-CondensedBold.woff2`;
const FuturaCondensedBoldWoff = `${url}fonts/Futura/Futura-CondensedBold.woff`;
const FuturaCondensedBoldTtf = `${url}fonts/Futura/Futura-CondensedBold.ttf`;

const FuturaLightWoff2 = `${url}fonts/Futura/Futura-Light.woff2`;
const FuturaLightWoff = `${url}fonts/Futura/Futura-Light.woff`;
const FuturaLightTtf = `${url}fonts/Futura/Futura-Light.ttf`;

const FuturaBookWoff2 = `${url}fonts/Futura/Futura-Book.woff2`;
const FuturaBookWoff = `${url}fonts/Futura/Futura-Book.woff`;
const FuturaBookTtf = `${url}fonts/Futura/Futura-Book.ttf`;

const futuraLight = {
  fontFamily: 'Futura',
  fontWeight: 'lighter',
  src: `
    url('${FuturaLightWoff2}') format('woff2'),
    url('${FuturaLightWoff}') format('woff'),
    url('${FuturaLightTtf}') format('truetype');
  `,
};

const futuraBook = {
  fontFamily: 'Futura',
  fontWeight: 'normal',
  src: `
    url('${FuturaBookWoff2}') format('woff2'),
    url('${FuturaBookWoff}') format('woff'),
    url('${FuturaBookTtf}') format('truetype');
  `,
};

const futuraCondensedBold = {
  fontFamily: 'Futura',
  fontWeight: 'bold',
  src: `
    url('${FuturaCondensedBoldWoff2}') format('woff2'),
    url('${FuturaCondensedBoldWoff}') format('woff'),
    url('${FuturaCondensedBoldTtf}') format('truetype');
  `,
};

const bodyFont = {
  fontFamily: [
    futuraBook.fontFamily,
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
};

const palette = {
  background: {
    default: '#003D7D',
    paper: '#fff',
  },
  primary: {
    main: '#003D7D',
    contrastText: '#fff',
  },
  secondary: {
    main: '#fff',
    contrastText: '#003D7D',
  },
  text: {
    primary: '#272828',
    secondary: '#003D7D',
  },
  action: {
    active: '#272828',
  },
};

const paletteDark = {
  text: {
    primary: '#fff',
    secondary: '#fff',
  },
  action: {
    active: '#fff',
  },
};

const typography = {
  h1: {
    ...bodyFont,
    fontSize: '7rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  h2: {
    ...bodyFont,
    fontSize: '3.75rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  h3: { ...bodyFont, textTransform: 'uppercase', fontWeight: 'bold' },
  h4: { ...bodyFont, fontWeight: 'bold' },
  h5: { ...bodyFont, fontWeight: 'bold' },
  h6: { ...bodyFont, fontWeight: 'bold' },
  caption: { ...bodyFont, fontWeight: 'lighter' },
  body1: bodyFont,
  body2: bodyFont,
  button: { ...bodyFont, textTransform: 'uppercase', fontWeight: 'bold' },
};

export const heinekenZero = {
  name: 'Heineken 0.0',
  id: 7,
  mode: 'light',
  campaigns: ['12'],
  userTheme: {
    default: {
      mui: {
        mixins: {
          toolbar: {
            minHeight: 70,
          },
        },
        typography,
        palette,
        props: {
          MuiButton: {
            color: 'primary',
            variant: 'contained',
            size: 'large',
          },
          MuiLink: {
            underline: 'always',
          },
          MuiCheckbox: {
            color: 'primary',
          },
          MuiRadio: {
            color: 'primary',
          },
        },
        overrides: {
          MuiCssBaseline: {
            '@global': {
              '@font-face': [futuraLight, futuraBook, futuraCondensedBold],
            },
          },
          MuiButton: {
            root: {
              borderRadius: '1em',
              lineHeight: '1',
            },
            contained: {
              boxShadow: 'none',
            },
            containedSizeLarge: {
              fontSize: '1.25rem',
              padding: '0.75rem 2rem',
            },
            label: typography.button,
          },
          MuiInputLabel: {
            root: {
              color: palette.text.primary,
              '&$focused': {
                color: palette.text.primary,
              },
            },
          },
          MuiCheckbox: {
            root: {
              color: palette.text.primary,
            },
          },
          MuiExpansionPanelSummary: {
            root: {
              padding: '0',
            },
          },
        },
      },
      brand: {
        paper: false,
        vendor: {
          name: 'Star Liquor',
          image: {
            url: `${url}vendor.svg`,
            alt: 'Star Liquor',
          },
        },
        home: {
          layout: {
            direction: 'row',
            sections: [
              {
                id: 'first',
                responsive: {
                  sizes: {
                    xs: 12,
                    sm: 7,
                  },
                  order: {
                    xs: 1,
                    lg: 1,
                  },
                },
                components: ['hero'],
              },
              {
                id: 'second',
                responsive: {
                  sizes: {
                    xs: 12,
                    sm: 5,
                  },
                  order: {
                    xs: 2,
                    lg: 2,
                  },
                },
                components: ['secondary', 'vendor', 'cta'],
              },
            ],
          },
          howTo: {
            active: true,
            icon: {
              color: palette.primary.main,
              marginTop: '5px',
            },
          },
          images: {
            secondary: {
              maxWidth: { mobile: '270px', desktop: '335px' },
            },
          },
        },
        lists: {
          point: `${url}number-point.svg`,
          fontColor: palette.secondary.contrastText,
        },
        quiz: {
          layout: {
            naked: true,
          },
        },
        logo: {
          url: `${url}logo.svg`,
          alt: 'Heineken 0.0',
        },
        header: {
          height: '78px',
          border: false,
          shadow: true,
          color: 'secondary',
          logo: {
            url: `${url}logo.svg`,
            alt: 'Heineken 0.0',
            height: '40px',
            position: 'left',
          },
        },
        draw: {
          side: 'right',
          inverse: true,
        },
        hero: {
          background: { color: 'transparent' },
          inverse: true,
        },
        footer: {
          inverse: true,
          drinkwise: false,
        },
        heroImage: {
          maxWidth: '230px',
        },
        map: {
          inverse: false,
          onPrem: {
            color: 'primary',
            inactive: `${url}on-prem-marker.svg`,
            active: `${url}on-prem-marker-active.svg`,
          },
          offPrem: {
            color: 'primary',
            inactive: `${url}off-prem-marker.svg`,
            active: `${url}off-prem-marker-active.svg`,
          },
        },
        finished: {
          titleColor: 'primary',
          logo: {
            url: `${url}stamp.svg`,
            alt: 'Heineken 0.0',
          },
          inverse: false,
        },
        background: {
          value: {
            xs: `url(${url}finished.png) center / cover no-repeat`,
            lg: `url(${url}hero.png) 48px center / 335px 478px no-repeat, url(${url}finished.png) center / cover no-repeat`,
          },
          include: ['/campaign-finished'],
        },
        typography: {
          h1: { color: 'textSecondary' },
          h2: { color: 'textSecondary' },
          h3: { color: 'textSecondary' },
          h4: { color: 'textSecondary' },
          h5: { color: 'textSecondary' },
          h6: { color: 'textSecondary' },
        },
      },
    },
    light: {
      mui: {
        palette: {},
      },
      brand: {},
    },
    dark: {
      mui: {
        palette: paletteDark,
      },
      brand: {},
    },
  },
};
