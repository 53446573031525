import React, { useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { useTheme } from '@material-ui/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isPostalCode from 'validator/lib/isPostalCode';
import validate from '../../services/validation';
import Typography from '@material-ui/core/Typography';
import { getResourceSelector } from './../../store/resource/selectors';
import { useSelector } from 'react-redux';
import SignUpBtn from '../SignUpBtn';
import {
  resourceClearResource,
  resourceClearFailure,
  resourceUpdate,
} from '../../store/resource/actions';
import SignUpConsent from '../SignUpConsent';
import SignUpDateField from '../SignUpDateField';
import SignUpNowOrLater from '../SignUpNowOrLater';
import { Input } from '@material-ui/core';
import { userVenueSelected } from '../../store/location/actions';
import VenuesSearchClaim from '../VenuesSearchClaim';

const ResponsiveBox = styled(Box)`
  ${({ theme }) => css`
    padding-top: ${theme.spacing(2)}px;
    padding-bottom: ${theme.spacing(2)}px;
  `}
`;

const renderTextField = ({
  input,
  meta: { touched, invalid, error },
  ...custom
}) => {
  // TODO: (sb): This is hacky and silly, fix the store
  const errormessagePresent =
    custom.errormessage !== '' && custom.errormessage !== undefined;

  return (
    <TextField
      {...input}
      {...custom}
      variant="outlined"
      error={(touched && invalid) || errormessagePresent}
      helperText={(touched && error) || custom.errormessage}
    />
  );
};

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const renderFileInput = ({
  input: { onChange, onBlur },
  meta: { touched, invalid },
  ...custom
}) => {
  // TODO: (sb): This is hacky and silly, fix the store
  const errormessagePresent =
    custom.errormessage &&
    custom.errormessage !== '' &&
    custom.errormessage !== undefined;

  return (
    <>
      <InputLabel
        // error={(touched && invalid) || errormessagePresent || !fileSizeValidation}
        error={(touched && invalid) || errormessagePresent}
      >
        Upload receipt*
        <span style={{ fontWeight: 'normal', lineHeight: '1.4' }}>
          <br />
          <em>
            Max file size: 10MB
            <br />
            Accepted formats: PNG/JPG/JPEG/GIF/PDF
          </em>
        </span>
      </InputLabel>
      <Input
        {...custom}
        type="file"
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        // error={(touched && invalid) || errormessagePresent || !fileSizeValidation}
        error={(touched && invalid) || errormessagePresent}
        // style={{ color: (((touched && invalid) || errormessagePresent || !fileSizeValidation) ? 'red' : 'currentColor') }}
        style={{
          color:
            (touched && invalid) || errormessagePresent
              ? 'red'
              : 'currentColor',
        }}
      />
      {/* {(errormessagePresent || !fileSizeValidation) && ( */}
      {errormessagePresent && (
        // <span style={{ display: 'block', marginTop: '10px', fontSize: '12px', color: 'red', lineHeight: '1.4' }}>{fileSizeValidation ? custom.errormessage : 'File size needs to be less than 10MB'}</span>
        <span
          style={{
            display: 'block',
            marginTop: '10px',
            fontSize: '12px',
            color: 'red',
            lineHeight: '1.4',
          }}
        >
          {custom.errormessage}
        </span>
      )}
    </>
  );
};

const required = value => (value ? undefined : 'Required');

const mobileFormat = value => value.replace('+61', '0');

const mobileValidation = value =>
  value && !isMobilePhone(mobileFormat(value), 'en-AU')
    ? 'Invalid Australian mobile number'
    : undefined;

const postalCodeValidation = value =>
  !isPostalCode(value, 'AU') ? 'Invalid Australian post code' : undefined;

// const fileSizeValidation = file => file.size > 10000000 ? 'File too large' : undefined;

const dobFormat = value =>
  value
    .replace(/[^\d]/g, '')
    .replace(/^(\d{2})(\d{2})(\d)/g, '$1/$2/$3')
    .replace(/^(\d{2})(\d{1,2})$/g, '$1/$2')
    .substr(0, 10);

const dobValidation = value => {
  const formatted_date = dobFormat(value);
  if (formatted_date.length === 10) {
    const parts = formatted_date.split('/');
    const now = new Date();
    const eighteenYearsAgo = Date.parse(
      `${(now.getMonth() + 1).toString()}/${now.getDate().toString()}/${(
        now.getFullYear() - 18
      ).toString()}`
    );

    const dobDate = Date.parse(`${parts[1]}/${parts[0]}/${parts[2]}`);
    if (isNaN(dobDate)) {
      return 'The DOB does not match the format dd/mm/yyyy.';
    } else if (dobDate >= eighteenYearsAgo) {
      return 'The DOB must be a date before or equal to 18 years ago.';
    }
  } else {
    return 'The DOB does not match the format dd/mm/yyyy.';
  }
};

const MuiField = ({ name, ...props }) => {
  const dispatch = useDispatch();
  const customer = useSelector(getResourceSelector)('customer');

  return (
    <Field
      name={name}
      InputLabelProps={{
        required: false,
        color: 'primary',
        shrink: true,
        className: 'body__text--secondary',
      }}
      FormHelperTextProps={{ filled: true }}
      required
      fullWidth
      errormessage={customer.errors[name]}
      onChange={() => {
        dispatch(resourceClearFailure('customer', name));
      }}
      {...props}
    />
  );
};

let SignUp = ({ handleSubmit, pristine, submitting, invalid: formInvalid }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resourceClearFailure('customer'));
    dispatch(resourceClearResource('customer'));
  }, [dispatch]);

  const customer = useSelector(getResourceSelector)('customer');

  const refs = {
    first_name: useRef(),
    last_name: useRef(),
    email: useRef(),
    mobile: useRef(),
    dob: useRef(),
    postcode: useRef(),
    receipt: useRef(),
  };
  const refFields = Object.keys(refs);
  useEffect(() => {
    if (customer.errorScroll) {
      let offsetTop;

      for (var i = 0; i < refFields.length; i++) {
        const refField = refFields[i];

        if (
          customer.errors[refField] !== undefined &&
          customer.errors[refField] !== ''
        ) {
          const ref = refs[refField];

          if (
            offsetTop === undefined ||
            (ref.current && offsetTop > ref.current.offsetTop)
          ) {
            offsetTop = ref.current.offsetTop;
          }
        }
      }
      if (offsetTop !== undefined) {
        window.scrollTo(0, offsetTop);
      }
      dispatch(resourceUpdate('customer', 'errorScroll', false));
    }
  });

  const { data: campaignData } = useSelector(getResourceSelector)('campaign');
  const { brand, ...theme } = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));

  const quizData = useSelector(getResourceSelector)('quiz');
  const selectedAnswer = quizData.selected_answer || {};

  const [focus, setFocus] = useState({ near: false, search: false });
  const [venue, setVenue] = useState(false);

  const updateSelectedVenue = venue => {
    setVenue(venue);
    dispatch(userVenueSelected(venue));
  };

  return (
    <ResponsiveBox theme={theme}>
      {customer.error &&
        !(
          customer.errors !== undefined &&
          Object.values(customer.errors).filter(error => error).length
        ) && (
          <Box mb={2}>
            <Typography color="error" component="span" variant="button">
              {customer.message}
            </Typography>
          </Box>
        )}
      <form
        className="signUpForm"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        <Grid container spacing={1}>
          {campaignData.quiz_question_count > 0 &&
            selectedAnswer.text === 'Dob' && (
              <Grid item xs={12} ref={refs.selected_venue}>
                <VenuesSearchClaim
                  setVenue={updateSelectedVenue}
                  venue={venue}
                  focus={focus.search}
                  setFocus={setFocus}
                />
              </Grid>
            )}
          <Grid item xs={12} ref={refs.first_name}>
            <MuiField
              name="first_name"
              className="textField"
              autoComplete="given-name"
              label="First Name*"
              placeholder="First Name"
              style={{ marginBottom: '10px' }}
              component={renderTextField}
              validate={required}
            />
          </Grid>
          <Grid item xs={12} ref={refs.last_name}>
            <MuiField
              name="last_name"
              className="textField"
              autoComplete="family-name"
              label="Last Name*"
              placeholder="Last Name"
              style={{ marginBottom: '10px' }}
              component={renderTextField}
              validate={required}
            />
          </Grid>
          <Grid item xs={12} ref={refs.email}>
            <MuiField
              name="email"
              className="textField"
              type="email"
              autoComplete="email"
              label="Email*"
              placeholder="Email"
              style={{ marginBottom: '10px' }}
              component={renderTextField}
              validate={required}
            />
          </Grid>
          <Grid item xs={12} ref={refs.mobile}>
            <MuiField
              name="mobile"
              className="textField"
              autoComplete="tel"
              label="Mobile*"
              placeholder="Mobile"
              inputProps={{ inputMode: 'decimal', pattern: '[0-9]*' }}
              style={{ marginBottom: '10px' }}
              validate={[required, mobileValidation]}
              component={renderTextField}
            />
          </Grid>
          <Grid item xs={12} ref={refs.dob}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MuiField
                name="dob"
                className="textField"
                autoComplete="bday"
                label="Date of Birth*"
                placeholder="DD/MM/YYYY"
                inputProps={{ inputMode: 'decimal', pattern: '[0-9]*' }}
                style={{ marginBottom: '10px' }}
                component={SignUpDateField}
                validate={[required, dobValidation]}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} ref={refs.postcode}>
            <MuiField
              name="postcode"
              className="textField"
              autoComplete="postal-code"
              label="Postcode*"
              placeholder="Postcode"
              id="postal-code"
              style={{ marginBottom: '10px' }}
              inputProps={{ inputMode: 'decimal', pattern: '[0-9]*' }}
              component={renderTextField}
              validate={[required, postalCodeValidation]}
            />
          </Grid>
          {campaignData.receipt_upload && (
            <Grid item xs={12} ref={refs.receipt}>
              <MuiField
                name="receipt"
                id="receipt"
                label="Upload receipt*<br/>Accepted formats: PNG, JPG, JPEG, GIF, PDF"
                // validate={[required, fileSizeValidation]}
                validate={required}
                component={renderFileInput}
                disableUnderline={true}
              />
            </Grid>
          )}
          {['on-prem', 'event'].indexOf(campaignData.type) !== -1 &&
            !desktop &&
            (!selectedAnswer.text ||
              (selectedAnswer.text && selectedAnswer.text !== 'Dob')) && (
              <Grid item xs={12} className="signUpNowOrLater">
                <SignUpNowOrLater />
              </Grid>
            )}
          <SignUpConsent />
        </Grid>
        <SignUpBtn disabled={pristine || submitting || formInvalid} />
        <Box mb={2} textAlign="center">
          <Typography
            variant="caption"
            style={{ fontSize: '10px' }}
            className="formCaption"
          >
            {(brand.signup && brand.signup.tncCaption) || ''}
          </Typography>
        </Box>
      </form>
    </ResponsiveBox>
  );
};

SignUp = reduxForm({
  form: 'signup',
  validate,
  destroyOnUnmount: true,
})(SignUp);

export default SignUp;
export { dobFormat };
