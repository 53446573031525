// https://github.com/diegohaz/arc/wiki/Actions
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
export const PREMISE_COUNTDOWN_STARTED = 'PREMISE_COUNTDOWN_STARTED';
export const PREMISE_COUNTDOWN_FINISHED = 'PREMISE_COUNTDOWN_FINISHED';
export const PREMISE_SELECTED = 'PREMISE_SELECTED';
export const PREMISE_SAVE_FOR_LATER = 'PREMISE_SAVE_FOR_LATER';
export const PREMISE_CLEAR = 'PREMISE_CLEAR';

export const clearPremise = () => ({
  type: PREMISE_CLEAR,
});

export const countdownStart = payload => ({
  type: PREMISE_COUNTDOWN_STARTED,
  payload,
});

export const countdownFinished = payload => ({
  type: PREMISE_COUNTDOWN_FINISHED,
  payload,
});

export const selectedPremises = payload => ({
  type: PREMISE_SELECTED,
  payload,
});

export const saveForLater = payload => ({
  type: PREMISE_SAVE_FOR_LATER,
  payload,
});
