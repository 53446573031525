import React from 'react';
import { Route } from 'react-router-dom';
import SaveForLaterRedirect from '../SaveForLaterRedirect';

const ApplicationRoute = ({ component: Component, ...props }) => {
  return (
    <SaveForLaterRedirect {...props} component={Component}>
      <Route {...props} component={Component} />
    </SaveForLaterRedirect>
  );
};

export default ApplicationRoute;
