// Taken from https://github.com/mui-org/material-ui/blob/master/docs/src/pages/getting-started/templates/blog/Markdown.js
import React from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const styles = theme => ({
  listItem: {
    marginTop: theme.spacing(1),
  },
});

const options = (forceInline = true) => {
  return {
    overrides: {
      h1: {
        component: Typography,
        props: {
          gutterBottom: true,
          variant: 'h4',
        },
      },
      h2: {
        component: Typography,
        props: { gutterBottom: true, variant: 'h6' },
      },
      h3: {
        component: Typography,
        props: { gutterBottom: true, variant: 'subtitle1' },
      },
      // h4: {
      //   component: Typography,
      //   props: { gutterBottom: true, variant: 'caption', paragraph: true },
      // },
      p: { component: Typography, props: { paragraph: true } },
      a: { component: Link },
      li: {
        component: withStyles(styles)(({ classes, ...props }) => (
          <li className={classes.listItem}>
            <Typography component="span" {...props} />
          </li>
        )),
      },
      strong: {
        component: Typography,
        props: { variant: 'button' },
      },
    },
    forceInline,
  };
};

export default function Markdown({ children, inline, ...props }) {
  if (!children) {
    return null;
  }
  if (typeof children !== 'string') {
    return children;
  }

  if (children)
    return (
      <ReactMarkdown options={options(inline)} children={children} {...props} />
    );
}
