import React from 'react';
import { StylesProvider, ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import useBrandTheme from '../../hooks/useBrandTheme';

// To have a custom light and dark palette: https://github.com/mui-org/material-ui/issues/9891
// https://material-ui.com/customization/default-theme/
const themeProcessor = ({ muiTheme = { palette: {} }, brand }) =>
  responsiveFontSizes(
    createMuiTheme({
      brand,
      ...muiTheme,
      palette: {
        ...muiTheme.palette,
      },
      props: {
        ...muiTheme.props,
      },
    }),
    { factor: 1.75 }
  );

function Theme({ children, parentTheme = false }) {
  const theme = useBrandTheme();

  const themeGuts = (
    <ThemeProvider theme={themeProcessor(theme)}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );

  if (parentTheme) {
    return <StylesProvider injectFirst>{themeGuts}</StylesProvider>;
  }

  return themeGuts;
}

export default Theme;
