import React from 'react';
import { useTheme } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const Barcode = props => {
  const theme = useTheme();
  if (props.code) {
    return (
      <>
        <img
          src={`https://xxxx-savannah.s3.ap-southeast-2.amazonaws.com/barcodes/${props.campaign}/${props.code}.png`}
          alt="barcode"
        />
        <Typography
          theme={theme}
          variant="subtitle2"
          component="p"
        >
          {props.code}
        </Typography>
      </>
    );
  } else {
    return <></>;
  }
};

export default Barcode;
